import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ModalForm from "./components/modalForm";
import {
  Button,
  Typography,
  Space,
  Dropdown,
  Menu,
  Checkbox,
  Modal,
  Table,
} from "antd";
import { ForkOutlined } from "@ant-design/icons";
import CustomToast from "./components/CustomToast";
import "handsontable/dist/handsontable.min.css";
import { HotTable } from "@handsontable/react";
import {
  drawCheckboxInRowHeaders,
  addClassesToRows,
} from "./utils/handsonTableUtils";
import HourGlass from "./components/customLoaders/HourGlass";
import { useSelector, useDispatch } from "react-redux";
import {
  DATA_FLOW_TABLE,
  dataTypeConversions,
  graphRequestMapping,
} from "./data/constants";
import CustomPageSize from "./components/CustomPageSize";
import {
  handleRowDelete,
  getColumnsFromData,
  handleFileChange,
  DEFAULT_PAGE_SIZE,
} from "./globalUtils";
import {
  handleExportJsonClick,
  handleImportJsonClick,
  handleExportClick,
} from "./utils/fileImportExportUtils";
import {
  fetchCataLog,
  updateCatalogData,
  fetchCataLogHeader,
} from "./container/actions/catalogActions";
import {
  fetchGraphData,
  resetGraph,
  updateCurrentFlow,
} from "./container/actions/graphActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faTrash,
  faPlugCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import OwnerTagLayout from "./OwnerTagLayout";
import MenuButtonComponent from "./components/button/DropDownButton";
import { ManageViewButton } from "./components/button/ManageViewButton";
import {
  getGraphicalRelationshipTypes,
  getNonGraphicalRelationshipTypes,
  getNonGraphicalRelationTypesByNodeName,
  selectAllRows,
} from "./globalUtils";
import { getEnumValues } from "./config/Api";
import CustomPagination from "./components/CustomPagination";
import NavBar from "./components/layout/NavBar";
import "./assets/styles/main.css";
import { actionIcons } from "./utils/svgIcons/actionIcons";

function SubmenuComponent({
  catalogName,
  submenuName,
  displayName,
  nodeDescription,
  relationshipMetaState,
  token
}) {
  const fileInputRef = useRef(null);
  const hotRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState({});
  const [columns, setColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageAudit, setCurrentPageAudit] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [hasNextPageAudit, setHasNextPageAudit] = useState(false);
  const [hasPrevPageAudit, setHasPrevPageAudit] = useState(false);
  const [shouldShowToast, setShouldShowToast] = useState(false);
  const [editedRows, setEditedRows] = useState([]); // State to store edited rows
  const [addedRows, setAddedRows] = useState([]); // State to store added rows
  const [deletedRows, setDeletedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const themeState = useSelector((themeState) => themeState.themeReducer);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isOwnerTag, setIsOwnerTag] = useState(false);
  const [tableName, setTableName] = useState();
  const [init, setInit] = useState(false);
  const [hasUpstreamRelation, setHasUpstreamRelation] = useState(false);
  const [hasDownstreamRelation, setHasDownstreamRelation] = useState(false);
  const [hasAsset, setHasAsset] = useState(false);
  const [selectedRequiredId, setSelectedRequiredId] = useState(null);
  const [cataLogEnumValues, setCatalogEnumValues] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [auditLogData, setAuditLogData] = useState([]);
  const [isAuditLog, setIsAuditLog] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const cataLogsState = useSelector(
    (catalogState) => catalogState.cataLogDataReducer
  );

  const cataLogsHeaderState = useSelector(
    (catalogState) => catalogState.cataLogHeaderReducer
  );

  const masterListState = useSelector(
    (catalogState) => catalogState.masterListReducer
  );

  const catalogError = cataLogsState?.[submenuName]?.error;
  const catalogLoading = cataLogsState?.[submenuName]?.loading;

  const getRequiredId = () => {
    if (selectedRows?.length === 1) {
      const firstUniqueIdItem = getFirstUniqueIdItem();
      const idDataType = firstUniqueIdItem["Data Type"];
      const IdentifierFieldName = firstUniqueIdItem["Display Name"];
      const id = selectedRows[0];
      const selectedItem = apiData[submenuName]?.find((item) => item.id === id);
      if (selectedItem?.hasOwnProperty(IdentifierFieldName)) {
        const selectedValue = selectedItem[IdentifierFieldName];
        if (idDataType === "String") {
          setSelectedRequiredId("'" + selectedValue + "'");
        } else {
          setSelectedRequiredId(selectedValue);
        }
      }
    }
  };

  function getFirstUniqueIdItem() {
    return cataLogsHeaderState[submenuName]?.data?.find(
      (item) => item["Property Type"] === "IS_IDENTITY"
    );
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showToast = () => {
    setShouldShowToast(true);
  };

  useEffect(() => {
    if (!init) {
      dispatch(resetGraph());
      dispatch(
        fetchCataLogHeader(submenuName, token, catalogName)
      );
      setInit(true);
    }
  }, []);

  useEffect(() => {
    const limit = pageSize;
    const skip = (currentPage - 1) * pageSize;
    dispatch(
      fetchCataLog(limit, skip, token, submenuName, catalogName)
    );
  }, [currentPage, pageSize]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if cataLogsHeaderState has submenuName and data
        if (
          cataLogsHeaderState.hasOwnProperty(submenuName) &&
          cataLogsHeaderState[submenuName]?.data?.length > 0
        ) {
          const enumPropNames =
            cataLogsHeaderState[submenuName]?.data
              ?.filter((item) => item["Data Type"] === "Enum")
              ?.map((item) => item["Property Name"]) || [];

          // Iterate over all enumPropNames and fetchEnumValues for each
          const enumValuesPromises = enumPropNames?.map(async (propName) => {
            try {
              const response = await getEnumValues(
                propName,
                token,
                catalogName
              );
              return { [propName]: response };
            } catch (error) {
              toast.error(
                `Error fetching enum values for ${propName}: ${error.message}`
              );
              return { [propName]: [] }; // or handle the error accordingly
            }
          });

          // Wait for all promises to resolve and update the local state
          const enumValues = Object.assign(
            {},
            ...(await Promise.all(enumValuesPromises))
          );
          setCatalogEnumValues(enumValues);
        }
      } catch (error) {
        toast.error(`Error fetching enum values: ${error.message}`);
      }
    };

    fetchData();
  }, [cataLogsHeaderState]);

  useEffect(() => {
    if (
      cataLogsHeaderState.hasOwnProperty(submenuName) &&
      cataLogsHeaderState[submenuName]?.data?.length > 0
    ) {
      setColumns(
        getColumnsFromData(
          cataLogsHeaderState[submenuName]?.data,
          cataLogEnumValues,
          submenuName,
          handleRowDelete,
          cataLogsState,
          setApiData
        )
      );
    }
  }, [cataLogsHeaderState, cataLogEnumValues]);

  useEffect(() => {
    if (cataLogsState.hasOwnProperty(submenuName)) {
      if (cataLogsState[submenuName]?.data?.length) {
        setHasNextPage(cataLogsState[submenuName].data?.length === pageSize);
        setHasPrevPage(currentPage > 1);
      } else {
        //cataLogsState[submenuName].data?.length is 0 and hence hardcode it to false
        setHasNextPage(false);
        setHasPrevPage(currentPage > 1);
      }
    }
    if (cataLogsState.hasOwnProperty(submenuName)) {
      setApiData(() => {
        const newData = {
          [submenuName]: cataLogsState[submenuName]?.data?.map((item) => {
            const id = uuidv4();
            return {
              ...item,
              id: id,
              exist: true,
            };
          }),
        };

        return { ...newData };
      });
    }
  }, [cataLogsState[submenuName], pageSize]);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };

  const goToPageAudit = (page) => {
    setCurrentPageAudit(page);
  };

  const goToNextPageAudit = () => {
    goToPageAudit(currentPageAudit + 1);
  };

  const goToPrevPageAudit = () => {
    goToPageAudit(currentPageAudit - 1);
  };

  const handleAfterChange = (changes, source) => {
    let updatedApiData = { ...apiData };
    if (source === "edit" || source === "Autofill.fill") {
      const updatedEditedRows = editedRows.slice(); // Create a copy of editedRows array
      changes.forEach(([row, column, , newValue]) => {
        const hotInstance = hotRef.current.hotInstance;
        const rowData = hotInstance.getData()[row];
        const header = columns.find((col) => col.header === column)?.header;

        if (header) {
          const idColumnIndex = columns.findIndex(
            (col) => col.columnConfig.data === "id"
          );
          const idValue = rowData[idColumnIndex]; // Assuming 'id' is in a specific column

          const editedRow = updatedEditedRows.find((r) => r.id === idValue);
          if (editedRow) {
            editedRow[header] = newValue;
          } else {
            const existingRow = apiData[submenuName].find(
              (row) => row.id === idValue
            );
            const newEditedRow = { ...existingRow, [header]: newValue }; // Clone the existing row and update the specific column
            updatedEditedRows.push(newEditedRow);
          }
        }
      });
      updatedApiData[submenuName] = updatedApiData[submenuName]?.map((item) => {
        const updatedRow = updatedEditedRows.find((r) => r.id === item.id);
        return updatedRow ? { ...item, ...updatedRow } : item;
      });

      setEditedRows(updatedEditedRows);
    } else if (source === "spliceRow") {
      const deletedIds = changes.map(([row]) => {
        const hotInstance = hotRef.current.hotInstance;
        const rowData = hotInstance.getData()[row];
        const idColumnIndex = columns.findIndex(
          (col) => col.columnConfig.data === "id"
        );
        return rowData[idColumnIndex];
      });

      const updatedDeletedRows = deletedIds.map((id) =>
        apiData[submenuName].find((row) => row.id === id)
      );

      setDeletedRows(updatedDeletedRows);
      updatedApiData[submenuName] = updatedApiData[submenuName]?.filter(
        (row) => !updatedDeletedRows.find((r) => r.id === row.id)
      );
    } else if (source === "populateFromArray") {
      const added =
        hotRef.current.hotInstance.countRows() - apiData[submenuName].length;
      const addedData = hotRef.current.hotInstance.getData().slice(-added);

      const updatedAddedRows = addedRows.slice(); // Create a copy of addedRows array

      addedData.forEach((newRowData) => {
        const idColumnIndex = columns.findIndex(
          (col) => col.columnConfig.data === "id"
        );
        const idValue = newRowData[idColumnIndex]; // Assuming 'id' is in a specific column

        const existingAddedRow = updatedAddedRows.find((r) => r.id === idValue);

        if (!existingAddedRow) {
          const newAddedRow = { id: idValue, ...newRowData };
          updatedAddedRows.push(newAddedRow);
        }
      });

      setAddedRows(updatedAddedRows);
      updatedApiData[submenuName] = [
        ...updatedApiData[submenuName],
        ...updatedAddedRows,
      ];
    }
    if (JSON.stringify(updatedApiData) !== JSON.stringify(apiData)) {
      setApiData(updatedApiData);
    }
  };

  const onFinish = (values) => {
    const result = [];
    result.push(values);
    const updatedApiData =
      apiData[submenuName]?.length > 0
        ? JSON.parse(JSON.stringify(apiData[submenuName]))
        : [];
    dispatch(
      updateCatalogData(
        updatedApiData,
        submenuName,
        token,
        result,
        "POST",
        catalogName
      )
    );
    const limit = pageSize;
    const skip = (currentPage - 1) * pageSize;
    setTimeout(() => {
      dispatch(
        fetchCataLog(limit, skip, token, submenuName, catalogName)
      );
    }, 4000);
  };
  const handleSaveClick = async () => {
    const updatedApiData = JSON.parse(JSON.stringify(apiData[submenuName]));
    const modifiedSaveArray = (data) =>
      data.map(
        ({
          exist,
          id,
          "Created By": createdBy,
          "Created DateTime": createdDateTime,
          "Last Updated By": lastUpdatedBy,
          "Last Updated DateTime": lastUpdatedDateTime,
          ...rest
        }) => rest
      );

    try {
      if (addedRows?.length > 0) {
        dispatch(
          updateCatalogData(
            updatedApiData,
            submenuName,
            token,
            modifiedSaveArray(addedRows),
            "POST",
            catalogName
          )
        );
      }
      if (editedRows?.length > 0) {
        modifiedSaveArray(editedRows).forEach((editRow) => {
          dispatch(
            updateCatalogData(
              updatedApiData,
              submenuName,
              token,
              editRow,
              "PATCH",
              catalogName
            )
          );
        });
      }
      if (deletedRows?.length > 0) {
        dispatch(
          updateCatalogData(
            updatedApiData,
            submenuName,
            token,
            modifiedSaveArray(deletedRows),
            "DELETE",
            catalogName
          )
        );
        setSelectedRows([]);
      }
      showToast();
    } catch (error) {
      toast.error("Error saving data:", error);
    }
  };

  useEffect(() => {
    if (
      shouldShowToast &&
      cataLogsState.hasOwnProperty(submenuName) &&
      !catalogLoading
    ) {
      if (catalogError) {
        toast.error(
          <CustomToast message={`Unable to save, ${catalogError}`} />,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            pauseOnHover: true,
            closeButton: true, // Enable the close button
          }
        );
      } else {
        setAddedRows([]);
        setDeletedRows([]);
        setEditedRows([]);
        toast.success("Saved Successfully");
      }
      setShouldShowToast(false); // Reset the flag
    }
  }, [catalogError, catalogLoading, shouldShowToast]);

  const handleCancelClick = () => {
    setApiData(() => {
      const newData = {
        [submenuName]:
          cataLogsState.hasOwnProperty(submenuName) &&
          cataLogsState[submenuName]?.data?.map((item) => {
            const id = uuidv4();
            return {
              ...item,
              id: id,
              exist: true,
            };
          }),
      };

      return { ...newData };
    });
    setSelectedRows([]);
    setAddedRows([]);
    setDeletedRows([]);
    setEditedRows([]);
  };

  const handleAfterRemoveRow = (index, amount) => {
    for (let i = 0; i < amount; i++) {
      handleRowDelete(submenuName, cataLogsState, setApiData, index + i);
    }
  };

  const handleRowSelection = (row, isChecked) => {
    if (isChecked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows?.filter((selectedRow) => selectedRow !== row)
      );
    }
  };
  const masterNodeName = masterListState?.data?.find(
    (val) => val["Node Name"] === submenuName
  );
  const NodeType = masterNodeName["Node Type"];
  const GraphDetailOptions = new Map([
    [
      "Tag Graph",
      [
        { label: "Create System Graph", key: "System Graph" },
        { label: "Create Metadata Graph", key: "Metadata Graph" },
      ],
    ],
    ["System Graph", [{ label: "Create System Graph", key: "System Graph" }]],
    [
      "Metadata Graph",
      [{ label: "Create Metadata Graph", key: "Metadata Graph" }],
    ],
  ]);

  function handleNewCreateGraph(graphType) {
    if (selectedRows?.length === 0) {
      toast.error(`Please select atleast one row to create ${graphType}`);
    } else if (selectedRows?.length > 0) {
      const propertyWithUniqueId = getFirstUniqueIdItem();

      let identifierName;
      let requestCase;
      let uniqueId;
      let selectedIdentifiers;

      identifierName = propertyWithUniqueId["Display Name"];
      uniqueId = propertyWithUniqueId["Property Name"];
      requestCase = graphRequestMapping[NodeType];
      selectedIdentifiers = selectedRows?.map((id) => {
        const selectedItem = apiData?.[submenuName]?.find(
          (item) => item.id === id
        );
        const identifierValue = selectedItem?.[identifierName];
        return typeof identifierValue === 'string' ? identifierValue.replace(/\s+/g, '_') : identifierValue;
    });

    const identifierDataType = selectedIdentifiers
        ? typeof selectedIdentifiers?.[0]
        : "";

      let data;
      if (submenuName === DATA_FLOW_TABLE) {
        data = {
          Case: (graphType === "System Graph"?requestCase[0]:requestCase[1]),
          Item: {
            Case: "TagNames",
            tags: selectedIdentifiers,
          },
        };
      } else if (identifierDataType === "number") {
        data = {
          Case: requestCase,
          Item: {
            Case: "NodeGraphRequest",
            nodeName: submenuName,
            nodeUniqueColName: uniqueId,
            nodeIdInfo: {
              Case: "IdNums",
              idNumList: selectedIdentifiers,
            },
          },
        };
      } else {
        data = {
          Case: requestCase,
          Item: {
            Case: "NodeGraphRequest",
            nodeName: submenuName,
            nodeUniqueColName: uniqueId,
            nodeIdInfo: {
              Case: "Ids",
              idStrList: selectedIdentifiers?.map(String), // Convert to strings
            },
          },
        };
      }
      dispatch(
        updateCurrentFlow({
          Id: selectedIdentifiers[0],
          "Catalog Name": catalogName,
        })
      );
      dispatch(fetchGraphData(token, data, catalogName));
      navigate(`/graph/${selectedIdentifiers[0]}`);
    }
  }
  const handleManageOwnerTags = (type) => {
    if (selectedRows?.length === 0) {
      toast.error(`Please select one row to manage.`);
    } else if (selectedRows?.length === 1) {
      getRequiredId();
      if (type === "Downstream") {
        setHasDownstreamRelation(true);
      } else if (type === "Upstream") {
        setHasUpstreamRelation(true);
      } else if (type === "Entities") {
        setHasAsset(true);
        setTableName(type);
      } else {
        setTableName(type);
        setIsOwnerTag(true);
      }
    } else {
      toast.error(`Please select only one row to use this feature!`);
    }
  };

  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  const handleNewRowSubmit = (formData) => {
    onFinish(formData);
    setIsModalVisible(false);
  };

  const ImportButtonComponent = () => {
    const importOptions = [
      {
        label: "Import Csv",
        key: "1",
        icon: <FontAwesomeIcon className="fa" icon={faFileCsv} />,
      },
      {
        label: "Import Json",
        key: "2",
        icon: <ForkOutlined />,
      },
    ];

    const handleMenuClick = (e) => {
      if (e.key === "1") {
        handleImportClick();
      } else {
        handleImportJsonClick(apiData, submenuName, setApiData);
      }
    };

    const menu = (
      <Menu onClick={handleMenuClick}>
        {importOptions.map((option) => (
          <Menu.Item
            key={option.key}
            className="shadow-lg border border-gray-300 rounded-md p-2"
          >
            <div className="spans-container">
              <span>{option.icon}</span>
              <span className="gap-between-spans">{option.label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        placement="left"
        // className={`gradient__btn__small gradient__btn__small-${
        //   themeColors[themeState.sidenavColor]
        // }`}
      >
        <span className="shadow-lg border border-gray-300 rounded-md p-2 menu-button">
          Import
        </span>
      </Dropdown>
    );
  };

  const ExportButtonComponent = () => {
    const exportOptions = [
      {
        label: "Export Csv",
        key: "1",
        icon: <FontAwesomeIcon className="fa" icon={faFileCsv} />,
      },
      {
        label: "Export Json",
        key: "2",
        icon: <ForkOutlined />,
      },
    ];

    const handleMenuClick = (e) => {
      // Handle menu click based on the key (e.key)
      if (e.key === "1") {
        handleExportClick(columns, apiData, submenuName);
      } else {
        handleExportJsonClick(apiData, submenuName);
      }
    };

    const menu = (
      <Menu onClick={handleMenuClick}>
        {exportOptions.map((option) => (
          <Menu.Item
            key={option.key}
            className="shadow-lg border border-gray-300 rounded-md p-2"
          >
            <div className="spans-container">
              <span>{option.icon}</span>
              <span className="gap-between-spans">{option.label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        placement="left"
        // className={`gradient__btn__small gradient__btn__small-${
        //   themeColors[themeState.sidenavColor]
        // }`}
      >
        <span className="shadow-lg border border-gray-300 rounded-md p-2 menu-button">
          Export
        </span>
      </Dropdown>
    );
  };

  const AddNewRowDeleteRowButtonComponent = () => {
    const addNewRowDeleteRowOptions = [
      {
        label: "Add New Row",
        key: "1",
        icon: <FontAwesomeIcon className="fa" icon={faPlugCirclePlus} />,
      },
      {
        label: "Delete Row/s",
        key: "2",
        icon: <FontAwesomeIcon className="fa" icon={faTrash} />,
      },
    ];

    const handleBulkDelete = () => {
      if (selectedRows?.length === 0) {
        toast.error("Please select rows to delete.");
      }

      if (
        cataLogsState?.hasOwnProperty(submenuName) &&
        cataLogsState[submenuName]?.data?.length > 0
      ) {
        setApiData((prevApiData) => {
          const updatedPrevApiData = prevApiData[submenuName]?.filter(
            (item) => {
              return !selectedRows.includes(item.id);
            }
          );

          const deletedRows = prevApiData[submenuName]?.filter((item) => {
            return selectedRows.includes(item.id);
          });

          setDeletedRows(deletedRows); // Update deletedRows state

          const updatedData = {
            [submenuName]: updatedPrevApiData,
          };

          return {
            ...prevApiData,
            ...updatedData,
          };
        });
      }
    };

    const handleMenuClickUpdate = (e) => {
      // Handle menu click based on the key (e.key)
      setSelectedRows([]);
      if (e.key === "1") {
        showModal();
      } else {
        handleBulkDelete();
      }
    };

    const menu = (
      <Menu onClick={handleMenuClickUpdate}>
        {addNewRowDeleteRowOptions?.map((option) => (
          <Menu.Item
            key={option?.key}
            className="shadow-lg border border-gray-300 rounded-md p-2"
          >
            <div className="spans-container">
              <span>{option?.icon}</span>
              <span className="gap-between-spans">{option?.label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        placement="left"
        // className={`gradient__btn__small gradient__btn__small-${
        //   themeColors[themeState.sidenavColor]
        // }`}
      >
        <span className="shadow-lg border border-gray-300 rounded-md p-2 menu-button">
          +/- Row{" "}
        </span>
      </Dropdown>
    );
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to the first page when changing page size
  };

  const onChange = (e) => {
    const checked = e.target.checked;
    // setSelectAll(checked);
    selectAllRows(hotRef, setSelectedRows, checked);
  };

  const isActionDisabled =
    addedRows.length === 0 &&
    editedRows.length === 0 &&
    deletedRows.length === 0;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAuditLog = () => {
    const limit = pageSize;
    const skip = (currentPage - 1) * pageSize;
    dispatch(
      fetchCataLog(
        limit,
        skip,
        token,
        submenuName + "_audit",
        catalogName,
        [],
        "lastUpdateDateTime"
      )
    ).then(() => {
      setIsAuditLog(true);
      setAuditLogData(cataLogsState[submenuName + "_audit"]?.data);

      if (cataLogsState[submenuName + "_audit"]?.data?.length) {
        setHasNextPageAudit(
          cataLogsState[submenuName + "_audit"].data?.length === pageSize
        );
        setHasPrevPageAudit(currentPageAudit > 1);
      } else {
        setHasNextPageAudit(false);
        setHasPrevPageAudit(currentPage > 1);
      }
    });
  };

  const handleOk = () => {
    setIsAuditLog(false);
    setSelectedFilter([]);
  };

  const handleCancel = () => {
    setIsAuditLog(false);
  };

  const auditLogColumns =
    auditLogData?.length > 0
      ? Object.keys(auditLogData?.[0]).map((key) => {
          // Define custom sorter function for different data types
          const sorter = (a, b) => {
            // If data is of type string or datetime, use localeCompare() for sorting
            if (typeof a[key] === "string" || a[key] instanceof Date) {
              return a[key].localeCompare(b[key]);
            }
            // For other data types (numbers), use default comparison
            return a[key] - b[key];
          };

          return {
            title: key,
            dataIndex: key,
            key: key,
            sorter: sorter,
            filters: Array.from(
              new Set(auditLogData?.map((item) => item[key]))
            ).map((value) => ({
              text: value?.toString(),
              value: value,
            })),
            onFilter: (value, record) => record[key] === value,
          };
        })
      : [];

  const catalogActions = [
    {
      name: "Save",
      key: "1",
      onClick: () => handleSaveClick(),
      icon: isActionDisabled ?  actionIcons.saveIcon : actionIcons.activeSaveIcon,
      isDisabled: isActionDisabled,
    },
    {
      name: "Cancel",
      key: "2",
      onClick: () => handleCancelClick(),
      icon: isActionDisabled ?  actionIcons.cancelIcon : actionIcons.activeCancelIcon,
      isDisabled: isActionDisabled
    },
    {
      name: "Menu",
      key: "3",
      onClick: () => toggleMenu(),
      icon: isMenuOpen ? actionIcons.closeIcon : actionIcons.hamburgerMenuIcon,
    },
  ];

  return (
    <div>
      <div className="mb-2 relative">
        <NavBar
          title={displayName}
          description={nodeDescription}
          icons={NodeType !== "View"?catalogActions:catalogActions.slice(2)}
          token={token}
        />

        {isMenuOpen && (
          <div
            className="absolute top-full right-0 mt-1 bg-white border border-gray-200 rounded-lg shadow-lg"
            style={{ zIndex: "2000", marginRight: "3%" }}
          >
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={(event) =>
                handleFileChange(
                  event,
                  cataLogsHeaderState,
                  submenuName,
                  dataTypeConversions,
                  apiData,
                  setApiData,
                  columns,
                  setAddedRows
                )
              }
              accept=".csv"
            />
            <div className="flex flex-col">
              {NodeType === "Tag Graph" ||
              NodeType === "System Graph" ||
              NodeType === "Metadata Graph" ? (
                <MenuButtonComponent
                  className="shadow-lg border border-gray-300 rounded-md p-2"
                  handleMenuClick={handleNewCreateGraph}
                  name="Create Graph"
                  placement="left"
                  options={GraphDetailOptions.get(NodeType)}
                />
              ) : null}
              <ManageViewButton
                relationMetaInfo={relationshipMetaState}
                handle={handleManageOwnerTags}
                catalogName={catalogName}
                submenuName={submenuName}
                className="shadow-lg border border-gray-300 rounded-md p-2"
              />
                {NodeType === "View" ? null : (
              <span
                onClick={handleAuditLog}
                className="shadow-lg border border-gray-300 rounded-md p-2 menu-button"
              >
                View AuditLog
              </span>
              )}
              <Modal
                open={isAuditLog}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
                footer={null} // Remove default footer
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg">Audit Log Details</h3>
                  <Button type="primary" onClick={handleOk}>
                    OK
                  </Button>
                </div>
                <hr />
                <Table
                  className="mt-2"
                  dataSource={auditLogData}
                  columns={auditLogColumns}
                  scroll={{ x: 900 }}
                  pagination={false}
                  filters={
                    selectedFilter
                      ? { [selectedFilter]: [selectedFilter] }
                      : null
                  }
                />
                <CustomPagination
                  goToPrevPage={goToPrevPageAudit}
                  hasPrevPage={hasPrevPageAudit}
                  goToNextPage={goToNextPageAudit}
                  hasNextPage={hasNextPageAudit}
                  currentPage={currentPageAudit}
                  handlePageSizeChange={handlePageSizeChange}
                  pageSize={pageSize}
                />
              </Modal>

              {isOwnerTag && selectedRows?.length > 0 ? (
                <OwnerTagLayout
                  token={token}
                  catalogName={catalogName}
                  selectedNodeName={submenuName}
                  upstream={false}
                  setIsOwnerTag={setIsOwnerTag}
                  //Only 1 row is expected here! So thats why we just selected only 1 item!
                  selectedId={selectedRequiredId}
                  relationModalDetails={[
                    getNonGraphicalRelationshipTypes(
                      relationshipMetaState,
                      catalogName,
                      submenuName
                    )?.find((item) => {
                      return item["Relationship Type"] === tableName;
                    }),
                  ]}
                  submenuUniqueColName={getFirstUniqueIdItem()["Property Name"]}
                  relationshipType={tableName}
                />
              ) : null}
              {hasDownstreamRelation ? (
                <OwnerTagLayout
                  token={token}
                  catalogName={catalogName}
                  selectedNodeName={submenuName}
                  upstream={false}
                  setIsOwnerTag={setHasDownstreamRelation}
                  selectedId={selectedRequiredId}
                  relationModalDetails={getGraphicalRelationshipTypes(
                    relationshipMetaState,
                    catalogName,
                    submenuName
                  )}
                  submenuUniqueColName={getFirstUniqueIdItem()["Property Name"]}
                  relationshipType={"Graphical"}
                />
              ) : null}
              {hasUpstreamRelation ? (
                <OwnerTagLayout
                  token={token}
                  catalogName={catalogName}
                  selectedNodeName={submenuName}
                  upstream={true}
                  setIsOwnerTag={setHasUpstreamRelation}
                  selectedId={selectedRequiredId}
                  relationModalDetails={getGraphicalRelationshipTypes(
                    relationshipMetaState,
                    catalogName,
                    submenuName
                  )}
                  submenuUniqueColName={getFirstUniqueIdItem()["Property Name"]}
                  relationshipType={"Graphical"}
                />
              ) : null}
              {hasAsset && selectedRequiredId && selectedRows?.length > 0 ? (
                <OwnerTagLayout
                  token={token}
                  catalogName={catalogName}
                  selectedNodeName={submenuName}
                  upstream={true}
                  setIsOwnerTag={setHasAsset}
                  //Only 1 row is expected here! So thats why we just selected only 1 item!
                  selectedId={selectedRequiredId}
                  relationModalDetails={getNonGraphicalRelationTypesByNodeName(
                    relationshipMetaState,
                    submenuName
                  )}
                  submenuUniqueColName={getFirstUniqueIdItem()["Property Name"]}
                  relationshipType={tableName}
                />
              ) : null}
              {isModalVisible ? (
                <ModalForm
                  columns={columns}
                  nodeName={displayName}
                  setIsModalVisible={setIsModalVisible}
                  handleNewRowSubmit={handleNewRowSubmit}
                  isModalVisible={isModalVisible}
                />
              ) : null}
              {NodeType === "View" ? null : (
                <>
              <AddNewRowDeleteRowButtonComponent className="block px-4 py-2 text-gray-800 hover:bg-gray-100" />
              <ImportButtonComponent className="block px-4 py-2 shadow-lg border border-gray-300 rounded-md p-2" />
              </>
                )}
              <ExportButtonComponent className="block px-4 py-2 text-gray-800 hover:bg-gray-100" />
            </div>
          </div>
        )}
      </div>
      {catalogLoading ? (
        <HourGlass></HourGlass>
      ) : (
        <div className="data-catalog">
          <Checkbox defaultChecked={false} onChange={onChange}>
            <div className="font-newOne"> Select All</div>
          </Checkbox>
          <HotTable
            ref={hotRef}
            data={
              apiData[submenuName]?.length > 0
                ? JSON.parse(JSON.stringify(apiData[submenuName]))
                : [{}]
            }
            colHeaders={columns?.map((column) => {
              let headerText = column.header;
              if (column.columnConfig.uniqueId) {
                headerText += " <sup><b>id</b></sup"; 
              }
              if (column.columnConfig.isRequired) {
                headerText += ' <span title="required" class="text-red-600">*</span>';
              }
              return headerText;
            })}            
            height={500}
            columns={columns?.map((column) => column.columnConfig)}
            afterGetColHeader={(col, TH) => {
              TH.style.backgroundColor = themeState.sidenavColor;
              TH.style.color = "#fff";
              TH.style.zIndex = "1001";
            }}
            settings={{
              dropdownMenu: true,
              hiddenColumns: {
                indicators: true,
                columns: [0],
              },
              stretchH: "all",
              multiColumnSorting: true,
              filters: true,
              rowHeaders: true,
              afterColumnSort: (currentSortConfig, destinationSortConfigs) => {
                setSelectedRows([]);
              },
              afterFilter: (formulasStack) => {
                setSelectedRows([]);
              },
              manualRowMove: true,
              cells: function (row, col, prop) {
                const isUniqueId = columns[col].columnConfig.uniqueId;
                const submenuData = apiData[submenuName];

                if (
                  submenuData &&
                  submenuData[row] &&
                  isUniqueId &&
                  submenuData[row][prop] !== undefined
                ) {
                  return { readOnly: true };
                }
              },
              licenseKey: "non-commercial-and-evaluation",
            }}
            beforeRenderer={addClassesToRows}
            afterGetRowHeader={(row, TH) => {
              const hotInstance = hotRef?.current?.hotInstance;
              const rowData = hotInstance?.getData()[row];
              drawCheckboxInRowHeaders(
                rowData?.[0],
                TH,
                handleRowSelection,
                selectedRows
              );
            }}
            afterChange={(changes, source) =>
              handleAfterChange(changes, source, columns)
            }
            afterRemoveRow={handleAfterRemoveRow}
          />

          <div className="pagination-container flex">
            <div className="pagination-buttons-left">
              <Space wrap>
                <Button onClick={goToPrevPage} disabled={!hasPrevPage}>
                  Previous
                </Button>
                <Button>{currentPage}</Button>
                <Button onClick={goToNextPage} disabled={!hasNextPage}>
                  Next
                </Button>
              </Space>
            </div>
            {currentPage === 1 ? (
              <CustomPageSize
                pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default SubmenuComponent;
