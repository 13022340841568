import React from "react";
import CustomPageSize from "./CustomPageSize";
import { Button ,Space } from "antd";

const CustomPagination = ({ goToPrevPage, hasPrevPage, goToNextPage, hasNextPage,currentPage,handlePageSizeChange,pageSize}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1rem",
      }}
    >
      <div style={{ display: "inline" }}>
        <Space wrap>
          <Button onClick={goToPrevPage} disabled={!hasPrevPage}>
            Previous
          </Button>
          <Button>{currentPage}</Button>
          <Button onClick={goToNextPage} disabled={!hasNextPage}>
            Next
          </Button>
        </Space>
      </div>
      {currentPage === 1 ? (
        <div style={{ display: "inline" }}>
          <CustomPageSize
            pageSize={pageSize}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CustomPagination;
