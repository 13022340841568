import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faEdit } from "@fortawesome/free-solid-svg-icons";
import { updateTableDetails } from "../../config/Api";
import { fetchCataLogHeader } from "../../container/actions/catalogActions";
import { svgIcons } from "../../utils/svgIcons";
import { fetchGraphData } from "../../container/actions/graphActions";

function EdgeModal({
  data,
  isEdgeModalOpen,
  setIsEditModalOpen,
  token,
  currentFlowData,
  catalogName,
}) {
  const [edit, setEdit] = useState(false);
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState(data);
  const dispatch = useDispatch();
  const cataLogsHeaderState = useSelector(
    (catalogState) => catalogState.cataLogHeaderReducer
  );

  useEffect(() => {
    if (!cataLogsHeaderState["GraphicalRelationships"]) {
      dispatch(
        fetchCataLogHeader("GraphicalRelationships", token, catalogName)
      );
    }
  }, []);

  useEffect(() => {
    const requiredFields = cataLogsHeaderState[
      "GraphicalRelationships"
    ]?.data?.filter((element) => element["Property Type"] === "IS_OPTIONAL");
    setFields(requiredFields);
  }, [data, edit]);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleChange = (event, fieldName, edgeType) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    // Update the formData based on the specified edgeType
    setFormData((prevData) => ({
      ...prevData,
      [edgeType]: {
        ...prevData[edgeType],
        data: {
          ...prevData[edgeType]?.data,
          [fieldName]: value,
        },
      },
    }));
  };

  const updateFormDataKeys = (formData, data, edgeType) => {
    const mapping = data.reduce((acc, item) => {
      acc[item["Property Name"]] = item["Display Name"];
      return acc;
    }, {});

    const updatedFormData = {};
    for (const key in formData[edgeType]?.data) {
      updatedFormData[mapping[key] || key] = formData[edgeType]?.data[key];
    }

    return updatedFormData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { data: catalogData } =
      cataLogsHeaderState["GraphicalRelationships"] || {};
    let backwardResult = null; // Define backwardResult initially as null

    try {
      // Update forward edge
      const updatedForwardFormData = updateFormDataKeys(
        formData,
        catalogData,
        "forwardEdge"
      );
      const forwardResult = await updateTableDetails(
        "GraphicalRelationships",
        updatedForwardFormData,
        token,
        "PATCH",
        catalogName
      );

      // Update backward edge
      if (formData?.backwardEdge) {
        const updatedBackwardFormData = updateFormDataKeys(
          formData,
          catalogData,
          "backwardEdge"
        );
        backwardResult = await updateTableDetails(
          // Update backwardResult
          "GraphicalRelationships",
          updatedBackwardFormData,
          token,
          "PATCH",
          catalogName
        );
      }

      // Handle success or failure of both forward and backward edge updates
      if (
        ![400, 401, 500].includes(forwardResult?.code) &&
        (![400, 401, 500].includes(backwardResult?.code) ||
          !formData?.backwardEdge)
      ) {
        toast.success("Saved Successfully");
        if (uuidValidate(currentFlowData?.Id)) {
          const getGraphPayload = {
            Case: "SystemGraphRequest",
            Item: {
              Case: "TagNames",
              tags: [currentFlowData.Id],
            },
          };
          dispatch(
            fetchGraphData(
              token,
              getGraphPayload,
              currentFlowData["Catalog Name"]
            )
          );
        }
        setIsEditModalOpen(false);
      } else {
        toast.error(forwardResult?.message || backwardResult?.message);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error("An error occurred during form submission");
    }
  };

  return (
    <Modal
      title={null}
      open={isEdgeModalOpen}
      footer={null}
      width={edit ? 500 : 800}
    >
      <div className="relative py-2 px-2 md:px-10 bg-white rounded font-newOne">
        <div className="flex items-center justify-between  relative">
          <div className="flex items-center">
            {svgIcons.edgeConnection}
            <h1 className="text-gray-800 ml-2 text-xl font-bold tracking-normal leading-tight">
              {edit ? "Edit Connection Information" : " Connection Information"}
            </h1>
          </div>

          <div className="flex">
            <FontAwesomeIcon
              className="absolute top-0 right-0 -mt-6 -mr-6 cursor-pointe"
              icon={faCircleXmark}
              size="2x"
              color="red"
              onClick={() => setIsEditModalOpen(false)}
            />
          </div>
          {!edit ? (
            <FontAwesomeIcon
              icon={faEdit}
              className="text-blue-500 cursor-pointer"
              onClick={handleEdit}
              size="lg"
            />
          ) : null}
        </div>
        {edit ? (
          <>
            <form
              onSubmit={handleSubmit}
              className="bg-white shadow-md rounded p-2 "
            >
              <div className="grid grid-cols-1 gap-4">
                {/* Forward Edge Section */}

                {formData?.forwardEdge ? (
                  <div>
                    <h2 className="font-bold text-lg mb-4">
                      Forward Edge Details
                    </h2>
                    <div className="grid grid-cols-1 gap-4">
                      {fields?.map((element) => (
                        <div className="mb-2" key={element["Property Name"]}>
                          <label
                            htmlFor={element["Property Name"]}
                            className="block text-gray-700 text-sm font-bold mb-2"
                          >
                            {element["Display Name"]}
                          </label>
                          <input
                            type="text"
                            required
                            id={element["Property Name"]}
                            disabled={!edit}
                            onChange={(event) =>
                              handleChange(
                                event,
                                element["Property Name"],
                                "forwardEdge"
                              )
                            }
                            value={
                              formData?.forwardEdge?.data[
                                element["Property Name"]
                              ] || ""
                            }
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}

                {/* Backward Edge Section */}
                {formData?.backwardEdge && (
                  <div>
                    <h2 className="font-bold text-lg mb-4">
                      Backward Edge Details
                    </h2>
                    <div className="grid grid-cols-1 gap-4">
                      {fields?.map((element) => (
                        <div className="mb-2" key={element["Property Name"]}>
                          <label
                            htmlFor={element["Property Name"]}
                            className="block text-gray-700 text-sm font-bold mb-2"
                          >
                            {element["Display Name"]}
                          </label>
                          <input
                            type="text"
                            required
                            id={element["Property Name"]}
                            disabled={!edit}
                            onChange={(event) =>
                              handleChange(
                                event,
                                element["Property Name"],
                                "backwardEdge"
                              )
                            }
                            value={
                              formData?.backwardEdge?.data[
                                element["Property Name"]
                              ] || ""
                            }
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center justify-center w-full">
                <button
                  className="bg-indigo-700 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="py-4">
            { formData?.forwardEdge &&  ( <div className="mb-8">
              <h2 className="font-bold text-lg mb-4">Forward Edge Details</h2>
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="px-4 py-2">Display Name</th>
                      <th className="px-4 py-2">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cataLogsHeaderState["GraphicalRelationships"]?.data?.map(
                      (element, index) => (
                        <tr key={index}>
                          <td className="border px-4 py-2">
                            {element["Display Name"]}
                          </td>
                          <td className="border px-4 py-2">
                            {
                              formData?.forwardEdge?.data[
                                element["Property Name"]
                              ]
                            }
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>) }
           

            {formData?.backwardEdge && (
              <div>
                <h2 className="font-bold text-lg mb-4">
                  Backward Edge Details
                </h2>
                <div className="overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">Display Name</th>
                        <th className="px-4 py-2">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cataLogsHeaderState["GraphicalRelationships"]?.data?.map(
                        (element, index) => (
                          <tr key={index}>
                            <td className="border px-4 py-2">
                              {element["Display Name"]}
                            </td>
                            <td className="border px-4 py-2">
                              {
                                formData?.backwardEdge?.data[
                                  element["Property Name"]
                                ]
                              }
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default EdgeModal;
