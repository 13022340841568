import { DataTypeMapping } from "./data/constants";
import Handsontable from "handsontable";
import ReactDOMServer from "react-dom/server";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";
import crypto from "crypto-js";
import "./assets/styles/main.css";

export const DEFAULT_PAGE_SIZE = 20;

export const findCommonObjects = (array1, array2) => {
  const set1 = new Set(array2.map(JSON.stringify));
  const commonObjects = array1.filter((obj) => !set1.has(JSON.stringify(obj)));
  return commonObjects;
};

export const customComparator = (a, b, key) => {
  const aValue = a[key];
  const bValue = b[key];

  if (typeof aValue === "string" && typeof bValue === "string") {
    return aValue.localeCompare(bValue);
  } else if (typeof aValue === "number" && typeof bValue === "number") {
    return aValue - bValue;
  } else if (aValue instanceof Date && bValue instanceof Date) {
    return aValue - bValue;
  } else {
    return 0;
  }
};

export const findDifferences = (originalArray, updatedArray, columns) => {
  const editedArray = [];
  const addedArray = [];
  const uniqueColumns = columns.filter(
    (column) => column.columnConfig.uniqueId
  );

  if (uniqueColumns.length === 0) {
    return { editedArray, addedArray };
  }

  for (let updatedObj of updatedArray) {
    const matchedOriginalObj = originalArray?.find((orig) => {
      return uniqueColumns.every((column) => {
        const uniqueColumnName = column.header;
        return orig[uniqueColumnName] === updatedObj[uniqueColumnName];
      });
    });

    if (
      matchedOriginalObj &&
      !isEqual(matchedOriginalObj, updatedObj, columns)
    ) {
      editedArray.push(updatedObj);
    } else if (!matchedOriginalObj) {
      addedArray.push(updatedObj);
    }
  }

  return { editedArray, addedArray };
};

export const isEqual = (obj1, obj2, columns) => {
  const uniqueColumns = columns?.filter(
    (column) => column.columnConfig.uniqueId
  );

  // Check if all unique columns are equal
  const uniqueColumnsEqual = uniqueColumns?.every((column) => {
    const uniqueColumnName = column.header;
    return obj1[uniqueColumnName] === obj2[uniqueColumnName];
  });

  if (!uniqueColumnsEqual) {
    return false;
  }

  // Compare the rest of the properties (excluding unique and existence properties)
  const { exist: exist1, row: row1, ...rest1 } = obj1;
  const { exist: exist2, row: row2, ...rest2 } = obj2;

  if (JSON.stringify(rest1) !== JSON.stringify(rest2)) {
    return false;
  }

  return true;
};

export const isEquality = (obj1, obj2, columns) => {
  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object"
  ) {
    return obj1 === obj2;
  }

  // Check if the arrays have the same length
  if (obj1.length !== obj2.length) {
    return false;
  }

  for (let i = 0; i < obj1.length; i++) {
    const item1 = obj1[i];
    const item2 = obj2[i];

    // Compare each property value in the objects
    for (const key in item1) {
      // Skip the 'exist' and 'row' properties
      if (key === "exist" || key === "row") {
        continue;
      }

      const value1 = item1[key];
      const value2 = item2[key];

      // Check if the property values are objects and compare them recursively
      if (typeof value1 === "object" && typeof value2 === "object") {
        if (!isEqual(value1, value2)) {
          return false;
        }
      } else {
        // Compare the property values using strict equality
        if (value1 !== value2) {
          return false;
        }
      }
    }

    // Compare the unique column value
    const uniqueColumn = columns?.find(
      (column) => column?.columnConfig?.uniqueId
    );
    const uniqueColumnName = uniqueColumn ? uniqueColumn.header : "";

    if (item1[uniqueColumnName] !== item2[uniqueColumnName]) {
      return false;
    }
  }

  return true;
};

export const modifiedArray = (data) =>
  data.map(({ exist, row, id, ...rest }) => rest);

export const handleRowDelete = (
  submenuName,
  cataLogsState,
  setApiData,
  row
) => {
  if (
    cataLogsState.hasOwnProperty(submenuName) &&
    cataLogsState[submenuName].data?.length > 0
  ) {
    setApiData((prevApiData) => {
      const updatedData = { ...prevApiData };
      const updatedRows = [...updatedData[submenuName]];
      updatedRows.splice(row, 1);
      updatedData[submenuName] = updatedRows;
      return updatedData;
    });
  }
};

// columnUtils.js

export const getColumnsFromData = (
  data,
  cataLogsEnumValues,
  submenuName,
  cataLogsState,
  setApiData
) => {
  if (!data || data?.length === 0) {
    return [];
  }

  Handsontable.cellTypes.registerCellType("custom-actions", {
    renderer: (instance, td, row, col, prop, value, cellProperties) => {
      while (td?.firstChild) {
        td?.removeChild(td.firstChild);
      }
      const container = document.createElement("div");
      container.style.display = "flex";
      container.style.justifyContent = "center";
      container.style.alignItems = "center";
      const deleteButton = document.createElement("button");
      deleteButton.innerHTML = ReactDOMServer.renderToString(
        <FontAwesomeIcon icon={faTrash} />
      );

      deleteButton.addEventListener("click", () => {
        handleRowDelete(submenuName, cataLogsState, setApiData, row);
      });

      container.appendChild(deleteButton);
      td.appendChild(container);
    },
  });

  const columns = data?.map((element, index) => {
    let columnName = element["Display Name"];
    const dataType = element["Data Type"];
    const columnType =
      dataType === "Enum" ? "dropdown" : DataTypeMapping[dataType];
    const dropdownValues =
      dataType === "Enum"
        ? cataLogsEnumValues[element["Property Name"]]
        : undefined;
    const extractedValues = dropdownValues?.map((item) => item["Enum Value"]);


    return {
      header: columnName,
      columnConfig: {
        data: columnName, // Using unmodified columnName as data
        type: columnType,
        uniqueId: element["Property Type"] === "IS_IDENTITY",
        isRequired: element["Property Type"] === "IS_REQUIRED",
        source: extractedValues,
      },
      displayOrder: element["Display Order"] || index,
    };
  });

  const idColumn = {
    header: "ID",
    columnConfig: {
      data: "id",
      type: "text",
      uniqueId: true,
      isRequired: true,
      hidden: true,
    },
    displayOrder: -1,
  };
  columns.unshift(idColumn);

  columns.sort((a, b) => a.displayOrder - b.displayOrder);

  return columns;
};

const generateID = (value) => {
  const hashId = crypto.MD5(value).toString();
  return hashId;
};

export const generateUniqueId = (uniqueValues, uniqueColumnHeaders) => {
  const concatenatedValue = uniqueColumnHeaders
    .map((header) => uniqueValues[header])
    .join("_");
  return generateID(concatenatedValue);
};

export const handleFileChange = (
  event,
  cataLogsHeaderState,
  submenuName,
  dataTypeConversions,
  apiData,
  setApiData,
  columns,
  setAddedRows
) => {
  const file = event.target.files[0];
  Papa.parse(file, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    complete: (results) => {
      const parsedData = results?.data;
      const convertedData = parsedData?.map((item) => {
        const id = uuidv4();

        const convertedItem = {};
        for (const key in item) {
          const dataTypeInfo = cataLogsHeaderState[submenuName]?.data?.find(
            (data) => data["Display Name"] === key
          );

          if (dataTypeInfo) {
            const dataType = dataTypeInfo["Data Type"];
            const conversionFunction = dataTypeConversions[dataType];
            if (conversionFunction) {
              convertedItem[key] = conversionFunction(item[key]);
            } else {
              convertedItem[key] = item[key];
            }
          }
        }

        // Assign the generated ID to the converted item
        convertedItem["id"] = id;

        return convertedItem;
      });

      // Add or update columns to each row of parsedData
      const updatedParsedData = convertedData?.map((row) => {
        const newRow = { ...row };
        return newRow;
      });

      const updatedData = {
        ...apiData,
        [submenuName]: [...updatedParsedData, ...(apiData[submenuName] || [])],
      };
      setApiData(updatedData);
      setAddedRows((prevAddedRows) => [...prevAddedRows, ...updatedParsedData]);
    },
  });
};

export const selectAllRows = (hotRef, setSelectedRows, checked) => {
  const hotInstance = hotRef.current.hotInstance;

  const data = hotInstance.getData();

  if (checked) {
    const allIds = data.map((row) => row[0]); // Assuming each row in apiData has an 'id' property
    // Set all row IDs when 'Select All' is checked
    setSelectedRows(allIds);
    // Logic to select all rows in Handsontable
    hotInstance.selectAll(false);
  } else {
    // Clear all row selections when 'Select All' is unchecked
    setSelectedRows([]);
    // Logic to deselect all rows in Handsontable
    hotInstance.deselectCell();
  }
};

const filterNonGraphicalRelationshipTypes = (
  relationshipInfoData,
  catalogName,
  nodeName
) => {
  return relationshipInfoData?.filter((value) => {
    if (
      catalogName === value["Catalog Name"] &&
      nodeName === value["Source Node"] &&
      value["Relationship Type"] !== "Graphical"
    ) {
      return true;
    } else return false;
  });
};

export function getNonGraphicalRelationTypesByNodeName(
  relationshipInfoData,
  nodeName
) {
  return relationshipInfoData?.filter((value) => {
    if (
      nodeName === value["Destination Node"] &&
      value["Relationship Type"] !== "Graphical"
    ) {
      return true;
    } else return false;
  });
}

export const getNonGraphicalRelationshipTypes = (
  relationshipInfoData,
  catalogName,
  nodeName
) => {
  const details = filterNonGraphicalRelationshipTypes(
    relationshipInfoData,
    catalogName,
    nodeName
  );
  return details;
};

const filterGraphicalRelationshipTypes = (
  relationshipInfoData,
  catalogName,
  nodeName
) => {
  return relationshipInfoData?.filter((value) => {
    if (
      catalogName === value["Catalog Name"] &&
      (nodeName === value["Source Node"] ||
        nodeName === value["Destination Node"]) &&
      value["Relationship Type"] === "Graphical"
    ) {
      return true;
    } else return false;
  });
};

export const getGraphicalRelationshipTypes = (
  relationshipInfoData,
  catalogName,
  nodeName
) => {
  const details = filterGraphicalRelationshipTypes(
    relationshipInfoData,
    catalogName,
    nodeName
  );
  return details;
};
