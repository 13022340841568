const cloudServer = [
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 1024 1024"
    class="icon"
    version="1.1"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M642.285617 470.217984l-281.556553 162.55486v273.261577l281.556553-162.554859V470.217984z"
        fill="#4FC1F4"
      ></path>
      <path
        d="M466.861125 894.140311L67.472294 663.553322c-5.332051-3.078048-9.658284 0.193893-9.658284 7.301275v106.91975c0 4.974562 3.029575 10.767108 6.762011 12.924166l392.62076 226.678837c5.332051 3.078048 9.664343-0.193893 9.664344-7.301275v-115.935764zM889.335314 664.316775c0-5.519885-3.356769-8.058669-7.501227-5.671364L466.861125 898.236296v110.022034c0 8.282857 5.041212 12.094062 11.25184 8.507046l404.660291-233.628682c3.623371-2.090407 6.562059-7.713297 6.562058-12.542439v-106.27748z"
        fill="#C4CADD"
      ></path>
      <path
        d="M475.913494 529.052325l-324.606809 187.40949 320.765308 185.1979 324.612868-187.415549-320.771367-185.191841z"
        fill="#243462"
      ></path>
      <path
        d="M448.168649 896.933579l43.45016-3.356769 8.064728 81.047182-28.314405 16.347585s-3.241645 3.374946-11.209427-1.266362-11.991057-92.771636-11.991056-92.771636z"
        fill="#C4CADD"
      ></path>
      <path
        d="M97.125771 723.726735l-10.718635-6.186392v55.259442l10.718635 6.186392v-55.259442zM126.930727 740.934719l-10.718635-6.186392v55.259443l10.718635 6.186391v-55.259442zM156.735683 758.142703l-10.718636-6.186391v55.259442l10.718636 6.186391v-55.259442zM186.534579 775.350687l-10.718635-6.186391v55.253383l10.718635 6.192451v-55.259443z"
        fill="#848BA6"
      ></path>
      <path
        d="M404.088337 916.989363c6.398462 3.696081 11.597212 12.699977 11.597212 20.08608 0 7.392162-5.19875 10.391441-11.597212 6.701419-6.404521-3.696081-11.603271-12.699977-11.603271-20.092139 0-7.392162 5.19875-10.391441 11.603271-6.69536z"
        fill="#4FC1F4"
      ></path>
      <path
        d="M453.046264 1014.977927s13.639145 10.96706 27.823614-0.109065c14.17841-11.082184 0.648329-42.498874 0.648329-42.498874l-28.150808 0.090887-0.321135 42.517052z"
        fill="#C4CADD"
      ></path>
      <path
        d="M466.861125 903.089674s3.908151 0.624092 8.367685-0.878576c2.302477-0.775571 4.198991-1.278481 6.477231-3.011398l22.340083-12.972638v52.829723l-19.813418 11.572975v32.870886l19.813418-11.306373v29.289928l-24.345662 14.360184s-2.520606 1.532965-4.98668 2.575138a16.680838 16.680838 0 0 1-4.235345 1.151239c-0.721039 0.078769-1.4239 0.181774-2.023756 0.218129-0.92705 0.048473-1.593556 0.030296-1.593556 0.030296v-116.729513z"
        fill="#A3ABC1"
      ></path>
      <path
        d="M58.419925 663.838102c1.460255-2.417601 8.155615-0.799808 8.155615-0.799807l391.778539 226.194105s5.598654 5.538063 15.572014 0.824044c9.070547-4.283819 344.989788-199.006702 405.987244-234.367898 6.095504-3.538543 9.421977 4.538303 9.421977 4.538303l-0.018177 9.561338-407.235429 235.113173s-11.736572 10.227844-32.452804-1.157298C428.918731 892.358921 57.81401 677.525721 57.81401 677.525721s-0.85434-11.270018 0.605915-13.687619z"
        fill="#F3F5FB"
      ></path>
      <path
        d="M467.224674 431.603025l10.488387-4.277759 403.987725 224.400597-0.793748 4.429238-27.199522 14.723733L467.224674 447.629475v-16.02645z"
        fill="#F3F5FB"
      ></path>
      <path
        d="M881.700786 651.725863s8.216207 2.544843 7.634528 8.979659l-4.798846 1.890455-5.362347-4.13234-0.860399-4.786728 3.387064-1.951046zM69.78083 663.335193l-0.206011-12.154654L454.682234 428.840053l12.54244 2.762972v15.050927L81.523461 669.33981l-11.742631-6.004617z"
        fill="#F3F5FB"
      ></path>
      <path
        d="M454.136911 429.155129s9.791585-7.919308 23.57615-1.829863l-1.70868 5.138159-9.143256 8.731234-5.065449-1.375427-3.950566-4.859438-3.708199-5.804665z"
        fill="#F3F5FB"
      ></path>
      <path
        d="M466.855066 444.096991l1.672325-1.436018 398.334538 217.626468c1.181534 0.648329 1.508728 1.205771 1.545083 1.841981 0.036355 0.64227-0.224189 1.363309-1.339072 1.99346l-87.724364 50.000101-312.736936-180.556592 0.248426-89.4694z"
        fill="#C4CADD"
      ></path>
      <path
        d="M464.467761 443.139646s1.642029-1.223948 4.05963-0.478673l-0.036355 0.957346-1.64203 1.357249-2.078288-0.914932-0.302957-0.92099z"
        fill="#C4CADD"
      ></path>
      <path
        d="M838.72324 737.735488c6.404521-3.70214 11.603271-0.696802 11.603271 6.701419 0 7.392162-5.19875 16.402117-11.603271 20.098199-6.41058 3.70214-11.60933 0.696802-11.60933-6.701419 0-7.392162 5.19875-16.402117 11.60933-20.098199z"
        fill="#4FC1F4"
      ></path>
      <path
        d="M788.662548 777.386562c0-8.858476-5.398702-12.936284-12.03953-9.100843l-197.067774 113.772648c-6.646887 3.841501-12.03953 14.148114-12.03953 23.00659 0 8.858476 5.392643 12.936284 12.03953 9.100842l197.067774-113.772647c6.640828-3.841501 12.03953-14.148114 12.03953-23.00659z"
        fill="#848BA6"
      ></path>
      <path
        d="M57.81401 671.539281s-3.235586-14.614668 16.280934-22.964176l-4.053571 5.719837c21.322147 22.861171 16.499064 26.163407-9.597692 13.53614l-2.629671 3.708199z"
        fill="#F3F5FB"
      ></path>
      <path
        d="M155.214836 714.207811L466.861125 534.281371V444.672611s-0.896754-2.405482-2.593316-1.42996c-30.616882 17.589711-322.940543 185.591745-383.762285 221.146834-1.357249 0.799808-3.217408 2.472133-2.781149 4.392883 0.369608 1.617793 2.841741 3.508247 3.944506 4.241405 12.451552 8.307094 73.545955 41.184038 73.545955 41.184038z"
        fill="#C4CADD"
      ></path>
      <path
        d="M307.608502 656.797371l310.119383-30.671414-1.07247 113.596932c-0.012118 0.975523 0.036355 1.987401-0.321135 2.684203-0.363549 0.696802-0.454436 0.690743-1.841981 1.490551l-133.834491 77.266273c-2.217649 1.28454-4.48983 2.290358-6.665065 2.338831-2.169175 0.042414-4.247464-0.872518-6.374225-2.06011L311.140986 733.797041c-2.284299-1.278481-2.35095-1.205771-2.938688-2.387305-0.587737-1.181534-0.593797-2.999279-0.593796-4.707959v-69.904406z"
        fill="#4FC1F4"
      ></path>
      <path
        d="M473.59284 684.566452L64.545725 448.398987v79.865648c0 3.320414 2.017697 7.180092 4.508007 8.616111l398.098232 229.841713c3.550662 2.054052 6.440876-0.127242 6.440876-4.865497V684.566452zM896.042793 440.661454L473.59284 684.566452v79.865648c0 3.314355 2.017697 4.84126 4.508007 3.405242l413.433939-238.69413c2.49031-1.436018 4.508007-5.301756 4.508007-8.616111V440.661454z"
        fill="#BBC6E6"
      ></path>
      <path
        d="M471.538788 645.963612l2.054052 38.60284 422.449953-243.904998 0.163597-32.161965-424.667602 237.464123z"
        fill="#4867BC"
      ></path>
      <path
        d="M65.369769 408.020816l-0.824044 40.378171 409.047115 236.167465v-37.39707L65.369769 408.020816z"
        fill="#4867BC"
      ></path>
      <path
        d="M508.893444 175.876626c-14.135995-8.161674-35.549029-9.04631-47.788511-1.981342L73.507207 397.671789c-12.239482 7.071027-10.700458 19.431692 3.429478 27.593366l375.624847 216.863015c14.129936 8.161674 35.549029 9.052369 47.788511 1.981342l387.597726-223.776504c12.239482-7.064968 10.700458-19.431692-3.435537-27.593367L508.893444 175.876626z"
        fill="#375096"
      ></path>
      <path
        d="M454.900364 673.805403l43.45016-3.356769 8.064728 81.041123-28.314405 16.347585s-3.241645 3.381005-11.209427-1.260303c-7.967781-4.647368-11.991057-92.771636-11.991056-92.771636z"
        fill="#BBC6E6"
      ></path>
      <path
        d="M64.339714 439.322382l401.036919 230.090138s7.816303 4.405002 17.789663-0.302958c9.979419-4.714018 412.92497-237.718607 412.92497-237.718607l-0.048473 9.270499-407.22937 235.113173s-11.736572 10.227844-32.452804-1.157298c-20.710173-11.385142-391.814894-226.218342-391.814894-226.218342l-0.206011-9.076605z"
        fill="#F3F5FB"
      ></path>
      <path
        d="M103.857486 486.408031l-10.718635-6.186391v55.259442l10.718635 6.186392v-55.259443zM133.662442 503.616016l-10.718636-6.186392v55.259442l10.718636 6.186392v-55.259442zM163.467397 520.824l-10.718635-6.186392v55.253383l10.718635 6.192451v-55.259442zM193.266294 538.031984l-10.718635-6.192451v55.259443l10.718635 6.186391v-55.253383zM410.813993 677.871092c6.404521 3.696081 11.603271 12.693918 11.603271 20.08608 0 7.392162-5.19875 10.391441-11.603271 6.695361-6.398462-3.696081-11.597212-12.693918-11.597212-20.086081 0-7.392162 5.19875-10.391441 11.597212-6.69536zM845.454955 497.302382c6.404521-3.70214 11.603271-0.696802 11.603271 6.701419 0 7.392162-5.19875 16.402117-11.603271 20.098199-6.41058 3.70214-11.60933 0.696802-11.609331-6.70142 0-7.392162 5.19875-16.402117 11.609331-20.098198z"
        fill="#637DC6"
      ></path>
      <path
        d="M473.59284 679.961499s4.247464 0.21207 8.700938-0.975523c2.302477-0.618033 4.241405-1.835922 6.519645-3.211349l21.964416-12.6818v39.626837l-19.813418 11.433615v26.060401l19.601348-11.318491v20.58899l-33.173843 18.850013s-0.35749 0.193893-0.793748 0.351431c-0.363549 0.121183-0.78163 0.206011-1.066411 0.254484-0.611974 0.103006-1.938928 0.048473-1.938927 0.048474V679.961499z"
        fill="#8EA1D6"
      ></path>
      <path
        d="M795.394263 539.607363c0-8.864536-5.398702-12.942343-12.03953-9.100842L586.286959 644.279168c-6.646887 3.835442-12.03953 14.142055-12.03953 23.00659 0 8.858476 5.392643 12.936284 12.03953 9.100843l197.067774-113.778707c6.640828-3.835442 12.03953-14.142055 12.03953-23.000531z"
        fill="#637DC6"
      ></path>
      <path
        d="M722.581465 386.577486v21.134313l-14.626787 6.755952-2.944747-19.225681 17.571534-8.664584z"
        fill="#98AADA"
      ></path>
      <path
        d="M494.418136 267.587911c-11.494206-6.634769-28.908202-7.355807-38.863384-1.611734L231.069327 395.587442c-9.949123 5.744074-8.700938 15.796202 2.793268 22.43097l218.656523 126.242378c11.488147 6.634769 28.902142 7.355807 38.857325 1.611733l224.485425-129.605205c9.949123-5.750133 8.700938-15.802262-2.793268-22.43703L494.418136 267.587911z"
        fill="#98A9D9"
      ></path>
      <path
        d="M224.355789 383.596385v20.958598l8.822122 7.755711 8.537341-16.959559-17.359463-11.75475z"
        fill="#98AADA"
      ></path>
      <path
        d="M494.418136 245.974925c-11.494206-6.634769-28.908202-7.355807-38.863384-1.611733l-224.485425 129.605205c-9.949123 5.750133-8.700938 15.802262 2.793268 22.43703l218.656523 126.242377c11.488147 6.634769 28.902142 7.355807 38.857325 1.611734l224.485425-129.611265c9.949123-5.744074 8.700938-15.796202-2.793268-22.430971L494.418136 245.974925z"
        fill="#4463B9"
      ></path>
      <path
        d="M473.102049 290.121888l50.412122 29.108153v9.700698l37.281946 21.528158h15.620487l57.76793 33.355617-46.116185 26.623903h-15.984036l-37.603081 21.709932v11.621448l-57.452855 33.173843-52.411642-30.259392v-9.409859l-37.342537-21.558453h-17.583652l-56.943886-33.016305 48.970046-28.271991 17.329167-0.151479 36.863865-21.285792-0.048474-9.821881 57.240785-33.0466z"
        fill="#3E5AA9"
      ></path>
      <path
        d="M473.102049 281.457304l50.412122 29.102094v9.706758l37.281946 21.528157h15.620487l57.76793 33.349559-46.116185 26.629961h-15.984036l-37.603081 21.709932v11.621449l-57.452855 33.173842-52.411642-30.265451v-9.403799l-37.342537-21.558454h-17.583652l-56.943886-33.016305 48.970046-28.271991 17.329167-0.157538 36.863865-21.279732-0.048474-9.821881 57.240785-33.046601z"
        fill="#637DC6"
      ></path>
      <path
        d="M417.515412 355.651588c8.216207-4.744314 21.564513-4.744314 29.780719 0 8.216207 4.744314 8.216207 12.445493 0 17.189807-8.216207 4.750373-21.564513 4.750373-29.780719 0-8.222266-4.744314-8.222266-12.445493 0-17.189807z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M446.80534 349.422782c5.859197-3.381005 15.378121-3.381005 21.237319 0 5.859197 3.381005 5.859197 8.876654 0 12.25766s-15.378121 3.381005-21.237319 0c-5.859197-3.381005-5.859197-8.876654 0-12.25766z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M461.298826 353.312756c12.069826-6.968022 31.671174-6.968022 43.740999 0 12.075885 6.974081 12.075885 18.286513 0 25.260594-12.069826 6.968022-31.671174 6.968022-43.740999 0-12.075885-6.974081-12.075885-18.286513 0-25.260594z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M473.417124 381.978592c11.064007-6.386343 29.023325-6.386343 40.081273 0 11.057948 6.386343 11.057948 16.753548 0 23.139891-11.057948 6.386343-29.017266 6.386343-40.081273 0-11.057948-6.386343-11.057948-16.753548 0-23.139891z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M447.296131 355.651588l-29.780719 17.189807 55.901712 32.277088 29.780719-17.189806-55.901712-32.277089z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M156.378193 418.17595s-4.350469 17.001973 14.051167 27.629721l295.135107 170.395399s11.421497 9.518924 34.506855-7.810244l312.2825-180.296048s18.795481-9.700698 11.603271-29.05968L473.59284 580.906525 156.378193 418.17595z"
        fill="#4FC1F4"
      ></path>
      <path
        d="M473.59284 515.443475L64.545725 279.282069v79.865648c0 3.314355 2.017697 7.174033 4.508007 8.610051l398.098232 229.841713c3.550662 2.054052 6.440876-0.127242 6.440876-4.865496V515.443475zM896.042793 271.538476L473.59284 515.443475v79.865648c0 3.320414 2.017697 4.847319 4.508007 3.411301l413.433939-238.700189c2.49031-1.436018 4.508007-5.295697 4.508007-8.616111V271.538476z"
        fill="#BBC6E6"
      ></path>
      <path
        d="M471.538788 476.840635l2.054052 38.60284 422.449953-243.904999 0.163597-32.161964-424.667602 237.464123z"
        fill="#4867BC"
      ></path>
      <path
        d="M65.369769 238.897839l-0.824044 40.38423 409.047115 236.161406v-37.39707L65.369769 238.897839z"
        fill="#4867BC"
      ></path>
      <path
        d="M508.893444 6.753649C494.757448-1.408025 473.344414-2.292661 461.104933 4.772307L73.507207 228.554871c-12.239482 7.064968-10.700458 19.431692 3.429478 27.587307L452.561532 473.011252c14.129936 8.155615 35.549029 9.04631 47.788511 1.981342l387.597726-223.782564c12.239482-7.064968 10.700458-19.431692-3.435537-27.587307L508.893444 6.753649z"
        fill="#375096"
      ></path>
      <path
        d="M454.900364 504.682426l43.45016-3.356769 8.064728 81.041123-28.314405 16.353644s-3.241645 3.374946-11.209427-1.266362-11.991057-92.771636-11.991056-92.771636z"
        fill="#BBC6E6"
      ></path>
      <path
        d="M64.339714 270.199404l401.036919 230.096198s7.816303 4.405002 17.789663-0.309017c9.979419-4.707959 412.92497-237.712548 412.92497-237.712548l-0.048473 9.264439-407.22937 235.113173s-11.736572 10.227844-32.452804-1.157297C435.650446 494.10921 64.545725 279.282069 64.545725 279.282069l-0.206011-9.082665z"
        fill="#F3F5FB"
      ></path>
      <path
        d="M103.857486 317.285054l-10.718635-6.186391v55.259442l10.718635 6.186391v-55.259442zM133.662442 334.493038l-10.718636-6.186391v55.259442l10.718636 6.186392v-55.259443zM163.467397 351.701023l-10.718635-6.186392v55.259442l10.718635 6.186392v-55.259442zM193.266294 368.909007l-10.718635-6.186392v55.253383l10.718635 6.192451v-55.259442zM410.813993 508.748115c6.404521 3.696081 11.603271 12.693918 11.603271 20.08608 0 7.392162-5.19875 10.391441-11.603271 6.69536-6.398462-3.696081-11.597212-12.693918-11.597212-20.08608 0-7.392162 5.19875-10.391441 11.597212-6.69536zM845.454955 328.179405c6.404521-3.696081 11.603271-0.696802 11.603271 6.701419s-5.19875 16.402117-11.603271 20.104258c-6.41058 3.696081-11.60933 0.690743-11.609331-6.70142 0-7.398221 5.19875-16.408176 11.609331-20.104257z"
        fill="#637DC6"
      ></path>
      <path
        d="M473.59284 510.838522s4.247464 0.218129 8.700938-0.975523c2.302477-0.611974 4.241405-1.835922 6.519645-3.21135l21.964416-12.67574v39.620778l-19.813418 11.439674v26.054342l19.601348-11.312432v20.58899l-33.173843 18.850013s-0.35749 0.193893-0.793748 0.345372a6.846839 6.846839 0 0 1-1.066411 0.254484c-0.611974 0.109065-1.938928 0.054532-1.938927 0.054532V510.838522z"
        fill="#8EA1D6"
      ></path>
      <path
        d="M795.394263 370.484386c0-8.858476-5.398702-12.936284-12.03953-9.100843L586.286959 475.156191c-6.646887 3.841501-12.03953 14.148114-12.03953 23.00659 0 8.858476 5.392643 12.936284 12.03953 9.100843l197.067774-113.778707c6.640828-3.835442 12.03953-14.142055 12.03953-23.000531z"
        fill="#637DC6"
      ></path>
      <path
        d="M494.418136 132.511294c-11.494206-6.640828-28.908202-7.361866-38.863384-1.611734l-224.485425 129.605205c-9.949123 5.744074-8.700938 15.796202 2.793268 22.437031L452.519118 409.178114c11.488147 6.634769 28.902142 7.361866 38.857325 1.611733l224.485425-129.605205c9.949123-5.744074 8.700938-15.802262-2.793268-22.43703L494.418136 132.511294z"
        fill="#323654"
      ></path>
      <path
        d="M722.581465 177.77314v21.134313l-14.626787 6.755952-2.944747-19.231741 17.571534-8.658524z"
        fill="#98AADA"
      ></path>
      <path
        d="M494.418136 58.783565c-11.494206-6.634769-28.908202-7.355807-38.863384-1.611734l-224.485425 129.605205c-9.949123 5.750133-8.700938 15.802262 2.793268 22.43703l218.656523 126.242377c11.488147 6.634769 28.902142 7.355807 38.857325 1.605675l224.485425-129.605205c9.949123-5.744074 8.700938-15.796202-2.793268-22.430971L494.418136 58.783565z"
        fill="#98A9D9"
      ></path>
      <path
        d="M224.355789 174.792039v20.958597l8.822122 7.755711 8.537341-16.959559-17.359463-11.754749z"
        fill="#98AADA"
      ></path>
      <path
        d="M494.418136 37.170579c-11.494206-6.634769-28.908202-7.355807-38.863384-1.611734l-224.485425 129.605205c-9.949123 5.750133-8.700938 15.802262 2.793268 22.43703l218.656523 126.236318c11.488147 6.640828 28.902142 7.361866 38.857325 1.611734l224.485425-129.605205c9.949123-5.744074 8.700938-15.796202-2.793268-22.43703L494.418136 37.170579z"
        fill="#4463B9"
      ></path>
      <path
        d="M473.102049 81.317541l50.412122 29.102095v9.706757l37.281946 21.528158h15.620487l57.76793 33.349558-46.116185 26.623902h-15.984036L534.481232 223.344002v11.621449l-57.452855 33.167784-52.411642-30.259392v-9.4038l-37.342537-21.558454h-17.583652l-56.943886-33.016305 48.970046-28.271991 17.329167-0.157537 36.863865-21.279733-0.048474-9.821881 57.240785-33.046601z"
        fill="#3E5AA9"
      ></path>
      <path
        d="M473.102049 72.652958l50.412122 29.102094v9.706757l37.281946 21.528158h15.620487l57.76793 33.349558-46.116185 26.623902h-15.984036l-37.603081 21.709933v11.627507l-57.452855 33.167784-52.411642-30.259392v-9.4038l-37.342537-21.558453h-17.583652l-56.943886-33.016305 48.970046-28.271991 17.329167-0.157538 36.863865-21.285792-0.048474-9.815822 57.240785-33.0466z"
        fill="#637DC6"
      ></path>
      <path
        d="M417.515412 146.841182c8.216207-4.744314 21.564513-4.744314 29.780719 0 8.216207 4.750373 8.216207 12.451552 0 17.195866-8.216207 4.744314-21.564513 4.744314-29.780719 0-8.222266-4.744314-8.222266-12.445493 0-17.195866z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M446.80534 140.618436c5.859197-3.387064 15.378121-3.387064 21.237319 0 5.859197 3.381005 5.859197 8.870595 0 12.257659-5.859197 3.381005-15.378121 3.381005-21.237319 0-5.859197-3.387064-5.859197-8.876654 0-12.257659z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M461.298826 144.50841c12.069826-6.968022 31.671174-6.968022 43.740999 0 12.075885 6.968022 12.075885 18.286513 0 25.254535-12.069826 6.974081-31.671174 6.974081-43.740999 0-12.075885-6.968022-12.075885-18.286513 0-25.254535z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M473.417124 173.174246c11.064007-6.386343 29.023325-6.386343 40.081273 0 11.057948 6.386343 11.057948 16.753548 0 23.139891-11.057948 6.386343-29.017266 6.386343-40.081273 0-11.057948-6.386343-11.057948-16.753548 0-23.139891z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M447.296131 146.841182l-29.780719 17.195866 55.901712 32.277089 29.780719-17.195866-55.901712-32.277089z"
        fill="#D9DFF1"
      ></path>
      <path
        d="M829.325499 756.561265l99.557883 55.41698s34.04636 9.155375 34.046361-36.645735V605.458198s0.260543-24.993991-27.835733-41.220393L748.005655 456.227408l-18.971196 10.954942 183.034784 105.671565s24.909163 14.796443 29.380815 31.525755c4.980621 18.619766 1.442078 167.044689 1.442078 167.044689s-0.248425 26.969274-17.038328 17.274634c-16.789903-9.68858-80.114073-41.614238-80.114073-41.614237l-16.414236 9.476509zM397.550515 923.799847l15.226642 8.791826-150.042715 86.621599s-32.41039 16.389999-32.41039-36.997166v-388.330883s-3.138639-27.520656 25.406013-44.001543l57.065069-32.943595 15.771965 9.100842-63.978558 40.856845s-12.481848 8.579756-14.517722 31.222796c-2.169175 24.115414-4.253523 388.985271 0 386.276832 0 0-1.005819 26.787499 16.087042 16.917145l125.787941-72.624965 5.604713-4.889733z"
        fill="#4FC1F4"
      ></path>
    </g>
  </svg>,
];

const dataStorage = [
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 1024 1024"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M172.261392 465.745702l-0.539234 376.625021 326.285617 180.627064v-375.786213L172.261392 465.745702z"
        fill="#4762AF"
      ></path>
      <path
        d="M743.081477 435.363404l50.121532 15.817532-41.815149 39.734468-8.306383-55.552zM235.558754 438.021447l-25.469277 23.895149 21.776341 20.153191 3.692936-44.04834z"
        fill="#6982C8"
      ></path>
      <path
        d="M521.750414 667.549957c-13.105021-7.271489-32.768-8.170213-43.890383-1.998978L178.966414 831.400851c-11.122383 6.171234-9.510128 17.081191 3.594893 24.352681l291.70383 161.857362c13.105021 7.271489 32.768 8.164766 43.890383 1.998978l298.893617-165.849872c11.122383-6.171234 9.510128-17.081191-3.594894-24.352681L521.750414 667.549957z"
        fill="#4762AF"
      ></path>
      <path
        d="M532.720286 13.148596c0-11.035234-6.737702-16.122553-15.044085-11.362043L246.909903 157.107745c-8.300936 4.760511-15.044085 17.582298-15.044085 28.617532v596.098723c0 11.029787 6.743149 16.122553 15.044085 11.362043l270.766298-155.321192c8.306383-4.760511 15.044085-17.587745 15.044085-28.617532V13.148596z"
        fill="#C4CADD"
      ></path>
      <path
        d="M526.717903 0.604596l14.542979 8.279149-15.937362 7.473021-6.955574-7.974128L526.717903 0.604596z"
        fill="#C4CADD"
      ></path>
      <path
        d="M546.560626 21.090043c0-11.035234-6.743149-16.128-15.044085-11.36749L260.750243 165.043745c-8.300936 4.760511-15.044085 17.587745-15.044085 28.622978v596.093277c0 11.035234 6.743149 16.128 15.044085 11.362043l270.766298-155.315745c8.300936-4.765957 15.044085-17.587745 15.044085-28.622979V21.090043z"
        fill="#848BA6"
      ></path>
      <path
        d="M602.842499 75.389277c0-11.035234-6.743149-16.128-15.044085-11.362043L317.032116 219.342979c-8.300936 4.765957-15.044085 17.587745-15.044085 28.622978v596.093277c0 11.035234 6.743149 16.128 15.044085 11.367489l270.766298-155.321191c8.300936-4.760511 15.044085-17.587745 15.044085-28.622979V75.389277z"
        fill="#C4CADD"
      ></path>
      <path
        d="M596.840116 62.845277l14.542978 8.279149-15.937361 7.467574-6.955575-7.968681 8.349958-7.778042z"
        fill="#C4CADD"
      ></path>
      <path
        d="M616.682839 83.325277c0-11.029787-6.743149-16.122553-15.044085-11.362043L330.872456 227.284426c-8.306383 4.760511-15.044085 17.587745-15.044085 28.617531v596.098724c0 11.035234 6.737702 16.128 15.044085 11.362042l270.766298-155.315744c8.300936-4.765957 15.044085-17.587745 15.044085-28.622979V83.325277z"
        fill="#848BA6"
      ></path>
      <path
        d="M672.964711 137.629957c0-11.035234-6.743149-16.128-15.044085-11.362042L387.154329 281.58366c-8.306383 4.765957-15.044085 17.587745-15.044086 28.622978v596.093277c0 11.035234 6.737702 16.128 15.044086 11.367489l270.766297-155.321191c8.300936-4.760511 15.044085-17.587745 15.044085-28.622979V137.629957z"
        fill="#C4CADD"
      ></path>
      <path
        d="M666.956882 125.080511l14.542978 8.284595-15.937361 7.467575-6.950128-7.974128 8.344511-7.778042z"
        fill="#C4CADD"
      ></path>
      <path
        d="M686.799605 145.565957c0-11.029787-6.737702-16.122553-15.038638-11.362042L400.989222 289.525106c-8.300936 4.760511-15.038638 17.587745-15.038638 28.617532v596.098724c0 11.035234 6.737702 16.122553 15.038638 11.362042l270.771745-155.321191c8.300936-4.760511 15.038638-17.582298 15.038638-28.617532V145.565957z"
        fill="#848BA6"
      ></path>
      <path
        d="M743.081477 199.870638c0-11.035234-6.737702-16.128-15.038638-11.367489L457.271094 343.82434c-8.300936 4.760511-15.038638 17.587745-15.038638 28.622979v596.093277c0 11.035234 6.737702 16.128 15.038638 11.362042l270.771745-155.315744c8.300936-4.765957 15.038638-17.587745 15.038638-28.622979V199.870638z"
        fill="#C4CADD"
      ></path>
      <path
        d="M737.079094 187.321191l14.542979 8.284596-15.937362 7.467575-6.955574-7.974128 8.349957-7.778043z"
        fill="#C4CADD"
      ></path>
      <path
        d="M756.921818 207.806638c0-11.035234-6.737702-16.122553-15.044085-11.362042L471.111435 351.765787c-8.300936 4.760511-15.044085 17.582298-15.044085 28.617532v596.098724c0 11.029787 6.743149 16.122553 15.044085 11.362042l270.766298-155.321191c8.306383-4.760511 15.044085-17.587745 15.044085-28.617532V207.806638z"
        fill="#848BA6"
      ></path>
      <path
        d="M496.215775 642.592681l327.511149-178.088851 0.566468 379.40834-328.077617-69.011064v-132.308425z"
        fill="#4762AF"
      ></path>
      <path
        d="M465.670073 636.43234l69.893447-2.897702v376.412596s-8.900085 7.690894-22.168511 11.645277c-4.33566 1.290894-9.150638 1.824681-14.254298 2.037106-9.984 0.408511-20.790468-4.057872-32.223319-10.092936l-1.247319-377.104341z"
        fill="#6982C8"
      ></path>
      <path
        d="M466.732201 641.056681L225.286073 501.656511v80.498383l241.446128 139.40017v-80.498383zM466.732201 926.093617L225.286073 786.693447v83.412425l241.446128 139.400171v-83.412426z"
        fill="#4762AF"
      ></path>
      <path
        d="M466.732201 705.16017l-19.477787-11.247659v269.361021l19.477787 11.247659v-269.361021z"
        fill="#4762AF"
      ></path>
      <path
        d="M231.865818 422.432681v24.989957l-6.634213 3.829107c-9.733447 5.621106-8.513362 15.458043 2.728851 21.945191l250.264511 144.48749c11.242213 6.492596 28.274383 7.200681 38.00783 1.579574l255.722212-147.641191c9.733447-5.621106 8.513362-15.452596-2.728851-21.945192l-12.30434-7.102638v-26.662128l56.59234 34.347575c13.083234 7.554723 14.510298 18.998468 3.17549 25.540085l-297.570043 171.797787c-11.329362 6.541617-31.144851 5.719149-44.228085-1.830128L183.672456 477.630638c-13.083234-7.554723-14.504851-18.993021-3.175489-25.534638l51.368851-29.663319z"
        fill="#6982C8"
      ></path>
      <path
        d="M535.361988 775.701787v182.805787l250.694809-127.656851-250.694809-55.148936zM302.124201 827.833191v-24.227404l-20.708766 12.27166 20.708766 11.955744z"
        fill="#4762AF"
      ></path>
      <path
        d="M316.503775 795.620766l123.332085-73.166979 0.838809 56.16749-111.812085 64.561021-12.358809-7.04817v-40.513362zM387.007265 828.328851l53.623829-30.95966v110.902469l-53.623829-30.954213v-48.988596z"
        fill="#848BA6"
      ></path>
      <path
        d="M245.967605 597.416851l-14.101787-7.03183v199.293277l13.84034 11.590808 0.261447-203.852255z"
        fill="#C4CADD"
      ></path>
      <path
        d="M245.967605 597.416851l76.625702 43.835915-76.625702 44.244425v-88.08034zM245.967605 705.525106v29.254809l118.544341-69.021958-24.832-14.336-93.712341 54.103149z"
        fill="#848BA6"
      ></path>
      <path
        d="M302.086073 629.716426v197.817191l14.417702 8.32817v-197.926127l-14.417702-8.219234zM372.23552 670.218894v198.17668l14.771745 8.529703v-198.443575l-14.771745-8.262808z"
        fill="#C4CBDC"
      ></path>
      <path
        d="M220.471094 697.943149l12.233532 7.059064v62.551149c0 10.937191 6.612426 23.628255 14.760851 28.334298l178.453788 103.031829c8.142979 4.700596 14.755404-0.359489 14.755404-11.291234v-62.551149l12.233532 7.059064v71.876085c0 12.217191-7.391319 17.870979-16.492936 12.614809l-199.451234-115.150979c-9.101617-5.25617-16.492936-19.43966-16.492937-31.662298v-71.870638z"
        fill="#6982C8"
      ></path>
      <path
        d="M452.908201 832.63183l-12.233532-7.064511v-95.923745c0-10.931745-6.612426-23.628255-14.755404-28.328851l-178.453788-103.031829c-8.148426-4.706043-14.760851 0.354043-14.760851 11.285787v95.929191l-12.233532-7.06451v-105.243234c0-12.222638 7.391319-17.876426 16.492937-12.620256l199.451234 115.150979c9.101617 5.25617 16.492936 19.445106 16.492936 31.662298v105.248681z"
        fill="#6982C8"
      ></path>
      <path
        d="M549.360286 891.827745v-141.932936s-6.601532-30.08817 43.699745-50.301277l196.433702-113.408s10.446979-9.624511 23.306893-0.849702c12.859915 8.769362 28.601191 16.514723 28.601192 16.514723s-227.878128 133.61566-229.653787 132.59166c-1.781106-1.029447-19.684766 5.670128-19.684766 34.124255v166.067745l-32.250554-18.617192s-10.452426-9.23234-10.452425-24.189276z"
        fill="#273F73"
      ></path>
      <path
        d="M847.671094 614.89566c0-10.893617-6.634213-15.904681-14.798978-11.193192l-229.681022 132.608c-8.170213 4.716936-14.798979 17.386213-14.798978 28.27983v157.864851c0 10.88817 6.628766 15.904681 14.798978 11.187745l229.681022-132.608c8.164766-4.711489 14.798979-17.386213 14.798978-28.274383v-157.864851z"
        fill="#677FC4"
      ></path>
      <path
        d="M806.711094 667.609872c0-7.424-4.520851-10.839149-10.087489-7.625532l-146.965787 84.850383c-5.566638 3.213617-10.087489 11.846809-10.087489 19.270809s4.520851 10.844596 10.087489 7.625532l146.965787-84.850383c5.566638-3.213617 10.087489-11.846809 10.087489-19.270809zM806.711094 719.659574c0-7.424-4.520851-10.839149-10.087489-7.625531l-146.965787 84.850383c-5.566638 3.213617-10.087489 11.846809-10.087489 19.270808s4.520851 10.844596 10.087489 7.625532l146.965787-84.850383c5.566638-3.213617 10.087489-11.846809 10.087489-19.270809zM806.711094 769.955404c0-7.424-4.520851-10.839149-10.087489-7.625532l-146.965787 84.850383c-5.566638 3.213617-10.087489 11.852255-10.087489 19.276256s4.520851 10.839149 10.087489 7.625532l146.965787-84.850383c5.566638-3.213617 10.087489-11.852255 10.087489-19.276256z"
        fill="#71E0FF"
      ></path>
    </g>
  </svg>,
];

const dataBase = [
  <svg
    id="a96792b7-ce28-4ca3-9767-4e065ef4820f"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <defs>
      <linearGradient
        id="ef16bf9d-a8b6-4181-b6cd-66fc5203f956"
        x1="2.59"
        y1="10.16"
        x2="15.41"
        y2="10.16"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#005ba1" />
        <stop offset="0.07" stop-color="#0060a9" />
        <stop offset="0.36" stop-color="#0071c8" />
        <stop offset="0.52" stop-color="#0078d4" />
        <stop offset="0.64" stop-color="#0074cd" />
        <stop offset="0.82" stop-color="#006abb" />
        <stop offset="1" stop-color="#005ba1" />
      </linearGradient>
      <radialGradient
        id="bf3846c3-4d74-4743-ab9a-f334c248bd92"
        cx="9.36"
        cy="10.57"
        r="7.07"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#f2f2f2" />
        <stop offset="0.58" stop-color="#eee" />
        <stop offset="1" stop-color="#e6e6e6" />
      </radialGradient>
    </defs>
    <title>Icon-databases-130</title>
    <path
      d="M9,5.14c-3.54,0-6.41-1-6.41-2.32V15.18c0,1.27,2.82,2.3,6.32,2.32H9c3.54,0,6.41-1,6.41-2.32V2.82C15.41,4.11,12.54,5.14,9,5.14Z"
      fill="url(#ef16bf9d-a8b6-4181-b6cd-66fc5203f956)"
    />
    <path
      d="M15.41,2.82c0,1.29-2.87,2.32-6.41,2.32s-6.41-1-6.41-2.32S5.46.5,9,.5s6.41,1,6.41,2.32"
      fill="#e8e8e8"
    />
    <path
      d="M13.92,2.63c0,.82-2.21,1.48-4.92,1.48S4.08,3.45,4.08,2.63,6.29,1.16,9,1.16s4.92.66,4.92,1.47"
      fill="#50e6ff"
    />
    <path
      d="M9,3a11.55,11.55,0,0,0-3.89.57A11.42,11.42,0,0,0,9,4.11a11.15,11.15,0,0,0,3.89-.58A11.84,11.84,0,0,0,9,3Z"
      fill="#198ab3"
    />
    <path
      d="M12.9,11.4V8H12v4.13h2.46V11.4ZM5.76,9.73a1.83,1.83,0,0,1-.51-.31.44.44,0,0,1-.12-.32.34.34,0,0,1,.15-.3.68.68,0,0,1,.42-.12,1.62,1.62,0,0,1,1,.29V8.11a2.58,2.58,0,0,0-1-.16,1.64,1.64,0,0,0-1.09.34,1.08,1.08,0,0,0-.42.89c0,.51.32.91,1,1.21a2.88,2.88,0,0,1,.62.36.42.42,0,0,1,.15.32.38.38,0,0,1-.16.31.81.81,0,0,1-.45.11,1.66,1.66,0,0,1-1.09-.42V12a2.17,2.17,0,0,0,1.07.24,1.88,1.88,0,0,0,1.18-.33A1.08,1.08,0,0,0,6.84,11a1.05,1.05,0,0,0-.25-.7A2.42,2.42,0,0,0,5.76,9.73ZM11,11.32a2.34,2.34,0,0,0,.33-1.26A2.32,2.32,0,0,0,11,9a1.81,1.81,0,0,0-.7-.75,2,2,0,0,0-1-.26,2.11,2.11,0,0,0-1.08.27A1.86,1.86,0,0,0,7.49,9a2.46,2.46,0,0,0-.26,1.14,2.26,2.26,0,0,0,.24,1,1.76,1.76,0,0,0,.69.74,2.06,2.06,0,0,0,1,.3l.86,1h1.21L10,12.08A1.79,1.79,0,0,0,11,11.32ZM10,11.07a.94.94,0,0,1-.76.35.92.92,0,0,1-.76-.36,1.52,1.52,0,0,1-.29-1,1.53,1.53,0,0,1,.29-1,1,1,0,0,1,.78-.37.87.87,0,0,1,.75.37,1.62,1.62,0,0,1,.27,1A1.46,1.46,0,0,1,10,11.07Z"
      fill="url(#bf3846c3-4d74-4743-ab9a-f334c248bd92)"
    />
  </svg>,
];

export const systemIcons = {
  cloudServer,
  dataBase,
  dataStorage,
};
