import {
  ADD_CURRENT_FLOW,
  RESET_CURRENT_FLOW,
  RESET_GRAPH,
  CREATE_GRAPH_FAILURE,
  CREATE_GRAPH_FETCH,
  CREATE_GRAPH_SUCCESS,
  UPDATE_GRAPH,
} from "../actionTypes";

const initialState = {
  currentFlow: {},
};

const initialSystemGraphState = {
  graphData: [],
  loading: false,
  error: null,
};

export const currentFlowReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CURRENT_FLOW:
      return {
        ...state,
        currentFlow: action.payload,
      };

    case RESET_CURRENT_FLOW:
      return {
        ...state,
        currentFlow: [],
      };
    default:
      return state;
  }
};

export const systemGraphReducer = (state = initialSystemGraphState, action) => {
  switch (action.type) {
    case CREATE_GRAPH_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_GRAPH_SUCCESS:
        const updatedGraph = { Id: action.payload.id, body: action.payload.body, graphDetails: action.payload.graphDetails };
        const updatedGraphData = state?.graphData?.map(item =>
          item.Id === action.payload.id ? updatedGraph : item
        );
        return {
          ...state,
          loading: false,
          graphData: updatedGraphData.some(item => item.Id === action.payload.id)
            ? updatedGraphData // Graph exists, update it
            : [...updatedGraphData, updatedGraph], // Graph doesn't exist, add it
        };

    case CREATE_GRAPH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_GRAPH:
      return {
        ...state,
        loading: false,
        graphData: action.payload,
      };
    case RESET_GRAPH:
      return {
        ...state,
        graphData: [],
      };

    default:
      return state;
  }
};
