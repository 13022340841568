// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-flow__edge.selected .react-flow__edge-path {
  stroke: #2186EB !important;
}
.react-flow__node {
  max-width: 350px;
}
.react-flow__attribution a {
  background: none;
}
.react-flow__handle-left {
  border: 0 !important;
  background: transparent !important;
}
.react-flow__handle-right {
  border: 0 !important;
  background: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/react-flow.scss"],"names":[],"mappings":"AAQI;EACE,0BAAA;AAPN;AAUI;EACE,gBAAA;AARN;AAYI;EACE,gBAAA;AAVN;AAaI;EACE,oBAAA;EAEA,kCAAA;AAZN;AAeI;EACE,oBAAA;EAEA,kCAAA;AAdN","sourcesContent":[".react-flow {\n    // &__node-custom {\n    //   border: 1px solid #555;\n    //   padding: 10px;\n    //   width: 300px;\n    //   border-radius: 5px;\n    // }\n  \n    &__edge.selected &__edge-path {\n      stroke: #2186EB !important;\n    }\n  \n    &__node {\n      max-width: 350px;\n  \n    }\n  \n    &__attribution a {\n      background: none;\n    }\n  \n    &__handle-left {\n      border: 0 !important;\n  \n      background: transparent !important;\n    }\n  \n    &__handle-right {\n      border: 0 !important;\n  \n      background: transparent !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
