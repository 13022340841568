import React, { useCallback } from "react";
import { useReactFlow } from "reactflow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import {
  faLayerGroup,
  faTrash,
  faMagnifyingGlass,
  faDiagramProject,
  faCircleInfo
} from "@fortawesome/free-solid-svg-icons";

export default function ContextMenus({
  id,
  top,
  left,
  right,
  bottom,
  handleContextMenu,
  options,
  nodeDetails,
  ...props
}) {
  const { getNode, setNodes, setEdges, addNodes } = useReactFlow();

  const duplicateNode = useCallback(() => {
    const node = getNode(id);
    const position = {
      x: node.position.x + 50,
      y: node.position.y + 50,
    };

    addNodes({ ...node, id: `${node.id}-copy`, position });
  }, [id, getNode, addNodes]);

  const deleteNode = useCallback(() => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) => edges.filter((edge) => edge.source !== id));
  }, [id, setNodes, setEdges]);

  return (
  //   <div
  //   style={{ top, left, right, bottom }}
  //   className="context-menu bg-slate-200 font-newOne flex flex-col px-4 py-4 shadow-md rounded-lg"
  //   {...props}
  // >
  //   {options.map((option, index) => (
  //     <div key={index} className="flex items-center">
  //       <Tooltip title={option.name}>
  //         <div
  //           className="flex items-center cursor-pointer hover:text-blue-700"
  //           onClick={() => handleContextMenu(option.key, nodeDetails)}
  //         >
  //           <FontAwesomeIcon icon={getIcon(option.key)} className="mr-2" />
  //           <span>{option.label}</span>
  //         </div>
  //       </Tooltip>
  //       {index < options.length - 1 && <div className="divider" />}
  //     </div>
  //   ))}
  //   <div className="divider" />
  //   <Tooltip title="Delete">
  //     <FontAwesomeIcon
  //       icon={faTrash}
  //       className="text-blue-500 hover:text-blue-700 cursor-pointer"
  //       onClick={deleteNode}
  //     />
  //   </Tooltip>
  // </div>

  <div
  style={{ top, left, right, bottom }}
  className="context-menu bg-slate-200 font-newOne flex flex-col px-4 py-4 shadow-md rounded-lg"
  {...props}
>
  {options.map((option, index) => (
    <React.Fragment key={index}>
      <div className="flex items-center">
        <Tooltip title={option.name}>
          <div
            className="flex items-center cursor-pointer hover:text-blue-700"
            
            onClick={() => handleContextMenu(option.key, nodeDetails)}
          >
            <FontAwesomeIcon icon={getIcon(option.key)} className="mr-2" />
            <span>{option.name}</span>
          </div>
        </Tooltip>
      </div>
      {index < options.length - 1 && <hr className=" my-2" />} {/* Use <hr> for horizontal divider */}
    </React.Fragment>
  ))}
</div>

  
  );
}

function getIcon(key) {
  switch (key) {
    case "lineage":
      return faDiagramProject;
    case "delete":
      return faTrash;
    case "nodeDetails":
      return faCircleInfo;
    case "levels":
      return faLayerGroup;
    default:
      return faDiagramProject;
  }
}
