// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.has-one-edge {
  /* https://reactflow.dev/docs/guides/theming/#react-flow-class-names */
}
.has-one-edge.selected path.react-flow__edge-path {
  marker-end: url(#hasOneSelected);
  stroke-width: 1.5px;
}
.has-one-edge--highlighted path.react-flow__edge-path,
.has-one-edge--highlighted path.react-flow__edge-interaction,
.has-one-edge--highlighted path.react-flow__connection-path {
  stroke: #00A300 !important;
  stroke-width: 1.5px;
}

.has-one-edge-reversed.selected path.react-flow__edge-path {
  marker-end: url(#hasOneReversedSelected);
  stroke-width: 1.5px;
}
.has-one-edge-reversed--highlighted path.react-flow__edge-path,
.has-one-edge-reversed--highlighted path.react-flow__edge-interaction,
.has-one-edge-reversed--highlighted path.react-flow__connection-path {
  stroke: #00A300 !important;
  stroke-width: 1.5px;
}

.system-edge.selected path.react-flow__edge-path {
  marker-end: url(#hasOneReversedSelected);
  stroke-width: 1.5px;
}
.system-edge--highlighted path.react-flow__edge-path,
.system-edge--highlighted path.react-flow__edge-interaction,
.system-edge--highlighted path.react-flow__connection-path {
  stroke: #00A300 !important;
  stroke-width: 1.5px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/has-one-edge.scss"],"names":[],"mappings":"AAAA;EACI,sEAAA;AACJ;AAAI;EACE,gCAAA;EACA,mBAAA;AAEN;AAEM;;;EAGE,0BAAA;EACA,mBAAA;AAAR;;AAMI;EACE,wCAAA;EACA,mBAAA;AAHN;AAOM;;;EAGE,0BAAA;EACA,mBAAA;AALR;;AAWI;EACE,wCAAA;EACA,mBAAA;AARN;AAYM;;;EAGE,0BAAA;EACA,mBAAA;AAVR","sourcesContent":[".has-one-edge {\n    /* https://reactflow.dev/docs/guides/theming/#react-flow-class-names */\n    &.selected path.react-flow__edge-path {\n      marker-end: url(#hasOneSelected);\n      stroke-width: 1.5px;\n    }\n  \n    &--highlighted {\n      path.react-flow__edge-path,\n      path.react-flow__edge-interaction,\n      path.react-flow__connection-path {\n        stroke: #00A300 !important;\n        stroke-width: 1.5px;\n      }\n    }\n  }\n  \n  .has-one-edge-reversed {\n    &.selected path.react-flow__edge-path {\n      marker-end: url(#hasOneReversedSelected);\n      stroke-width: 1.5px;\n    }\n  \n    &--highlighted {\n      path.react-flow__edge-path,\n      path.react-flow__edge-interaction,\n      path.react-flow__connection-path {\n        stroke: #00A300 !important;\n        stroke-width: 1.5px;\n      }\n    }\n  }\n\n  .system-edge {\n    &.selected path.react-flow__edge-path {\n      marker-end: url(#hasOneReversedSelected);\n      stroke-width: 1.5px;\n    }\n  \n    &--highlighted {\n      path.react-flow__edge-path,\n      path.react-flow__edge-interaction,\n      path.react-flow__connection-path {\n        stroke: #00A300 !important;\n        stroke-width: 1.5px;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
