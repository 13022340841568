import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faEdit } from "@fortawesome/free-solid-svg-icons";
import { svgIcons } from "../../utils/svgIcons";
import "../../../src/assets/styles/main.css";

const getFields = (type, fieldName, handleChange, formData) => {
  const fieldType = {
    Integer: (
      <input
        type="number"
        id={fieldName}
        required="required"
        onChange={(event) => handleChange(event, fieldName)}
        value={formData[fieldName]}
        className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
      />
    ),
    String: (
      <input
        type="text"
        required="required"
        id={fieldName}
        onChange={(event) => handleChange(event, fieldName)}
        value={formData[fieldName]}
        className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
      />
    ),
    Enum: (
      <input
        type="text"
        required="required"
        id={fieldName}
        onChange={(event) => handleChange(event, fieldName)}
        value={formData[fieldName]}
        className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
      />
    ),
    LocalDateTime: (
      <div>
        <div className="relative mb-2 mt-2">
          <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-calendar-event"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <rect x="4" y="5" width="16" height="16" rx="2" />
              <line x1="16" y1="3" x2="16" y2="7" />
              <line x1="8" y1="3" x2="8" y2="7" />
              <line x1="4" y1="11" x2="20" y2="11" />
              <rect x="8" y="15" width="2" height="2" />
            </svg>
          </div>
          <input
            id={fieldName}
            readOnly
            className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-5 text-sm border-gray-300 rounded border"
            placeholder="MM/YY"
            value={formData[fieldName]}
          />
        </div>
      </div>
    ),
  };
  return fieldType[type];
};

function CommonModal({
  data,
  setIsEditModalOpen,
  handleInfoSubmit,
  nodeType,
  isEditModalOpen,
}) {
  const cataLogsHeaderState = useSelector(
    (catalogState) => catalogState.cataLogHeaderReducer
  );
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const requiredFields = cataLogsHeaderState[nodeType]?.data?.filter(
      (element) =>
        element["Property Type"] === "IS_OPTIONAL" ||
        element["Property Type"] === "IS_REQUIRED"
    );

    setFields(requiredFields);
  }, []);

  const [formData, setFormData] = useState(data);

  const handleChange = (event, fieldName) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleInfoSubmit(formData);
  };

  return (
    <Modal title={null} open={isEditModalOpen} footer={null} width={500}>
      <div className="relative py-2 px-2 md:px-10 bg-white shadow-md rounded">
        <div className="flex items-center">
          {svgIcons.editInfo}
          <h1 class="font-newOne text-gray-800 ml-2 mb-2 text-xl font-bold tracking-normal leading-tight">
            Edit Information
          </h1>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="md:flex flex-wrap -mx-3">
            {fields?.map((field, index) => (
              <div
                key={field["Display Name"]}
                className="md:w-1/2 px-3 mb-6 md:mb-0"
              >
                <label
                  htmlFor={field["Display Name"]}
                  className="font-newOne text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  {field["Display Name"]}{" "}
                  {/* {field["Property Type"] === "IS_REQUIRED" ? (
                    <abbr title="required" className="text-red-600">
                      *
                    </abbr>
                  ) : null} */}
                </label>
                {getFields(
                  field["Data Type"],
                  field["Display Name"],
                  handleChange,
                  formData
                )}
              </div>
            ))}
          </div>
        </form>
        <p class="font-newOne text-xs text-red-500 text-right my-3">
          Required fields are marked with an asterisk{" "}
          <abbr title="Required field">*</abbr>
        </p>
        <div className="flex items-center justify-center w-full">
          <button
            className="font-newOne focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="font-newOne focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
            onClick={() => setIsEditModalOpen(false)}
          >
            Cancel
          </button>
        </div>
        <div className="flex absolute top-[-15px] right-[-15px]  space-x-3">
          <FontAwesomeIcon
            icon={faCircleXmark}
            size="2x"
            color="red"
            onClick={() => setIsEditModalOpen(false)}
          />{" "}
        </div>
      </div>
    </Modal>
  );
}

export default CommonModal;
