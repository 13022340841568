
export const getEndpoints=() =>{
  let API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let API_ENDPOINT_GRAPH = process.env.REACT_APP_API_ENDPOINT_GRAPH;
  let BASE_ENDPOINT = process.env.REACT_APP_BASE_ENDPOINT;
  let DATABASE_ENDPOINT = process.env.REACT_APP_DATABASE_ENDPOINT;
  const AZURE_RESOURCE_ENDPOINT = process.env.REACT_APP_AZURE_ENDPOINT
  let REACT_APP_AZURE_DATABASE_ENDPOINT = process.env.REACT_APP_AZURE_DATABASE_ENDPOINT;
  
  return {
    API_ENDPOINT,
    API_ENDPOINT_GRAPH,
    BASE_ENDPOINT,
    DATABASE_ENDPOINT,
    AZURE_RESOURCE_ENDPOINT,
    REACT_APP_AZURE_DATABASE_ENDPOINT
  };
}
