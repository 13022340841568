import Handsontable from "handsontable";
import { SELECTED_CLASS, ODD_ROW_CLASS } from "../data/constants";

export const addClassWhenNeeded = (props) => {
  const className = props.cellProperties.className;

  if (className !== void 0) {
    Handsontable.dom.addClass(props.TD, className);
  }
};

const headerAlignments = new Map([
  ["9", "htCenter"],
  ["10", "htRight"],
  ["12", "htCenter"],
]);

export const addClassesToRows = (
  TD,
  row,
  column,
  prop,
  value,
  cellProperties
) => {
  // Adding classes to `TR` just while rendering first visible `TD` element
  if (column !== 0) {
    return;
  }

  const parentElement = TD.parentElement;

  if (parentElement === null) {
    return;
  }

  // Add class to selected rows
  if (cellProperties.instance.getDataAtRowProp(row, "0")) {
    Handsontable.dom.addClass(parentElement, SELECTED_CLASS);
  } else {
    Handsontable.dom.removeClass(parentElement, SELECTED_CLASS);
  }

  // Add class to odd TRs
  if (row % 2 === 0) {
    Handsontable.dom.addClass(parentElement, ODD_ROW_CLASS);
  } else {
    Handsontable.dom.removeClass(parentElement, ODD_ROW_CLASS);
  }

  if (cellProperties?.columnConfig?.data === "Data Type") {
    const dropdown = document.createElement("select");
    const options = ["Boolean", "String", "LocalDateTime"];

    options.forEach((option) => {
      const optionElement = document.createElement("option");
      optionElement.value = option;
      optionElement.text = option;

      if (option === value) {
        optionElement.selected = true;
      }

      dropdown.appendChild(optionElement);
    });
    console.log("dropdowni", dropdown);
    return dropdown;
  }
};

export const drawCheckboxInRowHeaders = function (
  row,
  TH,
  handleRowSelection,
  selectedRows
) {
  const input = document.createElement("input");
  input.type = "checkbox";

  // Set the checked state based on the selectedRows array
  input.checked = selectedRows.includes(row);

  // Add event listener for checkbox click event
  input.addEventListener("click", (event) => {
    const isChecked = event.target.checked;
    handleRowSelection(row, isChecked);
  });

  Handsontable.dom.empty(TH);
  TH.appendChild(input);
};

export const addClassesAndEllipsis = (
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) => {
  if (!cellProperties) {
    // Handle the case when cellProperties is undefined
    return;
  }

  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);

  // Add ellipsis style
  td.style.whiteSpace = "nowrap";
  td.style.textOverflow = "ellipsis";
  td.style.overflow = "hidden";
};

export function alignHeaders(table, column, TH) {
  if (column < 0) {
    return;
  }
  if (TH && TH && TH.firstChild) {
    const alignmentClass = table.isRtl() ? "htRight" : "htLeft";

    if (headerAlignments.has(column.toString())) {
      Handsontable.dom.removeClass(TH.firstChild, alignmentClass);
      Handsontable.dom.addClass(
        TH.firstChild,
        headerAlignments.get(column.toString())
      );
    } else {
      Handsontable.dom.addClass(TH.firstChild, alignmentClass);
    }
  }
}

export const changeCheckboxCell = function changeCheckboxCell(event, coords) {
  if (coords.col === -1 && event.target && event.target.nodeName === "INPUT") {
    event.preventDefault(); // Handsontable will render checked/unchecked checkbox by it own.

    this.setDataAtRowProp(coords.row, "0", !event.target.checked);
  }
};

export const firstRowRenderer = function firstRowRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.TextRenderer.apply(this, arguments);
  td.style.fontWeight = "bold";
  td.style.color = "green";
  td.style.background = "#CEC";
};

export const convertToDateTime = (obj) => {
  return new Date(
    Date.UTC(
      obj.Year,
      obj.Month - 1, // Months in JavaScript are zero-based (0-11)
      obj.Day,
      obj.Hour,
      obj.Minute,
      obj.Second,
      obj.Nanosecond / 1000000 // Convert nanoseconds to milliseconds
    )
  );
};

export const convertColumnsToDateTimeWithFirstColumn = (array) => {
  for (let i = 0; i < array.length; i++) {
    const subArray = array[i];

    // Append "true" to the first place
    subArray.unshift(true);

    // Convert columns which are objects to DateTime
    for (let j = 0; j < subArray.length; j++) {
      const columnValue = subArray[j];
      if (typeof columnValue === "object") {
        const dateTime = convertToDateTime(columnValue);
        subArray[j] = dateTime;
      }
    }
  }

  return array;
};

export const addDropDownvalues = (row, col, prop, value, cellProperties) => {
  if (cellProperties.columnConfig.data === "Data Type") {
    const dropdown = document.createElement("select");
    const options = ["Boolean", "String", "LocalDateTime"];

    options.forEach((option) => {
      const optionElement = document.createElement("option");
      optionElement.value = option;
      optionElement.text = option;

      if (option === value) {
        optionElement.selected = true;
      }

      dropdown.appendChild(optionElement);
    });
    console.log("dropdowni", dropdown);
    return dropdown;
  }
  return value;
};
