import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { date: '2023-01-01', nodes: 10 },
  { date: '2023-02-01', nodes: 15 },
  { date: '2023-03-01', nodes: 22 },
  { date: '2023-04-01', nodes: 30 },
  { date: '2023-05-01', nodes: 42 },
  { date: '2023-06-01', nodes: 50 },
  // Add more data points as needed
];

const NodeGrowthChart = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="nodes" stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default NodeGrowthChart;
