import React, { useState, useEffect } from "react";
import { Modal } from "antd";

function ModalForm({
  nodeName,
  columns,
  setIsModalVisible,
  handleNewRowSubmit,
  isModalVisible,
}) {
  const [formData, setFormData] = useState({});
  useEffect(() => {
    const initialFormData = {};
    columns?.forEach((column) => {
      if (column.columnConfig.type === "checkbox") {
        initialFormData[column.columnConfig.data] = false;
      }
    });
    setFormData(initialFormData);
  }, [columns]);

  const handleChange = (event, fieldName) => {
    let value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    // If it's a checkbox, explicitly set to false if not checked
    if (event.target.type === "checkbox" && !event.target.checked) {
      value = false;
    }

    setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleNewRowSubmit(formData);
  };

  return (
    <Modal title={null} open={isModalVisible} footer={null} width={800}>
      <div className="relative py-2 px-2 md:px-10 bg-white shadow-md rounded">
        <div className="flex items-center">
          <svg
            width="64px"
            height="64px"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_iconCarrier">
              <defs>
                <style>
                  {`
              .cls-1 { fill: #f76c5e; }
              .cls-2 { fill: #e7ecef; }
              .cls-3 { fill: #8b8c89; }
              .cls-4 { fill: #bc6c25; }
              .cls-5 { fill: #a3cef1; }
              .cls-6 { fill: #dda15e; }
              .cls-7 { fill: #6096ba; }
              .cls-8 { fill: #274c77; }
            `}
                </style>
              </defs>

              {/* Apply inline styles directly on SVG elements */}
              <polygon
                className="cls-1"
                points="46 10 46 26 50 26 50 10 52 10 48 4 44 10 46 10"
                style={{ fill: "#f76c5e" }}
              ></polygon>
              <rect
                className="cls-4"
                height="18"
                width="24"
                x="36"
                y="26"
                style={{ fill: "#bc6c25" }}
              ></rect>
              <polyline
                className="cls-8"
                points="52 26 52 34 48 30 44 34 44 26"
                style={{ fill: "#274c77" }}
              ></polyline>
              <path
                className="cls-6"
                d="M26.58,39.04l-3,1.29c-1.01,.43-2.15,.43-3.15,0l-3-1.29c-1.47-.63-2.42-2.08-2.42-3.68v-5.36c0-3.31,2.69-6,6-6h2c3.31,0,6,2.69,6,6v5.36c0,1.6-.95,3.05-2.42,3.68Z"
                style={{ fill: "#dda15e" }}
              ></path>
              <path
                className="cls-8"
                d="M35.14,45c-1.82-1.85-4.35-3-7.14-3h-3c0,1.66-1.34,3-3,3s-3-1.34-3-3h-3c-5.52,0-10,4.48-10,10v7H28l4-14h3.14Z"
                style={{ fill: "#274c77" }}
              ></path>
              <path
                className="cls-6"
                d="M17,55h2c1.1,0,2,.9,2,2v2h-4v-4h0"
                style={{ fill: "#dda15e" }}
              ></path>
              <path
                className="cls-8"
                d="M12,31.73c-.29,.17-.64,.27-1,.27-1.1,0-2-.9-2-2s.9-2,2-2c.42,0,.81,.13,1.14,.36"
                style={{ fill: "#274c77" }}
              ></path>
              <path
                className="cls-8"
                d="M32,31.73c.29,.17,.64,.27,1,.27,1.1,0,2-.9,2-2s-.9-2-2-2c-.42,0-.81,.13-1.14,.36"
                style={{ fill: "#274c77" }}
              ></path>
              <path
                className="cls-4"
                d="M19,39.71l1.42,.61c1.01,.44,2.15,.44,3.16,0l1.42-.61v2.29c0,1.66-1.34,3-3,3s-3-1.34-3-3v-2.29Z"
                style={{ fill: "#bc6c25" }}
              ></path>
              <polyline
                className="cls-3"
                points="28 59 32 45 54 45 50 59"
                style={{ fill: "#8b8c89" }}
              ></polyline>
              <path
                className="cls-8"
                d="M28.4,27.38h-.01c-.57-.23-1.23-.38-2.06-.38-4.33,0-4.33,4-8.67,4-1.13,0-1.97-.27-2.66-.67v-.33c0-3.31,2.69-6,6-6h2c2.37,0,4.42,1.38,5.39,3.38h.01"
                style={{ fill: "#274c77" }}
              ></path>
              <path
                className="cls-2"
                d="M29,34.6v-4.6c0-3.31-2.69-6-6-6h-2c-3.31,0-6,2.69-6,6v4h-2c-.55,0-1-.45-1-1v-3c0-5.52,4.48-10,10-10,2.76,0,5.26,1.12,7.07,2.93s2.93,4.31,2.93,7.07v3.18c0,.48-.34,.89-.80,.98l-2.2,.44"
                style={{ fill: "#e7ecef" }}
              ></path>
              <path
                className="cls-5"
                d="M41,51c.55,0,1,.45,1,1s-.45,1-1,1v-2"
                style={{ fill: "#a3cef1" }}
              ></path>
              <path
                className="cls-7"
                d="M22,36h0c-.11-.54,.24-1.07,.78-1.18l8.22-1.64v-2.86c0-4.79-3.61-8.98-8.38-9.3-5.24-.35-9.62,3.81-9.62,8.98v3h2v2h-2c-1.10,0-2-.90-2-2v-2.68c0-5.72,4.24-10.74,9.94-11.27,6.54-.62,12.06,4.53,12.06,10.95v3.18c0,.95-.67,1.77-1.61,1.96l-8.22,1.64c-.54,.11-1.07-.24-1.18-.78"
                style={{ fill: "#6096ba" }}
              ></path>
              <path
                className="cls-7"
                d="M22,59h-2v-2c0-.55-.45-1-1-1h-7c-.55,0-1-.45-1-1v-4c0-.27,.11-.52,.29-.71l1.29-1.29c.39-.39,1.02-.39,1.41,0h0c.39,.39,.39,1.02,0,1.41l-1,1v2.59h6c1.66,0,3,1.34,3,3v2"
                style={{ fill: "#6096ba" }}
              ></path>
              <rect
                className="cls-5"
                height="2"
                width="50"
                x="4"
                y="58"
                style={{ fill: "#a3cef1" }}
              ></rect>
              <rect
                className="cls-2"
                height="2"
                width="2"
                x="48"
                y="14"
                style={{ fill: "#e7ecef" }}
              ></rect>
              <rect
                className="cls-2"
                height="2"
                width="2"
                x="48"
                y="18"
                style={{ fill: "#e7ecef" }}
              ></rect>
              <rect
                className="cls-2"
                height="2"
                width="2"
                x="48"
                y="22"
                style={{ fill: "#e7ecef" }}
              ></rect>
            </g>
          </svg>

          <h1 className="ml-2 text-gray-800 mb-2 text-xl font-bold tracking-normal leading-tight font-newOne">
            Create New {nodeName}
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4 font-newOne">
            {columns
              ?.filter(
                (column) =>
                  ![
                    "ID",
                    "Last Updated By",
                    "Created By",
                    "Created DateTime",
                    "Last Updated DateTime",
                  ].includes(column.header)
              )
              ?.map((column, index) => (
                <div key={index}>
                  <label
                    htmlFor={column.columnConfig.data}
                    className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                  >
                    <span dangerouslySetInnerHTML={{ __html: column.header }} />
                    {/* {column.columnConfig.uniqueId ? (
                      <span title="required" className="text-red-600">
                        *
                      </span>
                    ) : null} */}
                  </label>
                  {column.columnConfig.type === "text" && (
                    <input
                      id={column.columnConfig.data}
                      onChange={(event) =>
                        handleChange(event, column.columnConfig.data)
                      }
                      value={formData[column.columnConfig.data] || ""}
                      className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      placeholder={column.header.replace(/<\/?[^>]+(>|$)/g, "").replace('*', '')}
                    />
                  )}
                  {column.columnConfig.type === "checkbox" && (
                    <div className="flex items-center  mb-2 mt-2">
                      <input
                        type="checkbox"
                        id={column.columnConfig.data}
                        onChange={(event) =>
                          handleChange(event, column.columnConfig.data)
                        }
                        checked={formData[column.columnConfig.data] || false}
                        className="text-indigo-600 focus:ring-indigo-500 h-4 w-4"
                      />
                      {/* <label
                        htmlFor={column.columnConfig.data}
                        className="ml-2 text-gray-800 text-sm font-normal"
                      >
                        {column.header.replace(/<\/?[^>]+(>|$)/g, "").replace('*', '')}
                      </label> */}
                    </div>
                  )}
                  {column.columnConfig.type === "numeric" && (
                    <div>
                      <input
                        id={column.columnConfig.data}
                        type="number"
                        className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                        placeholder="Enter a number"
                        onChange={(event) =>
                          handleChange(event, column.columnConfig.data)
                        }
                        value={formData[column.columnConfig.data] || ""}
                      />
                    </div>
                  )}
                  {column.columnConfig.type === "dropdown" && (
                    <select
                      id={column.columnConfig.data}
                      value={formData[column.columnConfig.data] || ""}
                      onChange={(event) =>
                        handleChange(event, column.columnConfig.data)
                      }
                      className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    >
                      <option value="">Select an option</option>
                      {column.columnConfig.source?.map(
                        (option, optionIndex) => (
                          <option key={optionIndex} value={option}>
                            {option}
                          </option>
                        )
                      )}
                    </select>
                  )}
                </div>
              ))}
          </div>
        </form>

        <div className="flex items-center justify-center w-full">
          <button
            className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
            onClick={() => setIsModalVisible(false)}
          >
            Cancel
          </button>
        </div>
        <button
          className="cursor-pointer absolute top-0 right-0 mt-1 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded"
          onClick={() => setIsModalVisible(false)}
          aria-label="close modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-x"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="red"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="8" fill="red" />
            <line x1="9" y1="9" x2="15" y2="15" stroke="white" />
            <line x1="15" y1="9" x2="9" y2="15" stroke="white" />
          </svg>
        </button>
      </div>
    </Modal>
  );
}

export default ModalForm;
