export const Container = [
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <defs>
        {" "}
        {" "}
      </defs>{" "}
      <title>Icon_24px_ContainerRegistry_Color</title>{" "}
      <g data-name="Product Icons">
        {" "}
        <g>
          {" "}
          <polygon
            id="Fill-1"
            fill="#aecbfa"
            points="6.67 3 2 5.76 2 18.24 6.67 21 6.67 18.6 4 17.02 4 6.98 6.67 5.4 6.67 3"
          />{" "}
          <polygon
            id="Fill-2"
            fill="#aecbfa"
            points="9.33 9.14 12 10.71 14.67 9.14 14.67 5.98 12 4.41 9.33 5.98 9.33 9.14"
          />{" "}
          <polygon
            id="Fill-3"
            fill="#669df6"
            points="9.33 5.98 9.33 9.14 12 10.71 14.67 9.14 14.67 5.98 12 7.56 9.33 5.98"
          />{" "}
          <polygon
            id="Fill-4"
            fill="#4285f4"
            points="12 10.71 14.67 9.14 14.67 5.98 12 7.56 12 10.71"
          />{" "}
          <polygon
            id="Fill-5"
            fill="#aecbfa"
            points="12.67 15.63 15.33 17.2 18 15.63 18 12.47 15.33 10.89 12.67 12.47 12.67 15.63"
          />{" "}
          <polygon
            id="Fill-6"
            fill="#669df6"
            points="12.67 12.47 12.67 15.63 15.33 17.2 18 15.63 18 12.47 15.33 14.05 12.67 12.47"
          />{" "}
          <polygon
            id="Fill-7"
            fill="#4285f4"
            points="15.33 17.2 18 15.63 18 12.47 15.33 14.05 15.33 17.2"
          />{" "}
          <polygon
            id="Fill-8"
            fill="#aecbfa"
            points="6 15.63 8.67 17.2 11.33 15.63 11.33 12.47 8.67 10.89 6 12.47 6 15.63"
          />{" "}
          <polygon
            id="Fill-9"
            fill="#669df6"
            points="6 12.47 6 15.63 8.67 17.2 11.33 15.63 11.33 12.47 8.67 14.05 6 12.47"
          />{" "}
          <polygon
            id="Fill-10"
            fill="#4285f4"
            points="8.67 17.2 11.33 15.63 11.33 12.47 8.67 14.05 8.67 17.2"
          />{" "}
          <polygon
            id="Fill-11"
            fill="#669df6"
            points="2 8.17 2 15.84 4 17.02 4 6.98 2 8.17"
          />{" "}
          <polygon
            id="Fill-12"
            fill="#4285f4"
            points="2 8.17 4 7.71 4 6.98 2 8.17"
          />{" "}
          <polygon
            id="Fill-13"
            fill="#4285f4"
            points="4 17.02 4 16.44 2 15.84 4 17.02"
          />{" "}
          <polygon
            id="Fill-14"
            fill="#aecbfa"
            points="17.33 3 17.33 5.4 20 6.98 20 17.02 17.33 18.6 17.33 21 22 18.24 22 5.76 17.33 3"
          />{" "}
          <polygon
            id="Fill-15"
            fill="#669df6"
            points="20 17.02 22 15.84 22 8.17 20 6.98 20 17.02"
          />{" "}
          <polygon
            id="Fill-16"
            fill="#4285f4"
            points="20 7.71 22 8.17 20 6.98 20 7.71"
          />{" "}
          <polygon
            id="Fill-17"
            fill="#4285f4"
            points="20 16.44 20 17.02 22 15.84 20 16.44"
          />{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>,
];
