import React from "react";
import { getBezierPath, EdgeLabelRenderer, BaseEdge } from "reactflow";

const BidirectionalEdge = ({
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerStart,
  markerEnd,
  hoveredEdge
}) => {
  // Get the edge paths for both directions
  let [forwardPath, forwardLabelX, forwardLabelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });
  let [backwardPath, backwardLabelX, backwardLabelY] = getBezierPath({
    sourceX: targetX,
    sourceY: targetY,
    targetX: sourceX,
    targetY: sourceY,
    sourcePosition: targetPosition,
    targetPosition: sourcePosition,
  });
  
  // Adjust label positions if they overlap
  let labelSpacing = 20; // Adjust this value to control spacing between labels
  if (forwardLabelY === backwardLabelY && Math.abs(forwardLabelX - backwardLabelX) < labelSpacing) {
    if (forwardLabelX < targetX) {
      forwardLabelX -= labelSpacing;
      backwardLabelX += labelSpacing;
    } else {
      forwardLabelX += labelSpacing;
      backwardLabelX -= labelSpacing;
    }
  }

  const style = {
    zIndex: 1,
    strokeWidth: 0.5,
    stroke:
      hoveredEdge?.data.forwardEdge?.id === data?.forwardEdge?.id
        ? '#8df578'
        : "#479ef5",
  };

  return (
    <>
      {/* Forward edge */}
      <BaseEdge path={forwardPath} markerEnd={markerEnd} style={style}  />
      
      {/* Backward edge */}
      <BaseEdge path={backwardPath} markerEnd={markerEnd} style={style} />
      
      {/* Edge labels */}
      <EdgeLabelRenderer>
        {/* Forward edge label */}
        {data?.forwardEdge?.data?.label && (
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${forwardLabelX}px,${forwardLabelY}px)`,
              backgroundColor: "#c8e0f6",
              padding: 2,
              borderRadius: 1,
              fontSize: 5,
              fontWeight: 700,
            }}
            className="nodrag nopan"
          >
            {data.forwardEdge.data.label}
          </div>
        )}
        {/* Backward edge label */}
        {data?.backwardEdge?.data?.label && (
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${backwardLabelX}px,${backwardLabelY}px)`,
              backgroundColor: "#c8e0f6",
              padding: 2,
              borderRadius: 1,
              fontSize: 5,
              fontWeight: 700,
            }}
            className="nodrag nopan"
          >
            {data.backwardEdge.data.label}
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default BidirectionalEdge;
