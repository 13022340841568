import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactFlow, {
  useStoreApi,
  MarkerType,
  Background,
  addEdge,
  Controls,
  ControlButton,
  applyNodeChanges,
  applyEdgeChanges,
  getConnectedEdges,
} from "reactflow";
import "reactflow/dist/style.css";
import { Tooltip, Modal, Input, Typography, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faDownload,
  faToggleOn,
  faMaximize,
  faMinimize,
} from "@fortawesome/free-solid-svg-icons";

import { toPng } from "html-to-image";

import styled, { ThemeProvider } from "styled-components";

import { calculateDagreLayout } from "../../utils/layoutAlgorithms/dagreLayout";
import { lightTheme, darkTheme } from "../../utils/themes";
import withRouter from "../../utils/WithRouter";
import withProps from "../../utils/WithProps";
import BidirectionalNode from "../customNodes/BidirectionalNode";
import { TableNode } from "../customNodes/TableNode";
import DefaultEdge from "../customEdges/DefaultEdge";
import BidirectionalEdge from "../customEdges/BidirectionalEdge";
import SelfConnectingEdge from "../customEdges/SelfConnectingEdge";
import { loadDatabaseConfig } from "../../utils/dblayoutHelpers/loadDatabaseConfig";
import { initializeNodes } from "../../utils/dblayoutHelpers/initializeNodes";
import { calculateEdges } from "../../utils/dblayoutHelpers/calculateEdges";
import { setHighlightEdgeClassName } from "../../utils/dblayoutHelpers/setHighlightEdgeClassName";
import { setEdgeClassName } from "../../utils/dblayoutHelpers/setEdgeClassName";
import Loader from "../customLoaders/Loader";
import { manageNodeDetails } from "../../config/Api";
import ContextMenus from "../ContextMenu";
import {
  fetchGraphData,
} from "../../container/actions/graphActions";
import DefectNode from "../customNodes/DefectNode";
import CustomNodeTypeOne from "../customNodes/CustomNodeTypeOne";
import { toast } from "react-toastify";
import CommonModal from "../modalForm/commonModal";
import InfoModal from "../modalForm/InfoModal";
import { fetchCataLogHeader } from "../../container/actions/catalogActions";
import EdgeModal from "../modalForm/EdgeModal";
import InfoBox from "../InfoBox";

const downloadImage = (dataUrl) => {
  const a = document.createElement("a");
  a.setAttribute("download", "reactflow.png");
  a.setAttribute("href", dataUrl);
  a.click();
};

const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const ReactFlowStyled = styled(ReactFlow)`
  background-color: ${(props) => props.theme.bg};
`;

const StyledDiv = styled.div`
   {
    ${({ color }) =>
      color &&
      `
    margin: 0px;
    color: ${color};
  `}
  }
`;

const StyledButton = styled(Button)`
  .ant-btn {
    ${({ color }) =>
      color &&
      `
    background-color: ${color};
    color: #fff;
  `}
  }
`;

const ControlsStyled = styled(Controls)`
  button {
    background-color: ${(props) => props.theme.controlsBg};
    color: ${(props) => props.theme.controlsColor};
    border-bottom: 1px solid ${(props) => props.theme.controlsBorder};

    &:hover {
      background-color: ${(props) => props.theme.controlsBgHover};
    }

    path {
      fill: currentColor;
    }
  }
`;

const contextMenuOptions = [
  {
    id: 1,
    name: "View Lineage",
    key: "lineage",
    description: null,
  },
  {
    id: 2,
    name: "View Details",
    key: "nodeDetails",
    description: null,
  },
  {
    id: 3,
    name: "View Next Level",
    key: "levels",
    description: null,
  },
];

const proOptions = { hideAttribution: true, account: "paid-pro" };

const CreateGraph = ({
  color,
  treeWidth = 120,
  treeHeight = 200,
  animationDuration = 300,
  editGraph,
  saveOrCancel,
  token,
  catalogName,
  selectedFilters,
}) => {
  const store = useStoreApi();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const reactFlowInstance = useRef(null);

  const defaultEdgeOptions = {
    markerEnd: { type: MarkerType.ArrowClosed, color: color },
    pathOptions: { offset: 5 },
  };

  const [isDataReady, setIsDataReady] = useState(false);
  const [layoutData, setLayoutData] = useState(null);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState("100%");
  const [containerHeight, setContainerHeight] = useState("100%");
  const [nodes, setNodes] = useState([]);
  const [newNodes, setNewNodes] = useState([]);
  const [levelsOpened, setlevelsOpened] = useState(false);
  const [edges, setEdges] = useState([]);
  const [isEdgeModalOpen, setIsEdgeModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [mode, setMode] = useState("light");
  const theme = mode === "light" ? lightTheme : darkTheme;
  const [direction, setDirection] = useState(true);
  const [selectedNodeDetails, setSelectedNodeDetails] = useState(null);
  const [nodeType, setNodeType] = useState(null);
  const [selectedNode, setSelectedNode] = useState({});
  const [selectedEdgeDetails, setSelectedEdgeDetails] = useState({});
  const [graphDataChanged, setGraphDataChanged] = useState(false);
  const [fullscreenOn, setFullScreen] = useState(false);
  const [newNodeDetails, setNewNodeDetails] = useState({
    Name: "",
    Description: "",
    id: "",
    position: {},
    newNodeId: "",
  });
  const [nodeHoverActive, setNodeHoverActive] = useState(true);
  const [newNodeModal, setNewNodeModal] = useState(false);
  const [menu, setMenu] = useState(null);
  const [infoBox, setInfoBox] = useState(null);
  const ref = useRef(null);
  const [hoveredEdge, setHoveredEdge] = useState(null);
  const [hoveredNodes, setHoveredNodes] = useState(new Set());
  const [showInfo, setShowInfo] = useState(false);
  const [showMoreClick, setShowMoreClick] = useState(false)

  const graphData = useSelector((graphState) => graphState?.systemGraphReducer);

  const currentFlowData = useSelector(
    (currentFlowState) => currentFlowState?.currentFlowReducer?.currentFlow
  );

  const parts = location.pathname.split("/");
  const currentGraphId = parts[parts.length - 1];

  //useAutoLayout({ direction: "LR", graphType: graphData?.graphType });

  // this function adds a new node and connects it to the source node
  const createConnection = () => {
    // create an incremental ID based on the number of elements already in the graph
    const targetId = `${nodes.length + 1}`;

    const targetNode = {
      id: targetId,
      data: {
        Name: newNodeDetails.Name,
        Description: newNodeDetails.Description,
      },
      position: { x: 0, y: 0 },
      type: "custom",
      style: {
        position: "absolute",
      },
    };

    const connectingEdge = {
      id: `${newNodeDetails.id}->${targetId}`,
      source: newNodeDetails.id,
      target: targetId,
      style: { opacity: 0 },
      type: "basic",
    };

    setNewNodes([...newNodes, targetNode]);
    setNodes((nodes) => nodes.concat([targetNode]));
    setEdges((edges) => edges.concat([connectingEdge]));
    setGraphDataChanged(!graphDataChanged);
  };

  // const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(
  //   nodes,
  //   edges,
  //   { treeWidth, treeHeight }
  // );
  // const { nodes: animatedNodes } = useAnimatedNodes(visibleNodes, {
  //   animationDuration,
  // });

  // console.log(animatedNodes)

  // useEffect(() => {
  //   const updatedGraphNodes = {
  //     ...graphData,
  //     nodes,
  //     edges,
  //   };
  //   dispatch(updateGraph(updatedGraphNodes));
  // }, [editGraph]);

  const toggleMode = () => {
    setMode((m) => (m === "light" ? "dark" : "light"));
  };

  const toggleFullScreen = () => {
    if (fullscreenOn) {
      document
        .exitFullscreen()
        .then(function () {
          setFullScreen(false);
        })
        .catch(function (error) {
          alert("Can't exit fullscreen");
          console.error(error);
        });
    } else {
      var element = document.querySelector("body");

      // make the element go to full-screen mode
      element &&
        element
          .requestFullscreen()
          .then(function () {
            setFullScreen(true);
          })
          .catch(function (error) {
            alert("Can't turn on fullscreen");
            console.error(error);
          });
    }
  };

  const CustomControls = ({
    download,
    toggleMode,
    direction,
    setDirection,
  }) => {
    return (
      <ControlsStyled showInteractive={false}>
        <ControlButton onClick={download}>
          <Tooltip title={"download Image"} color={"cyan"}>
            <FontAwesomeIcon icon={faDownload} size="2x" />
          </Tooltip>
        </ControlButton>
        <ControlButton onClick={toggleMode}>
          <Tooltip title={"Switch Mode"} color={"cyan"}>
            <FontAwesomeIcon icon={faToggleOn} size="2x" />
          </Tooltip>
        </ControlButton>
        <ControlButton onClick={toggleFullScreen}>
          <Tooltip title={"Full Screen Mode"} color={"cyan"}>
            <FontAwesomeIcon
              icon={fullscreenOn ? faMinimize : faMaximize}
              size="2x"
            />
          </Tooltip>
        </ControlButton>{" "}
      </ControlsStyled>
    );
  };

  // useEffect(() => {
  //   if (reactFlowInstance) {
  //     reactFlowInstance.current.fitView({ duration: 400 });
  //   }
  // }, [nodes, direction, color, reactFlowInstance, edges, editGraph]);

  const handleLevels = (node) => {
    const getGraphPayload = node.data.innerGraphRequestBody;
    dispatch(
      fetchGraphData(token, getGraphPayload, currentFlowData["Catalog Name"])
    );
    setlevelsOpened(true);
    setSelectedNodeDetails(node);
  };

  const handleInfoSubmit = (details) => {
    if (!currentFlowData?.Title && !currentFlowData?.Description) {
      toast.error("Please save the graph details then try to edit");
      return;
    }

    const updatedNodeDetails = {
      ...selectedNodeDetails,
      ...details,
    };
    const response = manageNodeDetails(
      `api/catalog/${selectedNode?.data.innerGraphRequestBody.Item.nodeName}/1`,
      token,
      "PATCH",
      updatedNodeDetails
    );
    const getGraphPayload = {
      Case: "SystemGraphRequest",
      Item: {
        Case: "TagNames",
        tags: [currentFlowData.Id],
      },
    };

      dispatch(
        fetchGraphData(token, getGraphPayload, currentFlowData["Catalog Name"])
      );
    setIsEditModalOpen(false);
    setGraphDataChanged(true);
  };

  useEffect(() => {
    if (graphData?.graphData) {
      const currentGraphDetail = graphData?.graphData.find(
        (graph) => graph.Id === currentGraphId
      )?.graphDetails;
      if (
        currentGraphDetail?.nodes?.length > 0 &&
        selectedNodeDetails &&
        levelsOpened
      ) {
        navigate(
          selectedNodeDetails?.data?.innerGraphRequestBody?.Item?.nodeIdInfo.hasOwnProperty(
            "idNum"
          )
            ? `/graph/${selectedNodeDetails?.data.innerGraphRequestBody.Item.nodeIdInfo.idNum}`
            : `/graph/${selectedNodeDetails?.data.innerGraphRequestBody.Item.nodeIdInfo.idStr}`
        );

        setlevelsOpened(false);
      }
    }
  }, [graphData?.graphData, levelsOpened, currentGraphId, selectedNodeDetails]);

  useEffect(() => {
    const currentGraphDetail = graphData?.graphData.find(
      (graph) => graph.Id === currentGraphId
    )?.graphDetails;
    if (currentGraphDetail?.nodes?.length === 0)
      toast.success(
        "Hey theres no internal system to view.Please navigate to previous levels"
      );
  }, [graphData?.graphData, levelsOpened, currentGraphId, selectedNodeDetails]);

  const createNewChildNode = (node) => {
    setNewNodeModal(true);
    setNewNodeDetails({
      ...newNodeDetails,
      id: node.id,
      position: { x: node.position.x + 100, y: node.position.y + 100 },
    });
  };

  const handleNewNodeCancel = () => {
    setNewNodeModal(false);
  };

  const onNodeClick = (event, node) => {
    setInfoBox(null);
    if (!editGraph) {
      setSelectedNode(node);
      if (node.data?.innerGraphRequestBody) {
        setNodeType(node.data?.innerGraphRequestBody?.Item?.nodeName);
      }

      const pane = ref.current.getBoundingClientRect();
      const menuWidth = 200;
      const menuHeight = 200;

      const nodeRect = node.__rf?.handleBounds;

      let menuLeft = event.clientX - pane.left;
      let menuTop = event.clientY - pane.top;

      // Adjust position to prevent overflow
      if (menuLeft + menuWidth > pane.width) {
        menuLeft = Math.max(0, pane.width - menuWidth);
      }

      if (menuTop + menuHeight > pane.height) {
        menuTop = Math.max(0, pane.height - menuHeight);
      }

      // If the menu is still overflowing, reposition it to the clicked node
      if (
        menuLeft + menuWidth > pane.width ||
        menuTop + menuHeight > pane.height
      ) {
        menuLeft = nodeRect.x + nodeRect.width;
        menuTop = nodeRect.y + nodeRect.height;
      }

      setMenu({
        id: node.id,
        left: menuLeft,
        top: menuTop,
        handleContextMenu: handleContextMenu,
        options: contextMenuOptions,
        nodeDetails: node,
      });

      return;
    }

    //return createNewChildNode(node);
  };

  const handleChange = (e, fieldName) => {
    setNewNodeDetails({
      ...newNodeDetails,
      [fieldName]: e.target.value,
    });
  };

  const handleSave = () => {
    createConnection();
    setNewNodeDetails({});
    setNewNodeModal(false);
  };

  const nodeTypes = React.useMemo(
    () => ({
      // custom: withProps(DefaultNode, {
      //   direction,
      //   selectedNodeDetails,
      //   color,
      //   setIsNodeModalOpen,
      //   editGraph,
      //   newNodeDetails,
      //   setNewNodeDetails,
      // }),
      custom: withProps(CustomNodeTypeOne, {
        direction,
        selectedNodeDetails,
        color,
        editGraph,
        newNodeDetails,
        setNewNodeDetails,
        hoveredEdge,
      }),
      defect: withProps(DefectNode, {
        direction,
        selectedNodeDetails,
        color,
        editGraph,
        hoveredEdge,
      }),
      bidirectional: withProps(BidirectionalNode, {
        direction,
        selectedNodeDetails,
        color,
        newNodeDetails,
        setNewNodeDetails,
      }),
      table: withProps(TableNode, {
        setShowInfo,
        showInfo,
        handleTableHeaderClick,
        setShowMoreClick,
      }),
    }),
    [
      direction,
      selectedNodeDetails,
      color,
      editGraph,
      newNodeDetails,
      hoveredEdge,
      setShowInfo,
      showInfo,
      infoBox
    ]
  );

  const edgeTypes = React.useMemo(
    () => ({
      basic: withProps(DefaultEdge, {
        direction,
        color,
        setIsEdgeModalOpen,
        hoveredEdge,
      }),
      bidirectional: withProps(BidirectionalEdge, {
        direction,
        color,
        setIsEdgeModalOpen,
        hoveredEdge,
      }),
      selfconnecting: withProps(SelfConnectingEdge, {
        direction,
        color,
        setIsEdgeModalOpen,
        hoveredEdge,
      }),
      // buttonedge: withProps(ButtonEdge, {
      //   onInfoClick,
      // }),
    }),

    [direction, color, hoveredEdge]
  );

  const download = () => {
    toPng(document.querySelector(".react-flow"), {
      filter: (node) => {
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls")
        ) {
          return false;
        }

        return true;
      },
    }).then(downloadImage);
  };

  function checkNode(values, isIdField, node, field) {
    const idOrField = isIdField ? node.id.split('.').pop() : node.data?.[field];
    return values.includes(idOrField);
}

  const createFilteredLayoutData = (nodes, edges) => {
    let filteredNodes = [...nodes];
    let filteredEdges = [...edges];
    console.log(filteredEdges, filteredNodes, "hellll");
    const mergedEdges = mergeInterlinkedEdges(filteredEdges);

    Object.entries(selectedFilters).forEach(([field, values]) => {
      if (values.length > 0) {
        const isIdField = field === "ID";

        filteredNodes = filteredNodes.filter((node) => {
          return checkNode(values, isIdField, node, field)
        });

        filteredEdges = mergedEdges.filter((edge) => {
          const edgeSourceMatches = values.includes(
            isIdField ? edge.source : edge.data?.[field]
          );
          const edgeTargetMatches = values.includes(
            isIdField ? edge.target : edge.data?.[field]
          );
          return edgeSourceMatches || edgeTargetMatches;
        });
      }
    });
    return {
      filteredEdges,
      filteredNodes,
      mergedEdges,
    };
  };

  const mergeInterlinkedEdges = (edges) => {
    const mergedEdgeMap = {};

    for (const edge of edges) {
      const [source, target] = [edge.source, edge.target].sort(); // Sort the nodes to ensure consistent key generation
      const key = `${source}-${target}`;

      if (!mergedEdgeMap[key]) {
        mergedEdgeMap[key] = {
          ...edge,
          data: { forwardEdge: null, backwardEdge: null },
        };
      } else {
        mergedEdgeMap[key].type = "bidirectional";
      }

      mergedEdgeMap[key].data[
        source === edge.source ? "forwardEdge" : "backwardEdge"
      ] = edge;
    }

    return Object.values(mergedEdgeMap);
  };

  const getMetaGraphLayoutData = (graphData) => {
    const columnsLineagegraphData = JSON.parse(JSON.stringify(graphData));
    const metaGraphData = loadDatabaseConfig(columnsLineagegraphData);
    const metaNodes = initializeNodes(metaGraphData?.graphData);
    const metaEdges = calculateEdges({ metaNodes, metaGraphData, color });
    const { filteredNodes, filteredEdges, mergedEdges } =
      createFilteredLayoutData(metaNodes, metaEdges);
    const layoutData = calculateDagreLayout(
      filteredNodes,
      mergedEdges,
      columnsLineagegraphData.graphType
    );
    return layoutData;
  };

  useEffect(() => {
    if (graphData?.graphData.length > 0 && currentGraphId && selectedFilters) {
      const currentGraphDetail = graphData?.graphData.find(
        (graph) => graph.Id === currentGraphId
      )?.graphDetails;
      if (currentGraphDetail) {
        setIsDataReady(false);
        const { nodes, edges } = currentGraphDetail;
        const { filteredNodes, filteredEdges, mergedEdges } =
          createFilteredLayoutData(nodes, edges);

        let layoutData;

        if (currentGraphDetail.graphType === "columnlineage") {
          layoutData = getMetaGraphLayoutData(currentGraphDetail);
        } else {
          layoutData = calculateDagreLayout(
            filteredNodes,
            mergedEdges,
            currentGraphDetail.graphType
          );
        }
        setLayoutData(layoutData);
      }
    }
  }, [graphData?.graphData, currentGraphId, selectedFilters]);

  useEffect(() => {
    if (layoutData) {
      const maxX = layoutData.calculatedNodes.reduce((max, node) => {
        return node.position.x + node.data.width > max
          ? node.position.x + node.data.width
          : max;
      }, 0);
      const maxY = layoutData.calculatedNodes.reduce((max, node) => {
        return node.position.y + node.data.height > max
          ? node.position.y + node.data.height
          : max;
      }, 0);

      // Set the container's width and height dynamically based on maxX and maxY coordinates
      if (containerRef.current) {
        const desiredWidth = Math.min(window.innerWidth, maxX);
        const desiredHeight = Math.min(600, maxY);
        // Set container width and height with some padding
        setContainerWidth(desiredWidth);
        setContainerHeight(desiredHeight);
      }
      setNodes(layoutData.calculatedNodes);
      setEdges(layoutData.edges);
      setIsDataReady(true);
    }
  }, [layoutData]);

  useEffect(() => {
    if (editGraph) {
      setIsDataReady(false);
      const layoutDataSystemLineage = calculateDagreLayout(
        nodes,
        edges,
        "SystemLineage"
      );
      setLayoutData(layoutDataSystemLineage);
      setIsDataReady(true);
    }
  }, [graphDataChanged]);

  const onNodeMouseEnter = useCallback(
    (event, node) => {
      if (!nodeHoverActive) {
        return;
      }

      const state = store.getState();
      state.resetSelectedElements();
      state.addSelectedNodes([node.id]);

      const connectedEdges = getConnectedEdges([node], edges);
      setEdges((eds) => {
        return eds.map((ed) => {
          if (connectedEdges.find((e) => e.id === ed.id)) {
            setHighlightEdgeClassName(ed);
          }

          return ed;
        });
      });
    },
    [edges, nodeHoverActive, setEdges, store]
  );

  function handleTableHeaderClick(event, node) {
    setSelectedNode(node);
    if (node.data?.innerGraphRequestBody) {
      setNodeType(node.data?.innerGraphRequestBody?.Item?.nodeName);
    }

    const pane = ref.current.getBoundingClientRect();
    const menuWidth = 300;
    const menuHeight = 300;

    const nodeRect = node.__rf?.handleBounds;

    let menuLeft = event.clientX - pane.left;
    let menuTop = event.clientY - pane.top;

    // Adjust position to prevent overflow
    if (menuLeft + menuWidth > pane.width) {
      menuLeft = Math.max(0, pane.width - menuWidth);
    }

    if (menuTop + menuHeight > pane.height) {
      menuTop = Math.max(0, pane.height - menuHeight);
    }

    // If the menu is still overflowing, reposition it to the clicked node
    if (
      menuLeft + menuWidth > pane.width ||
      menuTop + menuHeight > pane.height
    ) {
      menuLeft = nodeRect.x + nodeRect.width;
      menuTop = nodeRect.y + nodeRect.height;
    }

    setShowInfo(true);
    setInfoBox({
      id: node.id,
      left: menuLeft,
      top: menuTop,
      nodeDetails: node,
      setShowInfo,
    });
    return;
  }

  const onNodeMouseLeave = useCallback(
    (_, node) => {
      if (!nodeHoverActive) {
        return;
      }

      const state = store.getState();
      state.resetSelectedElements();

      setEdges((eds) => eds.map((ed) => setEdgeClassName(ed)));

      // setInfoBox(null);

      // https://stackoverflow.com/questions/2520650/how-do-you-clear-the-focus-in-javascript
      document.activeElement.blur();
    },
    [nodeHoverActive, setEdges, store]
  );

  const handleContextMenu = (selectedMenu, nodeDetails) => {
    if (typeof contextMenuFunctions[selectedMenu] === "function") {
      contextMenuFunctions[selectedMenu](nodeDetails);
    } else {
      // Handle the case when the selectedMenu doesn't correspond to a function
      console.error(`${selectedMenu} is not a valid function.`);
    }
  };

  // const onNodeContextMenu = useCallback(
  //   (event, node) => {
  //     event.preventDefault();

  //   },

  //   [setMenu]
  // );

  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const contextMenuFunctions = {
    lineage: (selectedNode) => {
      var nodeIdData =
        selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.Case === "IdNum"
          ? {
              Case: "IdNum",
              idNum:
                selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.idNum,
            }
          : {
              Case: "Id",
              idStr:
                selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.idStr,
            };

      const getLineageGraphPayload = {
        Case: selectedNode.data.innerGraphRequestBody.Case,
        Item: {
          Case: "Lineage",
          nodeName: selectedNode.data.innerGraphRequestBody.Item.nodeName,
          nodeUniqueColName:
            selectedNode.data.innerGraphRequestBody.Item.nodeUniqueColName,
          nodeId: nodeIdData,
        },
      };
      dispatch(
        fetchGraphData(
          token,
          getLineageGraphPayload,
          currentFlowData["Catalog Name"]
        )
      );
      setlevelsOpened(true);
      setSelectedNodeDetails(selectedNode);
    },
    nodeDetails: async (selectedNode) => {
      dispatch(
        fetchCataLogHeader(
          selectedNode?.data?.innerGraphRequestBody?.Item.nodeName ??
            selectedNode?.data?.nodeLabel,
          token,
          currentFlowData["Catalog Name"]
        )
      );
      const nodeDetails = await manageNodeDetails(
        selectedNode.data.moreDataUrl,
        token,
        "GET"
      );
      setSelectedNodeDetails(nodeDetails[0]);
      setIsModalOpen(true);
    },
    metaDetails: async (selectedNode) => {
      const metaDataPayload = {
        Case: "DatabaseSchemaRequest",
        Item: selectedNode.data.innerGraphRequestBody.Item,
      };
      dispatch(
        fetchGraphData(token, metaDataPayload, currentFlowData["Catalog Name"])
      );
      setlevelsOpened(true);
      setSelectedNodeDetails(selectedNode);
    },
    levels: (selectedNode) => handleLevels(selectedNode),
  };

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onEdgeClick = (event, edge) => {
    console.log(edge);
    setSelectedEdgeDetails(edge.data);
    setIsEdgeModalOpen(!isEdgeModalOpen);
  };

  const onEdgeMouseEnter = (event, edge) => {
    setHoveredEdge(edge);
    setHoveredNodes(new Set([edge.source, edge.target]));
    event.stopPropagation();
  };

  const onEdgeMouseLeave = () => {
    setHoveredEdge(null);
    setHoveredNodes(new Set());
  };

  const onLoad = (reactFlowInstanceParam) => {
   
    reactFlowInstance.current = reactFlowInstanceParam;
  };

  const key = `${graphData?.graphData?.find((graph) => graph.Id === currentGraphId)?.graphDetails.graphType}-${nodes.length}`;

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "600px",
        overflow: "auto",
        position: "relative",
      }}
    >
      <ThemeProvider theme={theme}>
        {!graphData?.loading && isDataReady ? (
          <ReactFlowStyled
            style={{ width: "100%", height: "100%" }}
            // style={{ width: `${containerWidth}px`, height: `${containerHeight}px` }}
            key={
              graphData?.graphData?.find((graph) => graph.Id === currentGraphId)
                ?.graphDetails.graphType || nodes?.length
            }
            ref={ref}
            nodes={nodes}
            edges={edges}
            onPaneClick={onPaneClick}
            //onNodeContextMenu={onNodeContextMenu}
            fitView
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
            snapToGrid={true}
            onNodesChange={onNodesChange}
            onNodeMouseEnter={onNodeMouseEnter}
            onNodeMouseLeave={onNodeMouseLeave}
            onEdgesChange={onEdgesChange}
            //onEdgeMouseEnter={onEdgeMouseEnter} onEdgeMouseLeave={onEdgeMouseLeave}
            defaultEdgeOptions={defaultEdgeOptions}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodesDraggable={false}
            zoomOnScroll={false}
            proOptions={proOptions}
            onLoad={(instance) => (reactFlowInstance.current = instance)}
          >
            <CustomControls
              download={download}
              toggleMode={toggleMode}
              direction={direction}
              setDirection={setDirection}
            />
            <Background />
            {menu && !editGraph && (
              <ContextMenus onClick={onPaneClick} {...menu} />
            )}
            {infoBox && showInfo && <InfoBox {...infoBox} />}
          </ReactFlowStyled>
        ) : (
          <Loader />
        )}
      </ThemeProvider>
      {isEdgeModalOpen && !editGraph ? (
        <EdgeModal
          data={selectedEdgeDetails}
          isEdgeModalOpen={isEdgeModalOpen}
          setIsEditModalOpen={setIsEdgeModalOpen}
          token={token}
          currentFlowData={currentFlowData}
          catalogName={catalogName}
        />
      ) : null}
      {isModalOpen && !editGraph ? (
        <InfoModal
          setIsModalOpen={setIsModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          selectedNode={selectedNode}
          token={token}
          isModalOpen={isModalOpen}
          graphType={graphData?.graphData?.find((graph) => graph.Id === currentGraphId)?.graphDetails.graphType}
          catalogName={catalogName}
        />
      ) : null}
      {isEditModalOpen ? (
        <CommonModal
          setIsEditModalOpen={setIsEditModalOpen}
          data={selectedNodeDetails}
          handleInfoSubmit={handleInfoSubmit}
          nodeType={nodeType}
          isEditModalOpen={isEditModalOpen}
        />
      ) : null}
      <Modal
        title="Add System Information"
        open={newNodeModal}
        onOk={handleSave}
        onCancel={handleNewNodeCancel}
      >
        <p>
          <div key="name" className="md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              htmlFor="Name"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Name{" "}
              <abbr title="required" className="text-red-600">
                *
              </abbr>
            </label>
            <input
              type="text"
              required="required"
              id="name"
              onChange={(event) => handleChange(event, "Name")}
              value={newNodeDetails.Name}
              className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
            />
          </div>
          <div key="description" className="md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              htmlFor="description"
              className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
            >
              Description{" "}
              <abbr title="required" className="text-red-600">
                *
              </abbr>
            </label>
            <input
              type="text"
              required="required"
              id="description"
              onChange={(event) => handleChange(event, "Description")}
              value={newNodeDetails.Description}
              className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
            />
          </div>
        </p>
        <p class="font-newOne text-xs text-red-500 text-right my-3">
          Required fields are marked with an asterisk{" "}
          <abbr title="Required field">*</abbr>
        </p>
      </Modal>
    </div>
  );
};

export default withRouter(CreateGraph);
