import { UPDATE_THEME, RESET_THEME } from "../actionTypes"

const initialState = {
    sidenavColor: "#00309b",
    sidenavType: "#fff"
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_THEME:
      return {
        ...state,
        sidenavColor: action.payload.sidenavColor,
        sidenavType: action.payload.sidenavType
      };

    case RESET_THEME:
      return {
        ...state,
        sidenavColor: state.sidenavColor,
        sidenavType: state.sidenavType
      };
    default:
      return state;
  }
};

export default themeReducer