import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { HotTable } from "@handsontable/react";
import { useDispatch, useSelector } from "react-redux";
import Graph from "./Graph";
import Home from "./Home";
import { fetchRelationshipMetaInfo } from "../container/actions/catalogActions";
import Main from "../components/layout/Main";
import SubmenuComponent from "../SubmenuComponent";

const renderCatalogRoutes = (
  groupedCatalogData,
  relationshipMetaState,
  token
) => {
  return Object.entries(groupedCatalogData).map(
    ([catalogName, catalogItems]) => {
      const catalogRoute = (
        <Route
          key={catalogName}
          path={`/${catalogName.replace(/\s+/g, "").toLowerCase()}`}
          element={
            <HotTable
              data={catalogItems}
              licenseKey="non-commercial-and-evaluation"
            />
          }
        />
      );
      const submenuRoutes = catalogItems.map((submenu) => {
        //Replace any colons (:) with (-)
        const submenuName = submenu["Node Name"];
        const submenuPath = `/${catalogName
          .replace(/\s+/g, "")
          .toLowerCase()}/${submenuName
          .replace(/\s+/g, "")
          .toLowerCase()
          .replace(/:/g, "-")}`;
        return (
          <Route
            key={`${catalogName}-${submenuName}`}
            path={submenuPath}
            element={
              <SubmenuComponent
                catalogName={catalogName}
                displayName={submenu["Display Name"]}
                nodeDescription={submenu["Description"]}
                submenuName={submenuName}
                relationshipMetaState={relationshipMetaState}
                token={token}
              />
            }
          />
        );
      });
      // Return both the main catalog route and the submenu routes
      return [catalogRoute, ...submenuRoutes];
    }
  );
};

function Dashboard({ token, masterList }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const relationshipMetaState = useSelector(
    (catalogState) => catalogState.fetchRelationshipMetaInfoReducer
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchRelationshipMetaInfo(1000, 0, token));
    }
  }, [token]);

  const groupedCatalogData = masterList?.reduce((acc, catalog) => {
    const catalogName = catalog["Catalog Name"];
    if (!acc[catalogName]) {
      acc[catalogName] = [];
    }
    acc[catalogName].push(catalog);
    return acc;
  }, {});

  return (
    <Main>
      <Routes key={location.pathname} location={location}>
        <Route path="dashboard" element={<Home token={token} />} />
        {masterList &&
          renderCatalogRoutes(groupedCatalogData, relationshipMetaState?.data, token)}
        <Route path="/graph/:graphName" element={<Graph token={token} />} />
        <Route path="/subgraph/:id" element={<Graph token={token} />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate replace to="/dashboard" />} />
      </Routes>
    </Main>
  );
}

export default Dashboard;
