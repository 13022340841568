export const SELECTED_CLASS = "selected";
export const ODD_ROW_CLASS = "odd";

///Default Tables
export const MASTER_TABLE = "NodeInfo";
export const PROPERTIES_TABLE = "PropertyInfo";
export const ENUM_VALUES_TABLE = "EnumValues";
export const TAG_TABLE = "Tag";
export const SYSTEM_TABLE = 'System Graph';
export const METADATA_TABLE = 'Metadata Graph';
export const TAG_GRAPH_TABLE = 'Tag Graph';
export const DATA_FLOW_TABLE = "Tag:DataFlow";
export const GRAPH_DETAILS_TABLE = "NodeGraphDetails";
export const TAGS = "Tags";
export const GRAPH_REQUEST = "GraphRequest";
export const CREATE_GRAPH = "Create Graph";
export const CREATE = "Create";
export const CREATE_SYSTEM_GRAPH = "Create System Graph";
export const CREATE_METADATA_GRAPH = "Create Metadata Graph";
export const GRAPH = "Graph";
export const RELATIONSHIPS = "Relationships";
export const MANAGE = "Manage";
export const MANAGE_OWNER = "Owner";
export const MANAGE_OWNER_TAGS = "Tags";

//Mapping used for Tool Data Type -> Handson Table Datatype 
export const DataTypeMapping = {
  Integer: "numeric",
  String: "text",
  Float: "numeric",
  Boolean: "checkbox",
};

//Max Number of rows to get for the Master List
export const MASTER_TABLE_MAX_ROWS = 500;

export const dataTypeConversions = {
  String: (value) => String(value),
  Number: (value) => Number(value),
  LocalDateTime: (value) => new Date(value),
  // Add more data type conversions as needed
};

export const graphRequestMapping = {
  "System Graph":"SystemGraphRequest",
  "Metadata Graph":"DatabaseSchemaRequest",
  "Tag Graph":["SystemGraphRequest","DatabaseSchemaRequest"]
}

export const GRAPHICAL = "Graphical";
export const OWNER = "Owner";
export const GET = "GET";
export const POST = "POST";
export const DELETE = "DELETE";
export const GRAPH_RELATIONSHIPS = "GraphicalRelationships";
export const RELATIONSHIP_TYPES = "Relationship Type:";
export const DESTINATION_NODE = "Destination Node:";
export const DESTINATION_NAMES = "Destination Names:";
export const SOURCE_NAMES = "Source Names:";
export const OWNER_RELATIONSHIPS = "OwnerRelationships";
export const TAG_RELATIONSHIPS = "TagRelationships";
export const EXISTING_THINGS = "s associated with Id: ";
export const ADD_NEW = "Add ";

export const OWNER_DETAILS = ['Owner Id', 'Name', 'Owner Type', 'Email', 'Phone No.'];

