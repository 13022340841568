import { fullTableName } from "./fullTableName";

const setHandleType = (tableConfigs, tableName, columnName, handleType) => {
  tableConfigs.forEach((tableConfig) => {
    const configTableName = fullTableName(
      tableConfig.id,
      tableConfig.schema || "public"
    );
    if (configTableName === tableName) {
      tableConfig.columns.forEach((columnConfig) => {
        if (columnConfig.name === columnName) {
          // If handleType is an array, add the new handle type
          if (Array.isArray(columnConfig.handleType)) {
            columnConfig.handleType.push(handleType);
          } else {
            // Otherwise, create a new array with the handle types
            columnConfig.handleType = [handleType];
          }
        }
      });
    }
  });
};

export const initializeNodes = (databaseConfig) => {
  const tables = [];
  const tablePositionsWithSchema = {};

  databaseConfig.edges.forEach((edgeConfig) => {
    const sourceTableName = fullTableName(edgeConfig.source);
    const targetTableName = fullTableName(edgeConfig.target);

    // Set source handle type for the source node
    setHandleType(
      databaseConfig.nodes,
      sourceTableName,
      edgeConfig.sourceKey,
      "source"
    );

    // Set target handle type for the target node
    setHandleType(
      databaseConfig.nodes,
      targetTableName,
      edgeConfig.targetKey,
      "target"
    );
  });

  databaseConfig.nodes.forEach((tableConfig) => {
    const tableID = fullTableName(tableConfig.id);
    const tableDefinition = {
      ...tableConfig,
      id: tableID,
      data: {
        columns: tableConfig.columns,
        ...tableConfig.data,
        nodeLabel: tableConfig.nodeLabel,
        schemaColor: tableConfig.schemaColor
      },
      position: tablePositionsWithSchema[tableID] || { x: 0, y: 0 },
      type: "table",
    };

    tables.push(tableDefinition);
  });

  return tables;
};
