import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
  Label,
} from "recharts";

const NodeTypePieChart = ({ graphData, label }) => {
  // Calculate the count of each nodeType
  const nodeTypeCounts = graphData?.[label]?.reduce((counts, node) => {
    const nodeType =
      label === "nodes" ? node.data.nodeType : node.data.relationType;
    counts[nodeType] = (counts[nodeType] || 0) + 1;
    return counts;
  }, {});

  // Calculate the total number of nodes
  const totalNodes = graphData[label].length;

  // Calculate the percentage for each nodeType
  const nodeTypePercentageData = Object.keys(nodeTypeCounts).map(
    (nodeType) => ({
      name: nodeType,
      value: (nodeTypeCounts[nodeType] / totalNodes) * 100,
    })
  );

  const generateColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const hue = ((i * 10) % 360).toString();
      colors.push(`hsl(${hue}, 70%, 50%)`);
    }
    return colors;
  };

  const COLORS = generateColors(graphData?.[label].length);

  return (
    <div className="text-center">
      <h2 className="text-m font-semibold m-1">
        {label === "nodes"
          ? "Node Type Distribution"
          : "Edge Type Distribution"}
      </h2>
      
        <PieChart width={400} height={300}>
          <Pie
            data={nodeTypePercentageData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {nodeTypePercentageData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            {/* <Label
              value={({ percent }) => `${(percent * 100).toFixed(0)}%`}
              position="inside"
              fill="white"
            /> */}
          </Pie>
          <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
          <Legend align="right" verticalAlign="middle" layout="vertical" />
        </PieChart>
 
    </div>
  );
};

export default NodeTypePieChart;
