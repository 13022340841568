import React, { useState, useEffect } from "react";
import { Modal, Table, Row, Col, Select, Tag, Button } from "antd";
import { toast } from "react-toastify";
import {
  POST,
  DELETE,
  RELATIONSHIPS,
  EXISTING_THINGS,
  DESTINATION_NODE,
  RELATIONSHIP_TYPES,
} from "./data/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCatalogData,
  fetchCataLog,
  fetchCataLogHeader,
} from "./container/actions/catalogActions";
import CustomToast from "./components/CustomToast";
import CustomTablePagination from "./components/CustomTablePagination";
import "./assets/styles/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faCircleExclamation,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { customComparator, DEFAULT_PAGE_SIZE } from "./globalUtils";
import { getSelectedMenuDetails } from "./config/Api";
import { themeColors } from "./utils/constants";
import CustomPagination from "./components/CustomPagination";
import addSvg from "../src/assets/images/add.svg";
import deleteSvg from "../src/assets/images/delete.svg";

const OwnerTagLayout = ({
  token,
  catalogName,
  selectedNodeName,
  upstream,
  setIsOwnerTag,
  selectedId,
  relationModalDetails,
  submenuUniqueColName,
  relationshipType,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [selectedRows, setSelectedRows] = useState();
  const [addOwner, setAddOwner] = useState(false);
  const [shouldShowToast, setShouldShowToast] = useState(false);
  const [tableColumns, setTableColumns] = useState();
  const [tableData, setTableData] = useState();
  const [selectedRelationName, setSelectedRelationName] = useState();
  const [selectedDstNode, setSelectedDstNode] = useState();
  const [refresh, setRefresh] = useState(true);
  const [tableData2, setTableData2] = useState([]);
  const [destinationId, setDestinationId] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const themeState = useSelector((themeState) => themeState.themeReducer);
  //for main table
  const [hasNextPageMain, setHasNextPageMain] = useState(false);
  const [hasPrevPageMain, setHasPrevPageMain] = useState(false);
  const [currentPageMain, setCurrentPageMain] = useState(1);
  const [pageSizeMain, setPageSizeMain] = useState(DEFAULT_PAGE_SIZE);
  const [selCatalogName, setSelCatalogName] = useState(catalogName);
  const [catalogMenuData, setCatalogMenuData] = useState("");
  const [showModal, setShowModal] = useState(false);

  const cataLogsState = useSelector(
    (catalogState) => catalogState.cataLogDataReducer
  );

  const cataLogsHeaderState = useSelector(
    (catalogState) => catalogState.cataLogHeaderReducer
  );

  const handleDestinationNodeChange = (value) => {
    setSelectedDstNode(value);
  };
  const handleRelationshipNameChange = (value) => {
    setSelectedRelationName(value);
  };

  const handleCatalogNameChange = (value) => {
    setSelCatalogName(value);
  };

  const showToast = () => {
    setShouldShowToast(true);
  };

  const catalogNames = [
    ...new Set(relationModalDetails?.map((item) => item["Catalog Name"])),
  ];

  const destinationNodes = [
    ...new Set(relationModalDetails?.map((item) => item["Destination Node"])),
  ];

  const sourceNodes = [
    ...new Set(relationModalDetails?.map((item) => item["Source Node"])),
  ];

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to the first page when changing page size
  };

  const goToPageMain = (page) => {
    setCurrentPageMain(page);
  };

  const goToNextPageMain = () => {
    goToPageMain(currentPageMain + 1);
  };

  const goToPrevPageMain = () => {
    goToPageMain(currentPageMain - 1);
  };

  const handlePageSizeChangeMain = (value) => {
    setPageSizeMain(value);
    setCurrentPageMain(1);
  };

  //x - downstream, owner
  //x - downstream, graphical
  //upstream, owner
  //x - upstream, graphical
  //Run once! Only the first time!
  useEffect(() => {
    if (selectedDstNode === undefined || selectedDstNode === null) {
      if (!upstream && relationshipType !== "Graphical") {
        //Get Only the first destination node!
        setSelectedDstNode(relationModalDetails?.[0]["Destination Node"]);
      }
    }

    if (selectedRelationName === undefined || selectedRelationName === null) {
      if (relationshipType !== "Graphical") {
      setSelectedRelationName(relationModalDetails?.[0]["Relationship Name"]);
      }
    }

    setTableData2([]);
  }, []);

  useEffect(() => {
    const limit = pageSize;
    const skip = (currentPage - 1) * pageSize;
    if (addOwner === true) {
      dispatch(
        fetchCataLog(limit, skip, token, selectedDstNode, selCatalogName)
      );
    }
  }, [selectedDstNode, addOwner, currentPage, pageSize, selCatalogName]);

  useEffect(() => {
    if (addOwner === false) {
      fetchData(1, pageSizeMain);
      if (refresh === true) {
        setRefresh(false);
      }
    }
  }, [refresh, selectedRelationName, selectedDstNode, selCatalogName, pageSizeMain]);

  useEffect(() => {
    setTableData2(catalogMenuData);

    if (catalogMenuData?.length) {
      setHasNextPageMain(catalogMenuData?.length === pageSizeMain);
      setHasPrevPageMain(currentPageMain > 1);
    } else {
      //cataLogsState[submenuName].data?.length is 0 and hence hardcode it to false
      setHasNextPageMain(false);
      setHasPrevPageMain(currentPageMain > 1);
    }
  }, [catalogMenuData, pageSizeMain]);

  function updateDestinationId() {
    const propertyWithUniqueId = cataLogsHeaderState?.[
      selectedDstNode
    ]?.data?.find((item) => item["Property Type"] === "IS_IDENTITY");

    if (
      propertyWithUniqueId &&
      propertyWithUniqueId["Display Name"] !== destinationId
    ) {
      setDestinationId(propertyWithUniqueId["Display Name"]);
    }
  }

  useEffect(() => {
    if (selectedDstNode !== undefined && selectedDstNode !== null) {
      dispatch(fetchCataLogHeader(selectedDstNode, token, selCatalogName));
    }
  }, [selectedDstNode]);

  useEffect(() => {
    updateDestinationId();
  }, [cataLogsHeaderState]);

  useEffect(() => {
    const columns = Object.keys(
      cataLogsState[selectedDstNode]?.data?.[0] ?? {}
    ).map((key) => {
      const uniqueValues = new Set(
        cataLogsState[selectedDstNode]?.data?.map((rowData) => rowData[key])
      );
      // Exclude filters for Last Updated DateTime and Created DateTime
      const filters =
        key !== "Last Updated DateTime" && key !== "Created DateTime"
          ? Array.from(uniqueValues).map((value) => ({
              text: value,
              value: value,
            }))
          : undefined;

      const renderTypeTags = key.toLowerCase().includes("type")
        ? (tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          )
        : undefined;

      const renderErrorInformation =
        key.toLowerCase() === "error information"
          ? (text) => <span style={{ color: "red" }}>{text}</span>
          : undefined;

      // Include sorters for all columns
      const sorter = (a, b) => customComparator(a, b, key);
      return {
        title: key,
        dataIndex: key,
        key,
        filters: filters,
        onFilter: (value, record) =>
          record[key] ? record[key].toString().includes(value) : false,
        sorter: sorter,
        render: renderTypeTags || renderErrorInformation,
      };
    });

    setTableColumns(columns);
    setTableData(cataLogsState[selectedDstNode]?.data);
    if (cataLogsState.hasOwnProperty(selectedDstNode)) {
      if (cataLogsState[selectedDstNode]?.data?.length) {
        setHasNextPage(
          cataLogsState[selectedDstNode].data?.length === pageSize
        );
        setHasPrevPage(currentPage > 1);
      } else {
        //cataLogsState[submenuName].data?.length is 0 and hence hardcode it to false
        setHasNextPage(false);
        setHasPrevPage(currentPage > 1);
      }
    }
  }, [cataLogsState, pageSize]);

  useEffect(() => {
    if (shouldShowToast) {
      if (cataLogsState[selectedNodeName]?.error) {
        toast.error(
          <CustomToast
            message={`Unable to save, ${cataLogsState[selectedNodeName].error}`}
          />,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            pauseOnHover: true,
            closeButton: true,
          }
        );
      } else {
        toast.success("Saved Successfully");
      }
      setShouldShowToast(false);
    }
  }, [shouldShowToast]);

  const OwnerRelcolumns = Object.keys(catalogMenuData?.[0] ?? {}).map((key) => {
    const uniqueValues = new Set(
      catalogMenuData?.map((rowData) => rowData[key])
    );

    // Exclude filters for Last Updated DateTime and Created DateTime
    const filters =
      key !== "Last Updated DateTime" && key !== "Created DateTime"
        ? Array.from(uniqueValues).map((value) => ({
            text: value,
            value: value,
          }))
        : undefined;
    const renderErrorInformation =
      key.toLowerCase() === "error information"
        ? (text) => <span style={{ color: "red" }}>{text}</span>
        : undefined;

    const renderidIcon = key.toLowerCase().includes("id")
      ? (text) => (
          <>
            <FontAwesomeIcon icon={faCheck} color="green" className="mr-2" />
            {text}
          </>
        )
      : undefined;

    // Include sorters for all columns
    const sorter = (a, b) => customComparator(a, b, key);

    const renderTypeTags = key.toLowerCase().includes("type")
      ? (tag) => (
          <Tag color="blue" key={tag}>
            {tag}
          </Tag>
        )
      : undefined;

    return {
      title: key,
      dataIndex: key,
      key,
      filters: filters,
      onFilter: (value, record) =>
        record[key] ? record[key].toString().includes(value) : false,
      sorter: sorter,
      render: renderTypeTags || renderErrorInformation || renderidIcon,
    };
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const fetchData = async (pageNumber, curPageSize) => {
    if (selectedDstNode !== undefined && selectedDstNode !== null && selectedRelationName !== undefined) {
      const response = await getSelectedMenuDetails(
        token,
        curPageSize,
        (pageNumber - 1) * curPageSize,
        selectedDstNode,
        upstream,
        selectedRelationName,
        selectedNodeName,
        submenuUniqueColName,
        selectedId
      );
      setCatalogMenuData(response);
    }
  };

  function generateRelationshipPayload(row) {
    return {
      Source: upstream ? selectedDstNode : selectedNodeName,
      "Source Id": upstream
        ? row[destinationId].toString()
        : selectedId.toString().replace(/'/g, ""),
      Type: selectedRelationName,
      Destination: upstream ? selectedNodeName : selectedDstNode,
      "Destination Id": upstream
        ? selectedId.toString().replace(/'/g, "")
        : row[destinationId].toString(),
    };
  }

  const handleOkClick = () => {
    try {
      if (selectedRows?.length > 0) {
        dispatch(
          updateCatalogData(
            [],
            RELATIONSHIPS,
            token,
            selectedRows?.map(generateRelationshipPayload),
            POST,
            selCatalogName
          )
        );
        showToast();
        setAddOwner(false);
        setRefresh(!refresh);
      } else {
        toast.error(`please select atleast one row to add data`);
      }
    } catch (error) {
      toast.error("Error saving data:", error);
    } finally {
      setSelectedRows([]);
    }
  };

  const handleCancelClick = () => {
    setAddOwner(false);
    setTableData([]);
  };

  const handleNewRel = () => {
    setAddOwner(true);
  };

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    try {
      if (selectedRows?.length > 0) {
        dispatch(
          updateCatalogData(
            [],
            RELATIONSHIPS,
            token,
            selectedRows?.map(generateRelationshipPayload),
            DELETE,
            selCatalogName
          )
        );
        showToast();
        setRefresh(!refresh);
      } else {
        toast.error(`please select atleast one row to delete`);
      }
    } catch (error) {
      toast.error("Error saving data:", error);
    } finally {
      setSelectedRows([]);
      setShowModal(false);
    }
  };

  function getGraphicalSelectors() {
    function getRelationship(nodeName) {
      let nodeTypeDest = upstream ? "Source Node" : "Destination Node";
      let nodeTypeSource = upstream ? "Destination Node": "Source Node";

      return relationModalDetails
        ?.filter((item) => {
          return (selectedNodeName === item[nodeTypeSource] && nodeName === item[nodeTypeDest])
        })
        .map((item) => {
          return item["Relationship Name"];
        });
    }

    return (
      <>
        <div className="bg-gray-100 p-4 rounded-md mt-4">
          <Row gutter={24} className="m-5">
            <Col span={12}>
              <div className="flex items-center text-gray-500">
                <h2 className="mr-2 font-semibold">{DESTINATION_NODE}</h2>
                <Select
                  id="destNodes"
                  style={{ width: "50%" }}
                  placeholder="Select destination node"
                  onChange={handleDestinationNodeChange}
                  //value={selectedDstNode}
                >
                  {(upstream?sourceNodes:destinationNodes)?.map((type, index) => (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex items-center text-gray-500">
                <h1 className="mr-2 font-semibold">{RELATIONSHIP_TYPES}</h1>
                <Select
                  id="relationTypes"
                  style={{ width: "50%" }}
                  placeholder="Select relationship type"
                  onChange={handleRelationshipNameChange}
                  //value={selectedDstNode?getRelationship(selectedDstNode):null}
                >
                  {getRelationship(selectedDstNode)?.map((type, index) => (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>

          <div className="mt-2 flex items-center text-red-600 font-lg tracking-normal leading-tight">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              color="text-red-600"
              className="mr-2"
            />
            <span className="text-xs">
              Please select a destination node and a relationship type
            </span>
          </div>
        </div>
      </>
    );
  }

  function checkForValidCatalogName() {
    //If its graphical, then no need for this check!
    if (relationshipType === "Graphical") {
      return (selectedDstNode? true : false) && (selectedRelationName? true : false);
    } else {
      if (upstream) {
        return selectedDstNode ? true : false;
      } else {
        //No need of this check!
        return true;
      }
    }
  }

  function getNonGraphicalSelectors() {
    const sourceNodesMap = new Map();
    if (sourceNodesMap.size === 0) {
      relationModalDetails?.forEach((element) => {
        if (sourceNodesMap.has(element["Catalog Name"])) {
          var tempArr = sourceNodesMap.get(element["Catalog Name"]);
          tempArr.push(element["Source Node"]);
          sourceNodesMap.set(element["Catalog Name"], tempArr);
        } else {
          sourceNodesMap.set(element["Catalog Name"], [element["Source Node"]]);
        }
      });
    }

    return (
      <>
        <div className="text-gray-500 text-base m-1">
          Please select a catalog name and its associated node:
        </div>
        <Row gutter={16} className="m-2">
          <Col span={8}>
            <div className="">
              <h2 className="text-gray-500">Catalog Name:</h2>

              <Select
                id="catalogName1"
                style={{ width: "100%" }}
                className="text-gray-500"
                placeholder="Select catalog name"
                onChange={handleCatalogNameChange}
              >
                {catalogNames?.map((type, index) => (
                  <Option key={index} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <h1 className="text-gray-500">Node Name:</h1>
              <Select
                id="nodeName1"
                style={{ width: "100%" }}
                placeholder="Select node name"
                onChange={handleDestinationNodeChange}
              >
                {sourceNodesMap?.get(selCatalogName)?.map((type, index) => (
                  <Option key={index} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  const handleCancelDeleteClick = () => {
    setIsOwnerTag(false);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  function generateHeader() {
    var header = " ";
    if (relationshipType === "Graphical") {
      header = upstream ? "Upstream " : "Downstream ";
      header = header + "relationship";
    } else {
      if (upstream) {
        header = header + "Asset";
      } else {
        header = header + relationshipType;
      }
    }
    return header + EXISTING_THINGS;
  }

  return (
    <>
      <Modal open={true} footer={null} width="70%">
        <div className="relative">
          <div className="absolute top-0 right-0  -mt-5 -mr-4">
            <FontAwesomeIcon
              icon={faCircleXmark}
              color="red"
              size="2x"
              onClick={handleCancelDeleteClick}
              className="cursor-pointer"
            />
          </div>

          <div className="flex justify-between items-center p-4">
            <h1 className="mb-1 text-gray-800 text-xl font-bold tracking-normal leading-tight">
              <span className="leading-relaxed font-newOne">
                {generateHeader()}
              </span>
              <span className="font-newOne font-black"> {selectedId}</span>
            </h1>
            <div class="inline-flex rounded-md shadow-sm " role="group">
              <button
                type="button"
                onClick={handleNewRel}
                disabled={!checkForValidCatalogName()}
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-s-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                <img src={addSvg} alt="add" />
                Add
              </button>

              <button
                type="button"
                onClick={handleDeleteClick}
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-e-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              >
                <img src={deleteSvg} alt="delete" />
                Delete
              </button>
              {showModal && (
                <Modal
                  visible={showModal}
                  onCancel={handleCancelDelete}
                  footer={[
                    <Button key="cancel" onClick={handleCancelDelete}>
                      Cancel
                    </Button>,
                    <Button
                      key="delete"
                      type="primary"
                      onClick={handleConfirmDelete}
                    >
                      Delete
                    </Button>,
                  ]}
                >
                  <p className="text-center m-2 text-lg">
                    Are you sure you want to delete?
                  </p>
                </Modal>
              )}
            </div>
          </div>

          <hr />

          {relationshipType === "Graphical"
            ? getGraphicalSelectors()
            : upstream
            ? getNonGraphicalSelectors()
            : null}
          {refresh ? (
            "...loading"
          ) : (
            <>
              <CustomTablePagination
                data={tableData2}
                columns={OwnerRelcolumns}
                rowSelection={rowSelection}
                goToPrevPage={goToPrevPageMain}
                hasPrevPage={hasPrevPageMain}
                goToNextPage={goToNextPageMain}
                hasNextPage={hasNextPageMain}
                currentPage={currentPageMain}
                handlePageSizeChange={handlePageSizeChangeMain}
                pageSize={pageSizeMain}
                destinationId={destinationId}
              />
            </>
          )}

          {addOwner ? (
            <Modal open={true} footer={null} width="70%">
              <div className="flex justify-between items-center p-2">
                <h1 className="mb-1 text-gray-800 text-xl font-bold tracking-normal leading-tight mx-auto text-center">
                  <span className="">
                    {" "}
                    Please select the{" "}
                    {relationshipType === "Graphical"
                      ? "Node"
                      : relationshipType}
                    /s you wish to add
                  </span>
                </h1>

                <div className="space-x-2 flex absolute top-0 right-0 -mt-2 -mr-1 cursor-pointer">
                  <button aria-label="close modal">
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      size="2x"
                      color="red"
                      onClick={handleCancelClick}
                    />
                  </button>
                </div>
              </div>

              <hr />

              <Table
                className="mt-6"
                dataSource={
                  tableData
                    ? tableData?.map((row) => {
                        let temp = { ...row };
                        temp.key = row[destinationId];
                        return temp;
                      })
                    : []
                }
                columns={tableColumns}
                rowSelection={rowSelection}
                scroll={{ x: 900 }}
                pagination={false}
              />
              <div className="flex justify-between items-center">
                <CustomPagination
                  goToPrevPage={goToPrevPage}
                  hasPrevPage={hasPrevPage}
                  goToNextPage={goToNextPage}
                  hasNextPage={hasNextPage}
                  currentPage={currentPage}
                  handlePageSizeChange={handlePageSizeChange}
                  pageSize={pageSize}
                />
                <div
                  className={`gradient__btn__small gradient__btn__small-${
                    themeColors[themeState.sidenavColor]
                  }`}
                  onClick={handleOkClick}
                >
                  <div className="font-medium text-xs">Add</div>
                </div>
              </div>
            </Modal>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </>
  );
};

export default OwnerTagLayout;
