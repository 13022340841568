import React from "react";

const GlobalForm = ({
  handleInfoSubmit,
  data,
  setShowModal,
  formData,
  setFormData,
  loading,
}) => {
  const handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData((prevData) => ({ ...prevData, [event.target.name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleInfoSubmit(formData);
  };

  return (
    <div
      className="py-12 transition duration-150 ease-in-out z-20 absolute top-0 right-0 bottom-0 left-0 modal-container"
      id="modal"
    >
      <div
        className=" mx-auto  max-w-md w-full space-y-8 p-5 bg-white rounded-xl shadow-lg z-10"
        role="alert"
      >
        <div className="grid  gap-8 grid-cols-1">
          <div className="flex flex-col ">
            <div className="flex items-center space-x-5">
              <svg
                width="64px"
                height="64px"
                viewBox="0 0 64 64"
                data-name="Layer 1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <defs>
                    <style>
                      {`
              .cls-1 { fill: #f5dd90; }
              .cls-2 { fill: #e7ecef; }
              .cls-3 { fill: #8b8c89; }
              .cls-4 { fill: #bc6c25; }
              .cls-5 { fill: #a3cef1; }
              .cls-6 { fill: #dda15e; }
              .cls-7 { fill: #6096ba; }
              .cls-8 { fill: #274c77; }
            `}
                    </style>
                  </defs>
                  <path
                    className="cls-5"
                    d="M34.14,46c-1.82-1.85-4.35-3-7.14-3h-3v-2.29l1.58-.67c1.47-.63,2.42-2.08,2.42-3.68v-.76l2.2-.44c.46-.09,.8-.5,.8-.98v-1.45c.29,.17,.64,.27,1,.27,1.1,0,2-.9,2-2s-.9-2-2-2c-.42,0-.81,.13-1.14,.36-.15-.96-.45-1.87-.86-2.71V2h27l5,4V52h-10.71l1.71-6h-18.86Z"
                  ></path>
                  <rect
                    className="cls-8"
                    height="6"
                    width="2"
                    x="45"
                    y="14"
                  ></rect>
                  <rect
                    className="cls-8"
                    height="2"
                    width="4"
                    x="49"
                    y="22"
                  ></rect>
                  <rect
                    className="cls-8"
                    height="2"
                    width="4"
                    x="39"
                    y="22"
                  ></rect>
                  <path
                    className="cls-8"
                    d="M50.29,31.71l-4.29-4.29-4.29,4.29-1.41-1.41,5-5c.39-.39,1.02-.39,1.41,0l5,5-1.41,1.41Z"
                  ></path>
                  <path
                    className="cls-6"
                    d="M25.58,40.04l-3,1.29c-1.01,.43-2.15,.43-3.15,0l-3-1.29c-1.47-.63-2.42-2.08-2.42-3.68v-5.36c0-3.31,2.69-6,6-6h2c3.31,0,6,2.69,6,6v5.36c0,1.6-.95,3.05-2.42,3.68Z"
                  ></path>
                  <path
                    className="cls-8"
                    d="M34.14,46c-1.82-1.85-4.35-3-7.14-3h-3c0,1.66-1.34,3-3,3s-3-1.34-3-3h-3c-5.52,0-10,4.48-10,10v7H27l4-14h3.14Z"
                  ></path>
                  <path
                    className="cls-6"
                    d="M16,56h2c1.1,0,2,.9,2,2v2h-4v-4h0Z"
                  ></path>
                  <path
                    className="cls-8"
                    d="M11,32.73c-.29,.17-.64,.27-1,.27-1.1,0-2-.9-2-2s.9-2,2-2c.42,0,.81,.13,1.14,.36"
                  ></path>
                  <path
                    className="cls-8"
                    d="M31,32.73c.29,.17,.64,.27,1,.27,1.1,0,2-.9,2-2s-.9-2-2-2c-.42,0-.81,.13-1.14,.36"
                  ></path>
                  <path
                    className="cls-4"
                    d="M18,40.71l1.42,.61c1.01,.44,2.15,.44,3.16,0l1.42-.61v2.29c0,1.66-1.34,3-3,3s-3-1.34-3-3v-2.29Z"
                  ></path>
                  <polyline
                    className="cls-3"
                    points="27 60 31 46 53 46 49 60"
                  ></polyline>
                  <path
                    className="cls-8"
                    d="M27.4,28.38h-.01c-.57-.23-1.23-.38-2.06-.38-4.33,0-4.33,4-8.67,4-1.13,0-1.97-.27-2.66-.67v-.33c0-3.31,2.69-6,6-6h2c2.37,0,4.42,1.38,5.39,3.38h.01Z"
                  ></path>
                  <path
                    className="cls-2"
                    d="M28,35.6v-4.6c0-3.31-2.69-6-6-6h-2c-3.31,0-6,2.69-6,6v4h-2c-.55,0-1-.45-1-1v-3c0-5.52,4.48-10,10-10,2.76,0,5.26,1.12,7.07,2.93s2.93,4.31,2.93,7.07v3.18c0,.48-.34,.89-.8,.98l-2.2,.44Z"
                  ></path>
                  <path
                    className="cls-5"
                    d="M40,52c.55,0,1,.45,1,1s-.45,1-1,1v-2Z"
                  ></path>
                  <path
                    className="cls-7"
                    d="M21,60h-2v-2c0-.55-.45-1-1-1h-7c-.55,0-1-.45-1-1v-4c0-.27,.11-.52,.29-.71l1.29-1.29c.39-.39,1.02-.39,1.41,0h0c.39,.39,.39,1.02,0,1.41l-1,1v2.59h6c1.66,0,3,1.34,3,3v2Z"
                  ></path>
                  <rect
                    className="cls-5"
                    height="2"
                    width="50"
                    x="2"
                    y="60"
                  ></rect>
                  <path
                    className="cls-7"
                    d="M21,37h0c-.11-.54,.24-1.07,.78-1.18l8.22-1.64v-2.86c0-4.79-3.61-8.98-8.38-9.3-5.24-.35-9.62,3.81-9.62,8.98v3h2v2h-2c-1.1,0-2-.9-2-2v-2.68c0-5.72,4.24-10.74,9.94-11.27,6.54-.62,12.06,4.53,12.06,10.95v3.18c0,.95-.67,1.77-1.61,1.96l-8.22,1.64c-.54,.11-1.07-.24-1.18-.78Z"
                  ></path>
                  <rect
                    className="cls-1"
                    height="8"
                    width="8"
                    x="42"
                    y="6"
                  ></rect>
                  <circle className="cls-8" cx="36" cy="23" r="3"></circle>
                  <circle className="cls-7" cx="46" cy="23" r="3"></circle>
                  <circle className="cls-8" cx="56" cy="23" r="3"></circle>
                  <circle className="cls-8" cx="41" cy="34" r="3"></circle>
                  <circle className="cls-8" cx="51" cy="34" r="3"></circle>
                  <path
                    className="cls-1"
                    d="M36,27c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4Zm0-6c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M46,27c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4Zm0-6c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M56,27c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4Zm0-6c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M41,38c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4Zm0-6c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M51,38c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4Zm0-6c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z"
                  ></path>
                </g>
              </svg>
              <div class="block pl-2 font-semibold font-main text-xl self-start text-gray-700">
                <h2 class="leading-relaxed font-newOne">
                  {data?.title ? "Edit Data Flow" : "Create Data Flow"}
                </h2>
                {/* <p class="text-sm text-gray-500 font-normal leading-relaxed">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </p> */}
              </div>
            </div>

            <div className="mt-5">
              <div className="form">
                <form onSubmit={handleSubmit}>
                  <div class="md:flex flex-row md:space-x-4 w-full text-xs">
                    <div class="mb-3 space-y-2 w-full text-xs">
                      <label class="font-newOne text-gray-800 text-sm font-bold leading-tight tracking-normal py-2 font-main ">
                        Title{" "}
                        <sup>
                          <b>id</b>
                        </sup>
                        <abbr title="required" className="text-red-600">
                          *
                        </abbr>
                      </label>
                      <input
                        placeholder="Title"
                        class="font-newOne mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm  border border-grey-lighter rounded border"
                        required="required"
                        type="text"
                        name="Title"
                        id="Title"
                        value={formData?.Title}
                        onChange={handleChange}
                      />
                      <p className="text-red text-xs hidden">
                        Please fill out this field.
                      </p>
                    </div>
                  </div>

                  <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
                    {/* <div className="w-full flex flex-col mb-3">
                      <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal py-2 font-main ">
                        Location<abbr title="required">*</abbr>
                      </label>
                      <select
                        className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm  border border-grey-lighter rounded border"
                        required="required"
                        name="integration[city_id]"
                        id="integration_city_id"
                      >
                        <option value="">Seleted location</option>
                        <option value="">Cochin,KL</option>
                        <option value="">Mumbai,MH</option>
                        <option value="">Bangalore,KA</option>
                      </select>
                      <p className="text-sm text-red-500 hidden mt-3" id="error">
                        Please fill out this field.
                      </p>
                    </div> */}
                  </div>
                  <div class="flex-auto w-full mb-1 text-xs space-y-2">
                    <label class="font-newOne text-gray-800 text-sm font-bold leading-tight tracking-normal py-2 font-main ">
                      Description{" "}
                      <sup>
                        <b>id</b>
                      </sup>
                      <abbr title="required" className="text-red-600">
                        *
                      </abbr>
                    </label>
                    <textarea
                      required="required"
                      name="Description"
                      id="Description"
                      value={formData?.Description}
                      class="font-newOne w-full min-h-[100px] max-h-[300px] h-28 appearance-none mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm  border border-grey-lighter rounded border"
                      placeholder="Enter Description"
                      spellcheck="false"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <p class="font-newOne text-xs text-red-500 text-right my-3">
                    Required fields are marked with an asterisk{" "}
                    <abbr title="Required field">*</abbr>
                  </p>
                  {loading ? (
                    <div class="flex justify-center items-center space-x-2">
                      <div class="w-8 h-8 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
                      <span class="text-gray-700">Saving...</span>
                    </div>
                  ) : (
                    <div class="font-newOne mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
                      <button
                        className="mb-2 md:mb-0 bg-white px-5 py-2 text-xs shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
                        onClick={() => setShowModal(false)}
                      >
                        {" "}
                        Cancel{" "}
                      </button>
                      <button class="font-newOne mb-2 md:mb-0 bg-green-600 px-5 py-2 text-xs shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-500">
                        Save
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalForm;
