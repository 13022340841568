import React from "react";
import {
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Legend,
  Scatter,
  Cell,
  ResponsiveContainer,
} from "recharts";

const RelationshipStrengthHeatmap = ({graphData}) => {
  const edgeStrength = {};
  graphData.edges.forEach((edge) => {
    const { source, target } = edge;
    const edgeKey = `${source}-${target}`;
    edgeStrength[edgeKey] = (edgeStrength[edgeKey] || 0) + 1;
  });
  const scatterChartData = [];
  graphData.nodes.forEach((sourceNode) => {
    graphData.nodes.forEach((targetNode) => {
      scatterChartData.push({
        source: sourceNode.name,
        target: targetNode.name,
        value: edgeStrength[`${sourceNode.id}-${targetNode.id}`] || 0,
      });
    });
  });

  console.log(scatterChartData)

  return (
    <ResponsiveContainer  height={300}>
      <ScatterChart margin={{ top: 20, right: 40, bottom: 40, left: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="source" />
        <YAxis dataKey="target" />
        <ZAxis dataKey="value" range={[0, 100]} />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
        <Legend />
        <Scatter data={scatterChartData} shape="circle">
          {scatterChartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={`rgba(24, 144, 255, ${entry.value / 100})`}
            />
          ))}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default RelationshipStrengthHeatmap;
