// CustomTablePagination.js

import React from "react";
import { Table } from "antd";
import CustomPagination from "./CustomPagination";

const CustomTablePagination = ({
  data,
  columns,
  rowSelection,
  goToPrevPage,
  hasPrevPage,
  goToNextPage,
  hasNextPage,
  currentPage,
  handlePageSizeChange,
  pageSize,
  destinationId
}) => {
  return (
    <>
      <Table
        className="mt-5"
        dataSource={data
          ? data?.map((row) => {
              let temp = { ...row };
              temp.key = row[destinationId];
              return temp;
            })
          : []}
        columns={columns}
        rowSelection={rowSelection}
        scroll={{ x: 900 }}
        pagination={false}
      />
      <CustomPagination
        goToPrevPage={goToPrevPage}
        hasPrevPage={hasPrevPage}
        goToNextPage={goToNextPage}
        hasNextPage={hasNextPage}
        currentPage={currentPage}
        handlePageSizeChange={handlePageSizeChange}
        pageSize={pageSize}
      />
    </>
  );
};

export default CustomTablePagination;
