import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCube,
  faCircleXmark,
  faPenToSquare,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Tabs, Modal, Collapse } from "antd";
import { manageNodeDetails } from "../../config/Api";
import {
  fetchRelationshipMetaInfo,
  fetchCataLogHeader,
} from "../../container/actions/catalogActions";
import {
  getNonGraphicalRelationshipTypes,
  getGraphicalRelationshipTypes,
} from "../../globalUtils";
import OwnerTagLayout from "../../OwnerTagLayout";
import { svgIcons } from "../../utils/svgIcons";
import { KeyIcon } from "../customNodes/KeyIcon";
import { systemIcons } from "../../utils/svgIcons/systemIcons";
import { ProcessOther } from "../../utils/svgIcons/process-other";
import { DBTable } from "../../utils/svgIcons/db-table-svgrepo";
import { Container } from "../../utils/svgIcons/container-registry-svgrepo";

import { AzureActiveDirectory } from "../../assets/Azure_Public_Service_Icons/Icons/identity/10221-icon-service-Azure-Active-Directory";
import { AzureNetworkInterfaces } from "../../assets/Azure_Public_Service_Icons/Icons/networking/10080-icon-service-Network-Interfaces";
import { AzurePrivateEndpoints } from "../../assets/Azure_Public_Service_Icons/Icons/other/02579-icon-service-Private-Endpoints";
import { AzureSqlServer } from "../../assets/Azure_Public_Service_Icons/Icons/databases/10132-icon-service-SQL-Server";
import { AzureSqlServerDB } from "../../assets/Azure_Public_Service_Icons/Icons/databases/10137-icon-service-Azure-SQL-Server-Stretch-Databases";
import { AzureAppServiceCertificates } from "../../assets/Azure_Public_Service_Icons/Icons/web/00049-icon-service-App-Service-Certificates";
import { AzureMLStudio } from "../../assets/Azure_Public_Service_Icons/Icons/iot/00030-icon-service-Machine-Learning-Studio-(Classic)-Web-Services";
import { AzureWebsiteStaging } from "../../assets/Azure_Public_Service_Icons/Icons/general/10848-icon-service-Website-Staging";
import { AzureServiceStorageAccounts } from "../../assets/Azure_Public_Service_Icons/Icons/storage/10086-icon-service-Storage-Accounts";
import { AzureServiceSSHKeys } from "../../assets/Azure_Public_Service_Icons/Icons/other/00412-icon-service-SSH-Keys";
import { AzureServiceComputerVision } from "../../assets/Azure_Public_Service_Icons/Icons/ai + machine learning/00792-icon-service-Computer-Vision";

import { AzureNetworkSecurityGroups } from "../../assets/Azure_Public_Service_Icons/Icons/networking/10067-icon-service-Network-Security-Groups";
import { AzurePublicIPAddresses } from "../../assets/Azure_Public_Service_Icons/Icons/networking/10068-icon-service-Public-IP-Addresses-(Classic)";
import { AzureVirtualNetworks } from "../../assets/Azure_Public_Service_Icons/Icons/networking/10061-icon-service-Virtual-Networks";
import { AzureServiceNetworkWatcher } from "../../assets/Azure_Public_Service_Icons/Icons/monitor/10066-icon-service-Network-Watcher";
import { AzureServiceGenomicsAccounts } from "../../assets/Azure_Public_Service_Icons/Icons/ai + machine learning/10164-icon-service-Genomics-Accounts";

const TabTwoThreeContent = ({ data }) => {
  const [openAccordion, setOpenAccordion] = useState(null);
  // Check if there's at least one item in the data array
  if (!data || data.length === 0) {
    return null; // Return null if no data to display
  }

  //Find keys with either Name or Id in them
  let pattern = /id|name/i;
  let displayIndex = Object.keys(data[0]).find((key) => {
    return pattern.test(key);
  });
  return (
    <div className="py-2 justify-center">
      <div className="">
        <div className="overflow-auto shadow bg-white" id="journal-scroll">
          {data.map((item, index) => (
            <div key={index}>
              <div
                className={`bg-gray-100 mx-autorounded-sm text-gray-700 mb-0.5 h-30 ${
                  openAccordion === index
                    ? " dark:bg-gray-800 text-blue-600 dark:text-white"
                    : ""
                }`}
                data-accordion="collapse"
                data-active-classes="dark:bg-gray-800 text-blue-600 dark:text-white"
                onClick={() =>
                  setOpenAccordion(openAccordion === index ? null : index)
                }
              >
                <h2 id={`accordion-color-heading-${index}`}>
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-2 font-medium text-left text-gray-500 rounded-t-xl focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800  bg-blue-100 "
                    data-accordion-target={`#accordion-color-body-${index}`}
                    aria-expanded={openAccordion === index ? "true" : "false"}
                    aria-controls={`accordion-color-body-${index}`}
                  >
                    <span className="font-medium leading-5 text-gray-800">
                      {displayIndex === undefined ? "" : item[displayIndex]}
                    </span>
                    <svg
                      data-accordion-icon
                      className={`w-3 h-3 rotate-180 shrink-0 ${
                        openAccordion === index ? "transform rotate-0" : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                </h2>
                <div
                  id={`accordion-color-body-${index}`}
                  className={
                    openAccordion === index
                      ? "p-2  dark:bg-yellow-500 bg-opacity-25"
                      : "hidden"
                  }
                  aria-labelledby={`accordion-color-heading-${index}`}
                >
                  <div
                    key={index}
                    className="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-1 py-2 my-2"
                  >
                    <div className="flex-grow font-medium px-2">
                      {Object.entries(item).map(([key, value]) => (
                        <div key={key} className="grid grid-cols-2 gap-x-2">
                          <div className="text-left font-semibold">{key}:</div>
                          <div className="text-right">{value}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const InfoModal = ({
  selectedNode,
  setIsModalOpen,
  setIsEditModalOpen,
  token,
  isModalOpen,
  graphType,
  catalogName,
}) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [manage, setManage] = useState(false);
  const [activeTabDetails, setActiveTabDetails] = useState(null);
  const [viewManage, setViewManage] = useState(false);
  const [viewManageUpstream, setViewManageUpstream] = useState(false);
  const [relationModalDetails, setRelationModalDetails] = useState([]);
  const cataLogsHeaderState = useSelector(
    (catalogState) => catalogState.cataLogHeaderReducer
  );

  useEffect(() => {
    let nodeLabelSet = new Set(
      selectedNode?.columns?.map((item) => {
        return item.nodeLabel;
      })
    );
    nodeLabelSet?.forEach((nodeLabel) => {
      if (!cataLogsHeaderState[nodeLabel]) {
        dispatch(fetchCataLogHeader(nodeLabel, token, catalogName));
      }
    });

    dispatch(fetchRelationshipMetaInfo(1000, 0, token));
  }, []);

  const [tabList, setTabList] = useState([
    {
      id: "1",
      Icon: faCube,
      name: "System",
      tabDetailsUrl: selectedNode?.data?.moreDataUrl,
      tableName: selectedNode?.data.innerGraphRequestBody.Item.nodeName,
      relationModalDetails: null,
    },
  ]);

  const relationshipMetaState = useSelector(
    (catalogState) => catalogState.fetchRelationshipMetaInfoReducer.data
  );

  const currentFlowData = useSelector(
    (currentFlowState) => currentFlowState?.currentFlowReducer?.currentFlow
  );

  const manageTab = () => {
    setManage(true);
  };

  const getRequiredTabData = async (tab) => {
    setLoading(true);

    const endpoint = tabList.find((tabInfo) => {
      return tabInfo.id === tab;
    });
    const response = await manageNodeDetails(
      endpoint.tabDetailsUrl,
      token,
      "GET"
    );
    setData(response);

    setLoading(false);
  };

  const generateTabDetailsUrl = (relationInfo, selectedNode) => {
    var currId =
      selectedNode?.data?.innerGraphRequestBody?.Item?.nodeIdInfo.hasOwnProperty(
        "idNum"
      )
        ? selectedNode?.data.innerGraphRequestBody.Item.nodeIdInfo.idNum
        : "'" +
          selectedNode?.data.innerGraphRequestBody.Item.nodeIdInfo.idStr +
          "'";
    return (
      `/api/catalog/${relationInfo["Destination Node"]}?limit=100&skip=0` +
      `&relationInfo.name=${relationInfo["Relationship Name"]}` +
      `&relationInfo.nodeName=${relationInfo["Source Node"]}` +
      `&relationInfo.uniqueColName=${selectedNode.data.innerGraphRequestBody.Item.nodeUniqueColName}&relationInfo.id=${currId}`
    );
  };

  useEffect(() => {
    if (manage === false) {
      getRequiredTabData(activeTab);
    }
  }, [activeTab, manage]);

  useEffect(() => {
    //First Set Graphical Relation Types
    const graphicalRelationInfo = getGraphicalRelationshipTypes(
      relationshipMetaState,
      currentFlowData["Catalog Name"],
      selectedNode.data.innerGraphRequestBody.Item.nodeName
    );

    setRelationModalDetails(graphicalRelationInfo);

    //Now Set Non-Graphical Relation types
    const relationInfo = getNonGraphicalRelationshipTypes(
      relationshipMetaState,
      currentFlowData["Catalog Name"],
      selectedNode.data.innerGraphRequestBody.Item.nodeName
    );

    const tabListDetails = relationInfo?.map((item, i) => {
      const tabDetailsUrl = generateTabDetailsUrl(item, selectedNode);
      return {
        tabDetailsUrl,
        name: item["Relationship Type"],
        Icon: faUser,
        id: String(i + 2),
        tableName: item["Destination Node"],
        relationModalDetails: item,
      };
    });
    const uniqueTabListDetails = tabListDetails?.filter(
      (item, index, self) => index === self.findIndex((i) => i.id === item.id)
    );

    if (uniqueTabListDetails) {
      setTabList((prevTabList) => [
        ...prevTabList,
        ...uniqueTabListDetails?.filter(
          (item) =>
            !prevTabList?.some((prevItem) => prevItem.name === item.name)
        ),
      ]);
    }
  }, [relationshipMetaState]);

  const truncateWithEllipsis = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength - 3) + "...";
    }
    return str;
  };

  const filterData = (query) => {
    const regex = new RegExp(query, "gi");
    const filtered = data.filter((owner) => regex.test(owner.Name));
    setFilteredData(filtered);
  };

  const nodeTypeMapper = (nodeType) => {
    if (nodeType.includes("AzureActiveDirectory/b2cDirectories")) {
      return AzureActiveDirectory;
    } else if (nodeType.includes("Network/networkInterfaces")) {
      return AzureNetworkInterfaces;
    } else if (nodeType.includes("Network/privateEndpoints")) {
      return AzurePrivateEndpoints;
    } else if (
      nodeType.includes("Sql/servers" || nodeType.includes("SQL Server"))
    ) {
      return AzureSqlServer;
    } else if (nodeType.includes("Sql/servers/databases")) {
      return AzureSqlServerDB;
    } else if (nodeType.includes("Web/certificates")) {
      return AzureAppServiceCertificates;
    } else if (nodeType.includes("Web/serverFarms")) {
      return AzureMLStudio;
    } else if (nodeType.includes("Web/sites")) {
      return AzureWebsiteStaging;
    } else if (nodeType.includes("Storage/storageAccounts")) {
      return AzureServiceStorageAccounts;
    } else if (nodeType.includes("Compute/sshPublicKeys")) {
      return AzureServiceSSHKeys;
    } else if (nodeType.includes("Compute/virtualMachineScaleSets")) {
      return AzureServiceComputerVision;
    } else if (nodeType.includes("Network/networkSecurityGroups")) {
      return AzureNetworkSecurityGroups;
    } else if (nodeType.includes("Network/publicIPAddresses")) {
      return AzurePublicIPAddresses;
    } else if (nodeType.includes("Network/virtualNetworks")) {
      return AzureVirtualNetworks;
    } else if (nodeType.includes("Network/networkWatchers")) {
      return AzureServiceNetworkWatcher;
    } else if (nodeType.includes("CognitiveServices/accounts")) {
      return AzureServiceGenomicsAccounts;
    } else if (nodeType.trim().toLowerCase() === "table") {
      return DBTable;
    } else if (
      nodeType.trim().toLowerCase() === "container" ||
      nodeType.trim().toLowerCase() === "context"
    ) {
      return Container;
    } else {
      return ProcessOther;
    }
  };

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      filterData(searchQuery);
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // function findSourceAndTargetNodes(selectedNodeName, edges, nodes) {
  //   // Find the selected node based on its Name
  //   const selectedNode = nodes.find(
  //     (node) => node.data.Name === selectedNodeName
  //   );

  //   if (!selectedNode) {
  //     // If the selected node is not found, return 0 counts
  //     return { sourceSystems: [], targetSystems: [] };
  //   }

  //   const selectedNodeId = selectedNode.id;

  //   // Filter edges to find systems where the selected node is the source
  //   const sourceEdges = edges?.filter((edge) => edge.source === selectedNodeId);

  //   // Filter edges to find systems from which the selected node reads
  //   const targetEdges = edges?.filter((edge) => edge.target === selectedNodeId);

  //   // Extract the names of source systems
  //   const sourceSystems = sourceEdges?.map((edge) => {
  //     const sourceNode = nodes?.find((node) => node.id === edge.target);
  //     return sourceNode ? sourceNode.data.Name : null;
  //   });

  //   // Extract the names of target systems
  //   const targetSystems = targetEdges?.map((edge) => {
  //     const targetNode = nodes?.find((node) => node.id === edge.source);
  //     return targetNode ? targetNode.data.Name : null;
  //   });

  //   return { sourceSystems, targetSystems };
  // }

  const handleTab = (tab) => {
    setActiveTab(tab);
    const detail = tabList.find((tabInfo) => {
      return tabInfo.id === tab;
    });
    setActiveTabDetails(detail);
  };
  const handleEdit = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(true);
  };

  const handleViewManage = () => {
    setViewManage(true);
  };

  const handleViewManageUpstream = () => {
    setViewManageUpstream(true);
  };

  return (
    <>
      <Modal title={null} open={isModalOpen} footer={null} width={600}>
        <div
          className="space-y-8 p-1 bg-white rounded-xl shadow-lg z-10"
          role="alert"
        >
          <div>
            <div className="flex flex-col ">
              <div className="text-center items-center space-x-5">
                <div className="text-center block pl-2 font-semibold font-main text-xl self-start text-gray-700 relative">
                  <i className="absolute top-0 right-1 cursor-pointer">
                    <FontAwesomeIcon
                      size="sm"
                      icon={faPenToSquare}
                      onClick={handleEdit}
                    />
                  </i>
                  <i class="absolute  top-0 right-0 -mt-6 -mr-4 cursor-pointer">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faCircleXmark}
                      color="red"
                      onClick={() => setIsModalOpen(false)}
                    />
                  </i>

                  <Tooltip title={selectedNode.data?.Name}>
                    <div>
                      <h2 class="font-newOne leading-relaxed text-teal-600">
                        {truncateWithEllipsis(selectedNode.data?.Name, 24)}
                      </h2>
                    </div>
                  </Tooltip>

                  <Tooltip title={selectedNode.data?.Description}>
                    <div>
                      <p class="font-newOne text-sm text-gray-500 font-normal leading-relaxed">
                        {truncateWithEllipsis(
                          selectedNode.data?.Description,
                          25
                        )}
                      </p>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="mx-1 p-1">
                <ul className="flex space-x-4">
                  <li className="flex items-center">
                    <div className="transition duration-500 ease-in-out h-10 w-10 py-2">
                      {svgIcons.upStream}
                    </div>
                    <div className="flex-grow mr-2">
                      <span className="font-newOne font-semibold text-gray-700">
                        Upstream:
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleViewManageUpstream(selectedNode)}
                      className="font-newOne text-white bg-blue-600 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-xs px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      View/Manage
                    </button>
                  </li>
                  <li className="flex items-center">
                    <div className="transition duration-500 ease-in-out h-10 w-10 py-2">
                      {svgIcons.downStream}
                    </div>
                    <div className="flex-grow mr-2">
                      <span className="font-newOne font-semibold text-gray-700">
                        Downstream:
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleViewManage(selectedNode)}
                      className="font-newOne text-white bg-blue-600 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-xs px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      View/Manage
                    </button>
                  </li>
                </ul>
              </div>

              <div className="mt-1">
                <div className="form">
                  <div className="text-center mt-1">
                    {" "}
                    <Tabs
                      activeKey={activeTab}
                      onChange={(key) => handleTab(key)}
                      centered
                      items={tabList?.map((item, i) => {
                        return {
                          label: (
                            <span
                              className="font-newOne"
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <FontAwesomeIcon icon={item.Icon} />
                              {item.name}
                            </span>
                          ),
                          key: item.id,
                          children: (
                            <>
                              {loading ? (
                                "Loading.... "
                              ) : (
                                <>
                                  {activeTab !== "1" ? (
                                    <div className=" h-2/4 flex items-center p-1 space-x-2 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-500">
                                      <div className="flex bg-gray-100 p-2  space-x-2 rounded-lg">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6 opacity-30"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                          />
                                        </svg>
                                        <input
                                          className="bg-gray-100 outline-none h-6 rounded-lg text-sm"
                                          type="text"
                                          placeholder="name or keyword..."
                                          value={searchQuery}
                                          onChange={handleSearch}
                                        />
                                      </div>
                                      <div class="ml-2" onClick={manageTab}>
                                        <div class="bg-blue-600 py-1 px-2 text-white font-semibold rounded-lg hover:shadow-lg transition duration-3000 cursor-pointer">
                                          <span class="font-newOne text-sm">
                                            Manage
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {activeTab === "1" ? (
                                    <>
                                      <div className="py-2 justify-center">
                                        {graphType === "columnlineage" ? (
                                          <>
                                            <div
                                              className="font-newOne"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                            >
                                              <div className="flex">
                                                <div
                                                  className="table-section w-full overflow-auto"
                                                  style={{ maxHeight: "60vh" }}
                                                >
                                                  <div className="table w-full">
                                                    {/* <div style={{ backgroundColor: selectedNode?.data.schemaColor }} className="table__name">
                                                   {selectedNode?.data?.anomalyDetails && (
                                                     <Tooltip title={selectedNode?.data?.anomalyDetails}>
                                                       <span className="mr-2">
                                                         <FontAwesomeIcon
                                                           className="text-red-500 animate-glow"
                                                           size="lg"
                                                           icon={faFlag}
                                                         />
                                                       </span>
                                                     </Tooltip>
                                                   )}
                                                   {selectedNode?.data.schema
                                                     ? `${selectedNode?.data.schema}.${selectedNode?.data.Name}`
                                                     : selectedNode?.data.Name}
                                                 </div> */}

                                                    <Collapse accordion>
                                                      {selectedNode?.data?.columns?.map(
                                                        (column, index) => (
                                                          <Collapse.Panel
                                                            header={
                                                              <div className="panel-header">
                                                                <div className="header-left flex items-center">
                                                                  <div className="mr-2">
                                                                    {column?.anomalyDetails ? (
                                                                      <FontAwesomeIcon
                                                                        color="red"
                                                                        icon={
                                                                          faFlag
                                                                        }
                                                                      />
                                                                    ) : (
                                                                      nodeTypeMapper(
                                                                        column?.nodeType
                                                                      )
                                                                    )}
                                                                  </div>
                                                                  <div className="truncate">
                                                                    <span className="font-bold">
                                                                      {truncateWithEllipsis(
                                                                        column.name,
                                                                        30
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                </div>

                                                                <div className="header-right">
                                                                  {column?.description ??
                                                                    truncateWithEllipsis(
                                                                      column.description,
                                                                      25
                                                                    )}
                                                                </div>
                                                              </div>
                                                            }
                                                            key={index}
                                                          >
                                                            <div className="border-4">
                                                              <div className="details bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 p-4 rounded-lg shadow-lg">
                                                                <div className="flex flex-col space-y-2">
                                                                  {cataLogsHeaderState?.[
                                                                    column
                                                                      .nodeLabel
                                                                  ]?.data
                                                                    ?.filter(
                                                                      (
                                                                        element
                                                                      ) =>
                                                                        Object.keys(
                                                                          column?.data
                                                                        ).includes(
                                                                          element[
                                                                            "Property Name"
                                                                          ]
                                                                        )
                                                                    )
                                                                    .map(
                                                                      (
                                                                        filteredElement,
                                                                        index
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            index
                                                                          }
                                                                          className="flex items-center div-with-border"
                                                                        >
                                                                          <p>
                                                                            <strong>
                                                                              {
                                                                                filteredElement[
                                                                                  "Display Name"
                                                                                ]
                                                                              }
                                                                            </strong>
                                                                            :{" "}
                                                                            {
                                                                              column
                                                                                ?.data?.[
                                                                                filteredElement[
                                                                                  "Property Name"
                                                                                ]
                                                                              ]
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </Collapse.Panel>
                                                        )
                                                      )}
                                                    </Collapse>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <div>
                                            <div
                                              className="w-full h-2/3 overflow-auto shadow bg-white"
                                              id="journal-scroll"
                                            >
                                              <table className="w-full">
                                                <thead>
                                                  <tr class="font-newOne bg-blue-500 bg-opacity-25">
                                                    <th class="pl-5 pr-3 py-2 whitespace-no-wrap text-left">
                                                      Name
                                                    </th>
                                                    <th className="px-2 py-2 whitespace-no-wrap">
                                                      Value
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody className="">
                                                  {filteredData.map(
                                                    (item, index) =>
                                                      Object.entries(item).map(
                                                        ([key, value]) => (
                                                          <tr
                                                            key={index + key} // You should ensure a unique key for each row
                                                            className="relative transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default bg-blue-500 bg-opacity-25"
                                                          >
                                                            <td className="pl-5 pr-3 whitespace-no-wrap text-left">
                                                              <div>{key}</div>
                                                            </td>
                                                            <td className="px-2 py-2 whitespace-no-wrap">
                                                              <div className="leading-5 text-gray-900">
                                                                <div
                                                                  style={{
                                                                    minHeight:
                                                                      "0.5rem",
                                                                  }}
                                                                >
                                                                  {value ?? ""}
                                                                </div>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <TabTwoThreeContent data={filteredData} />
                                  )}
                                </>
                              )}
                            </>
                          ),
                        };
                      })}
                    />
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {manage ? (
        <OwnerTagLayout
          token={token}
          catalogName={currentFlowData["Catalog Name"]}
          selectedNodeName={
            selectedNode?.data?.innerGraphRequestBody.Item.nodeName
          }
          upstream={false}
          setIsOwnerTag={setManage}
          selectedId={
            selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.hasOwnProperty(
              "idNum"
            )
              ? selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.idNum.toString()
              : "'" +
                selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.idStr +
                "'"
          }
          relationModalDetails={[activeTabDetails?.relationModalDetails]}
          submenuUniqueColName={
            selectedNode?.data?.innerGraphRequestBody.Item.nodeUniqueColName
          }
          relationshipType={activeTabDetails?.name}
        />
      ) : null}
      {viewManage ? (
        <OwnerTagLayout
          token={token}
          catalogName={currentFlowData["Catalog Name"]}
          selectedNodeName={
            selectedNode?.data?.innerGraphRequestBody.Item.nodeName
          }
          upstream={false}
          setIsOwnerTag={setViewManage}
          selectedId={
            selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.hasOwnProperty(
              "idNum"
            )
              ? selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.idNum.toString()
              : "'" +
                selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.idStr +
                "'"
          }
          relationModalDetails={relationModalDetails}
          submenuUniqueColName={
            selectedNode?.data?.innerGraphRequestBody.Item.nodeUniqueColName
          }
          relationshipType={"Graphical"}
        />
      ) : null}
      {viewManageUpstream ? (
        <OwnerTagLayout
          token={token}
          catalogName={currentFlowData["Catalog Name"]}
          selectedNodeName={
            selectedNode?.data?.innerGraphRequestBody.Item.nodeName
          }
          upstream={true}
          setIsOwnerTag={setViewManageUpstream}
          selectedId={
            selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.hasOwnProperty(
              "idNum"
            )
              ? selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.idNum.toString()
              : "'" +
                selectedNode.data.innerGraphRequestBody.Item.nodeIdInfo.idStr +
                "'"
          }
          relationModalDetails={relationModalDetails}
          submenuUniqueColName={
            selectedNode?.data?.innerGraphRequestBody.Item.nodeUniqueColName
          }
          relationshipType={"Graphical"}
        />
      ) : null}
    </>
  );
};

export default InfoModal;
