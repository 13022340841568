import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import {
  Button,
  Drawer,
  Switch,
  Typography,
  Menu,
  Dropdown,
  Tooltip,
  Modal,
} from "antd";
import styled from "styled-components";
import { svgIcons } from "../../utils/svgIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faSignOut,
  faUserCircle,
  faDatabase,
  faCloud,
  faCircleXmark,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import { actionIcons } from "../../utils/svgIcons/actionIcons";

import ImportWizard from "../modalForm/ImportWizardModal";
import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { updateTheme } from "../../container/actions/themeActions";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "../chatBot/config";
import ActionProvider from "../chatBot/ActionProvider";
import MessageParser from "../chatBot/MessageParser";

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #1890ff !important;
  }
  .ant-btn-success {
    background-color: #52c41a;
  }
  .ant-btn-yellow {
    background-color: #faaf47;
  }
  .ant-btn-danger {
    background-color: #d9363e;
  }
  .ant-btn-black {
    background-color: #00309b;
    color: #fff;
    border: 0px;
    border-radius: 5px;
  }
  .ant-switch-active {
    background-color: #1890ff;
  }
  .ant-btn-light-purple {
    background-color: #bd9ac8;
  }
  .ant-btn-darker-blue {
    background-color: #024d84;
  }
  .ant-btn-medium-blue {
    background-color: #6d87b5;
  }
`;

const { Title, Text } = Typography;

const NavBar = ({ title, description, icons, token }) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const themeState = useSelector((themeState) => themeState.themeReducer);
  const dispatch = useDispatch();
  const [fixed, setFixed] = useState(false);
  const [sidenavType, setSidenavType] = useState("white");

  const handleSidenavType = (type) => {
    const data = { ...themeState, sidenavType: type };
    dispatch(updateTheme(data));
  };

  const handleSidenavColor = (color) => {
    const data = { ...themeState, sidenavColor: color };
    dispatch(updateTheme(data));
  };

  const handleFixedNavbar = (type) => setFixed(type);
  const jwtTokenState = useSelector((jwtState) => jwtState.jwtReducer);
  const pathname = location.pathname.replace("/", "");
  const showDrawer = () => setVisible(!visible);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showChatBot, setShowChatBot] = useState(false);
  const { instance } = useMsal();
  const userManagementOptions = [
    {
      label: "Sign Out",
      key: "1",
      icon: <FontAwesomeIcon className="fa-light" icon={faSignOut} />,
    },
    {
      label: "Settings",
      key: "2",
      icon: <FontAwesomeIcon className="fa" icon={faGear} />,
    },
  ];

  const handleMenuClick = (e) => {
    const key = e.key;
    switch (key) {
      case "1":
        instance.logoutRedirect({
          account: instance.getActiveAccount(),
          onRedirectNavigate: () => !BrowserUtils.isInIframe(),
        });
        break;
      case "2":
        showDrawer();
        break;
      default:
        break;
    }
  };

  const handleMenu2Click = (e) => {
    const key = e.key;
    setSelectedMenu(key);
    setIsModalOpen(true);
  };

  const options = [
    {
      label: "Import DB",
      key: "1",
      icon: <FontAwesomeIcon className="fa" icon={faDatabase} />,
    },
    {
      label: "Azure Resources",
      key: "2",
      icon: <FontAwesomeIcon className="fa" icon={faCloud} />,
    },
  ];

  const menu = (
    <Menu onClick={handleMenuClick}>
      {userManagementOptions?.map((option) => (
        <Menu.Item key={option?.key}>
          <div className="spans-container">
            <span>{option?.icon}</span>
            <span className="gap-between-spans">{option?.label}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu2 = (
    <Menu onClick={handleMenu2Click}>
      {options?.map((option) => (
        <Menu.Item key={option?.key}>
          <div className="spans-container">
            <span>{option?.icon}</span>
            <span className="gap-between-spans">{option?.label}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <nav className="bg-white w-full flex relative mx-auto px-2 h-14">
        {/* First Half */}
        <div className="flex items-center justify-center mr-1 flex-grow">
          <div className="flex flex-col items-center">
            <div>
              <span className="text-xl font-medium font-newOne">{title}</span>
            </div>
            <span className="text-md font-normal font-newOne">
              {description}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-end flex-1">
          <div className="flex justify-end items-center relative">
            <div className="block">
              <div className="inline relative flex space-x-4">
                {["dashboard", "datacatalog"].includes(pathname) && (
                  <Dropdown overlay={menu2} placement="bottom">
                    <button
                      type="button"
                      className="bg-gray-100 rounded-lg p-2"
                    >
                      {actionIcons.importData}
                    </button>
                  </Dropdown>
                )}
                {icons?.map((icon, index) => (
                  <Tooltip
                    title={icon.name}
                    color="rgb(0, 48, 155)"
                    overlayStyle={{ color: "black" }}
                    key={index}
                  >
                    <div
                      className={`bg-gray-100 rounded-lg p-2 ${
                        icon?.isDisabled
                          ? "pointer-events-none bg-gray-300"
                          : ""
                      }`}
                      
                      onClick={icon.onClick}
                      tabIndex={icon?.isDisabled ? "-1" : "0"}
                    >
                      {icon.icon}
                    </div>
                  </Tooltip>
                ))}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowChatBot(!showChatBot)}
                  className={`bg-gray-100 rounded-lg p-2`}
                >
                  <FontAwesomeIcon
                    icon={faRobot}
                    className="text-gray-600 mx-1 my-1"
                    size={"1x"}
                
                  />
                </div>

                <Dropdown overlay={menu} placement="bottom">
                  <button type="button" className="flex items-center">
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      className="text-gray-600 mx-1 my-1"
                      size={"2x"}
                    />
                  </button>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {isModalOpen && (
        <ImportWizard
          selectedMenu={selectedMenu}
          setIsModalOpen={setIsModalOpen}
          token={jwtTokenState.token}
          isModalOpen={isModalOpen}
        />
      )}

      {showChatBot && (
        <Modal title={null} open={showChatBot} footer={null} width={500}>
          <Chatbot
            config={config}
            style={{width: '400px'}}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
          <div className="flex absolute top-[-7px] right-[-10px]  space-x-3">
          <FontAwesomeIcon
            icon={faCircleXmark}
            size="2x"
            color="red"
            onClick={() => setShowChatBot(false)}
          />{" "}
        </div>
        </Modal>
      )}

      <Drawer
        className="settings-drawer"
        mask={true}
        width={360}
        onClose={showDrawer}
        placement={placement}
        open={visible}
        title={
          <div className="header-top">
            <Title level={4}>
              Configurator
              <Text className="subtitle">See our dashboard options.</Text>
            </Title>
          </div>
        }
      >
        <div layout="vertical">
          <div className="sidebar-color">
            <Title level={5}>Sidebar Color</Title>
            <div className="theme-color mb-2">
              <ButtonContainer>
                <Button
                  type="darker-blue"
                  onClick={() => handleSidenavColor("#024d84")}
                >
                  1
                </Button>
                <Button
                  type="black"
                  onClick={() => handleSidenavColor("#00309b")}
                >
                  1
                </Button>

                <Button
                  type="medium-blue"
                  onClick={() => handleSidenavColor("#6d87b5")}
                >
                  1
                </Button>
              </ButtonContainer>
            </div>

            <div className="sidebarnav-color mb-2">
              <Title level={5}>Sidenav Type</Title>
              <Text>Choose between 2 different sidenav types.</Text>
              <ButtonContainer className="trans">
                <Button
                  type={sidenavType === "transparent" ? "primary" : "white"}
                  onClick={() => {
                    handleSidenavType("transparent");
                    setSidenavType("transparent");
                  }}
                >
                  TRANSPARENT
                </Button>
                <Button
                  type={sidenavType === "white" ? "primary" : "white"}
                  onClick={() => {
                    handleSidenavType("#fff");
                    setSidenavType("white");
                  }}
                >
                  WHITE
                </Button>
              </ButtonContainer>
            </div>
            <div className="fixed-nav mb-2">
              <Title level={5}>Navbar Fixed </Title>
              <Switch onChange={(e) => handleFixedNavbar(e)} />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default NavBar;
