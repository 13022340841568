import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from "./App";
import '@mui/material';

// MSAL imports
import { msalConfig } from "./authConfig.js";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import store from "./store";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS
      ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root");
  const root = createRoot(container); 

  // Define your theme here
  const theme = createTheme({
    // Customize your theme options
    palette: {
      primary: {
        main: '#1976D2',
      },
      secondary: {
        main: '#f50057',
      },
    },
  });

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        {/* Wrap your entire component tree with ThemeProvider */}
        <ThemeProvider theme={theme}>
          <App pca={msalInstance} />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
});
