import React, { useState, useRef, useEffect } from "react";
import { Menu, Tooltip, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import withRouter from "../../utils/WithRouter";
import styled from "styled-components";
import { svgIcons } from "../../utils/svgIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/Anansi_Logo.png";

const StyledButton = styled.div`
  .ant-btn {
    background-color: #fff;
    border-color: #f0f0f0;
    color: #141414;
    box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
  }
`;

function Sidenav({ color, cataLogsState }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [openKeys, setOpenKeys] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Set sidebarOpen to true by default
  const { SubMenu } = Menu;
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  function truncateWithEllipsis(str, maxLength, title) {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength - 3) + "...";
    }
    return str;
  }

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;

      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, []);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, []);

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const openDocumentation = () => {
    // Open the documentation URL in a new browser window
    window.open("https://www.anansihub.com/docs/", "_blank");
  };

  const handleSubMenuClick = (key) => {
    setOpenKeys((prevOpenKeys) => {
      // If the clicked submenu is already open, close it
      if (prevOpenKeys.includes(key)) {
        return [];
      } else {
        // Otherwise, open the clicked submenu and close other submenus
        return [key];
      }
    });
  };

  const navLinksJsx = (data) => {
    const navLinks = Array.from(
      new Set(data?.map((catalog) => catalog["Catalog Name"]))
    )?.map((catalogName, index) => {
      const catalog = cataLogsState?.data?.find(
        (catalog) => catalog["Catalog Name"] === catalogName
      );
      if (catalog) {
        const submenus = cataLogsState?.data?.filter(
          (submenu) => submenu["Catalog Name"] === catalogName
        );

        return (
          <React.Fragment key={`catalog-${index}`}>
            <SubMenu
              key={`${catalogName}`}
              icon={
                <span
                  className="icon"
                  style={{
                    background: openKeys?.includes(catalogName) ? color : "",
                  }}
                >
                  {svgIcons[catalogName] ?? svgIcons.backupIcon}
                </span>
              }
              title={
                <span className="sub-label font-newOne">
                  {catalog["Catalog Name"]}
                </span>
              }
              onTitleClick={() => handleSubMenuClick(catalogName)}
            >
              {submenus.map((submenu, subIndex) => (
                <Menu.Item
                  key={`sub-${catalogName}-${submenu["Node Name"]}-${subIndex}`}
                  icon={
                    <span
                      className="icon"
                      style={{
                        background: new RegExp(
                          `^.*?/${submenu["Node Name"]}$`
                        ).test(page)
                          ? color
                          : "",
                      }}
                    >
                      {svgIcons[submenu["Node Name"]] ?? svgIcons.backupIcon}
                    </span>
                  }
                >
                  <NavLink
                    to={`/${catalog["Catalog Name"]
                      .replace(/\W+/g, "")
                      .toLowerCase()}/${submenu["Node Name"].replace(
                      /\W+/g,
                      "-"
                    )}`}
                  >
                    <Tooltip
                      title={
                        submenu["Display Name"].length > 14
                          ? submenu["Display Name"]
                          : ""
                      }
                    >
                      <span className="display-name font-newOne">
                        {truncateWithEllipsis(submenu["Display Name"], 20)}
                      </span>
                    </Tooltip>
                  </NavLink>
                </Menu.Item>
              ))}
            </SubMenu>
          </React.Fragment>
        );
      }
      return null;
    });
    return navLinks;
  };

  return (
    <>
      <div
        className={`fixed bg-slate-900 bg-opacity-30 z-40 lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>
        {/* <div
          className="pt-3 hidden lg:inline-flex justify-end mt-auto"
          style={{ marginLeft: "-1rem", marginTop: "-1rem" }}
        >
          <div className="inline-flex">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              {sidebarExpanded ? (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="w-5 h-5 text-slate-800"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="w-5 h-5 text-slate-800"
                />
              )}
            </button>
          </div>
        </div> */}
        <div
          id="sidebar"
          ref={sidebar}
          className={`flex shadow-right p-2 flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 overflow-y-scroll lg:overflow-y-auto no-scrollbar lg:sidebar-expanded:!w-56 shrink-0 transition-all duration-200 ease-in-out ${
            sidebarOpen ? "translate-x-0" : "-translate-x-64"
          }`}
        >
          {/* Links */}

            <>
              <div className="m-1 p-2">
                <div className="flex items-center">
                  <div className="flex h-14">
                    <img src={logo} alt="Anansi"></img>
                  </div>
                  <span className={`ml-2 text-2xl font-bold font-newOne`}>
                    Anansi
                  </span>
                </div>
              </div>

              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["dashboard"]}
                openKeys={openKeys}
              >
                <Menu.Item key="1">
                  <NavLink to="/dashboard">
                    <span
                      className="icon"
                      style={{
                        background:
                          ["dashboard"].includes(page) ||
                          page.match(/^graph\/.*$/)
                            ? color
                            : "",
                      }}
                    >
                      {dashboard}
                    </span>
                    <span className="font-newOne">Dashboard</span>
                  </NavLink>
                </Menu.Item>

                {navLinksJsx(cataLogsState?.data)}
              </Menu>
              <div className="aside-footer">
                <div
                  className="footer-box"
                  style={{
                    background: color,
                  }}
                >
                  <span className="icon" style={{ color }}>
                    {dashboard}
                  </span>
                  <h6 className="font-newOne">Need Help?</h6>
                  <p className="font-newOne">Please check our docs</p>

                  <StyledButton>
                    <Button className="font-newOne" onClick={openDocumentation}>
                      DOCUMENTATION
                    </Button>
                  </StyledButton>
                </div>
              </div>
            </>
        </div>
    
    </>
  );
}

export default withRouter(Sidenav);
