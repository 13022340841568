import Papa from "papaparse";

export const handleExportJsonClick = (apiData, submenuName) => {
  const jsonData = apiData;
  for (const key in jsonData) {
    if (Array.isArray(jsonData[key])) {
      jsonData[key]?.forEach((item) => {
        if (item.exist !== undefined) {
          delete item.exist;
          delete item.id;
        }
      });
    }
  }
  const resultData = JSON.stringify(jsonData[submenuName]);
  const blob = new Blob([resultData], {
    type: "application/json;charset=utf-8",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${submenuName}.json`;
  link.click();
};

export const handleImportJsonClick = (apiData, submenuName, setApiData) => {
  const fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.accept = "application/json";
  fileInput.onchange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const jsonData = JSON.parse(e.target.result);
      const updatedData = {
        ...apiData,
        [submenuName]: [...jsonData, ...(apiData[submenuName] || [])],
      };
      setApiData(updatedData);
    };
    fileReader.readAsText(file);
  };
  fileInput.click();
};

export const handleExportClick = (columns, apiData, submenuName) => {
  const headers = columns
    ?.filter((column) => column.header !== "ID" && column.header !== "Actions")
    ?.map((column) => column.header);

  const rawData = apiData[submenuName] || [];

  if (rawData?.length === 0) {
    return; // No data to export
  }

  // Remove "Actions" column from each data row
  const dataWithoutActions = rawData?.map((row) => {
    const { ...rowDataWithoutActions } = row;
    return rowDataWithoutActions;
  });

  const csvData = [
    headers,
    ...dataWithoutActions?.map((row) => headers?.map((header) => row[header])),
  ]; // Include headers and data rows

  const csv = Papa.unparse(csvData);
  const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
  const link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", `${submenuName}.csv`);
  link.click();
};
