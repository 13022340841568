import {
  GET_MASTER_LIST_SUCCESS,
  GET_MASTER_LIST_FAILURE,
  GET_MASTER_LIST_FETCHING,
  GET_JWT_TOKEN_FETCH,
  GET_JWT_TOKEN_SUCCESS,
  GET_JWT_TOKEN_FAILURE,
  GET_CATALOG_FAILURE,
  GET_CATALOG_FETCH,
  GET_CATALOG_SUCCESS,
  UPDATE_CATALOG_DATA_FETCH,
  UPDATE_CATALOG_DATA_SUCCESS,
  UPDATE_CATALOG_FAILURE,
  GET_CATALOG_HEADERS,
  GET_CATALOG_HEADERS_FAILURE,
  GET_CATALOG_HEADERS_SUCCESS,
  GET_RELATIONSHIP_METAINFO_FETCH,
  GET_RELATIONSHIP_METAINFO_SUCCESS,
  GET_RELATIONSHIP_METAINFO_FAILURE,
} from "../actionTypes";
import { toast } from "react-toastify";
import {
  getMasterList,
  getCatalogData,
  updateTableDetails,
  getTableHeaders,
  getRelationshipMetaInfo,
} from "../../config/Api";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const fetchJWTTokenRequest = () => ({
  type: GET_JWT_TOKEN_FETCH,
});

export const fetchJWTTokenSuccess = (data) => ({
  type: GET_JWT_TOKEN_SUCCESS,
  payload: data,
});

export const fetchJWTTokenFailure = (error) => ({
  type: GET_JWT_TOKEN_FAILURE,
  payload: error,
});

export const fetchJWTToken = async (instance) => {
  const request = {
    scopes: [
      "https://anansihub.onmicrosoft.com/demoapi/read",
      "https://anansihub.onmicrosoft.com/demoapi/write",
    ],
  };

  try {
    const tokenResponse = await instance.acquireTokenSilent(request);
    // If token is acquired successfully, return the accessToken
    return tokenResponse.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // When the silent token acquisition fails, fallback to interactive method
      try {
        const tokenResponse = await instance.acquireTokenPopup(request);
        return tokenResponse.accessToken;
      } catch (innerError) {
        // Handle or throw the inner error from acquireTokenPopup
        throw new Error(
          `Error fetching token interactively: ${innerError.message}`
        );
      }
    } else {
      // Handle or throw other errors
      throw new Error(`Error fetching token from the API: ${error.message}`);
    }
  }
};

export const fetchMasterListRequest = () => ({
  type: GET_MASTER_LIST_FETCHING,
});

export const fetchMasterListSuccess = (data) => ({
  type: GET_MASTER_LIST_SUCCESS,
  payload: data,
});

export const fetchMasterListFailure = (error) => ({
  type: GET_MASTER_LIST_FAILURE,
  payload: error,
});

export const fetchMasterList = (limit, skip, token) => {
  return async (dispatch) => {
    dispatch(fetchMasterListRequest());
    try {
      const response = await getMasterList(limit, skip, token);
      if (Array.isArray(response)) {
        dispatch(fetchMasterListSuccess(response));
      } else {
        dispatch(fetchMasterListFailure(response));
        toast.error(`${response.message}`);
      }
    } catch (error) {
      dispatch(fetchMasterListFailure(error.message));
      toast.error(`${error.message}`);
    }
  };
};

export const fetchCataLogRequest = (submenuName) => ({
  type: GET_CATALOG_FETCH,
  submenuName,
});

export const fetchCataLogSuccess = (data, submenuName) => ({
  type: GET_CATALOG_SUCCESS,
  payload: data,
  submenuName,
});

export const fetchCataLogFailure = (error, submenuName) => ({
  type: GET_CATALOG_FAILURE,
  payload: error,
  submenuName,
});

export const fetchCataLog = (
  limit,
  skip,
  token,
  submenuName,
  catalogName,
  filters,
  lastUpdateDateTime
) => {
  return async (dispatch) => {
    dispatch(fetchCataLogRequest(submenuName));
    try {
      const response = await getCatalogData(
        limit,
        skip,
        token,
        submenuName,
        catalogName,
        filters,
        lastUpdateDateTime
      );
      dispatch(fetchCataLogSuccess(response, submenuName));
    } catch (error) {
      dispatch(fetchCataLogFailure(error.message, submenuName));
      toast.error(`Error fetching data from the API ${error.message}`);
    }
  };
};

export const updateCataLogDataRequest = (submenuName) => ({
  type: UPDATE_CATALOG_DATA_FETCH,
  submenuName,
});

export const handleCataLogData = (submenuName, data) => ({
  type: UPDATE_CATALOG_DATA_SUCCESS,
  submenuName,
  payload: data,
});

export const upDateCataLogFailure = (error, submenuName) => ({
  type: UPDATE_CATALOG_FAILURE,
  payload: error,
  submenuName,
});

export const updateCatalogData = (
  updatedData,
  submenuName,
  token,
  changedData,
  method,
  catalogName,
  avoidError = false
) => {
  return async (dispatch) => {
    dispatch(updateCataLogDataRequest(submenuName));
    try {
      const response = await updateTableDetails(
        submenuName,
        changedData,
        token,
        method,
        catalogName
      );
      if (response?.code) {
        !avoidError &&
          dispatch(upDateCataLogFailure(response?.message, submenuName));
        !avoidError && toast.error(`Error updating data ${response.message}`);

        if (!avoidError) {
          // Only stop the process if avoidError is not set to true
          return;
        }
      } else {
        dispatch(handleCataLogData(submenuName, updatedData));
      }
    } catch (error) {
      !avoidError && dispatch(upDateCataLogFailure(error.message, submenuName));
      !avoidError && toast.error(`Error updating data ${error.message}`);

      if (!avoidError) {
        // Only stop the process if avoidError is not set to true
        return;
      }
    }
  };
};

export const fetchCataLogHeaderRequest = (submenuName) => ({
  type: GET_CATALOG_HEADERS,
  submenuName,
});

export const fetchCataLogHeaderSuccess = (data, submenuName) => ({
  type: GET_CATALOG_HEADERS_SUCCESS,
  payload: data,
  submenuName,
});

export const fetchCataLogHeaderFailure = (error, submenuName) => ({
  type: GET_CATALOG_HEADERS_FAILURE,
  payload: error,
  submenuName,
});

export const fetchCataLogHeader = (submenuName, token, catalogName) => {
  return async (dispatch) => {
    dispatch(fetchCataLogHeaderRequest(submenuName, catalogName));
    try {
      const response = await getTableHeaders(submenuName, token);
      dispatch(fetchCataLogHeaderSuccess(response, submenuName));
    } catch (error) {
      dispatch(fetchCataLogHeaderFailure(error.message, submenuName));
      toast.error(`Error fetching data from the API ${error.message}`);
    }
  };
};

export const fetchRelationshipMetaInfoRequest = () => ({
  type: GET_RELATIONSHIP_METAINFO_FETCH,
});

export const fetchRelationshipMetaInfoSuccess = (data) => ({
  type: GET_RELATIONSHIP_METAINFO_SUCCESS,
  payload: data,
});

export const fetchRelationshipMetaInfoFailure = (error) => ({
  type: GET_RELATIONSHIP_METAINFO_FAILURE,
  payload: error,
});

export const fetchRelationshipMetaInfo = (limit, skip, token) => {
  return async (dispatch) => {
    dispatch(fetchRelationshipMetaInfoRequest());
    try {
      const response = await getRelationshipMetaInfo(limit, skip, token);
      dispatch(fetchRelationshipMetaInfoSuccess(response));
    } catch (error) {
      dispatch(fetchRelationshipMetaInfoFailure(error.message));
      toast.error(`Error fetching data from the API ${error.message}`);
    }
  };
};
