import { useState, useEffect } from "react";
import { Handle, Position, useReactFlow, useStore } from "reactflow";
import { KeyIcon } from "./KeyIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";
import { TableNodeModal } from "../modalForm/TableNodeModal";

import "@reactflow/node-resizer/dist/style.css";

export const TableNode = ({ data, handleTableHeaderClick }) => {
  const [selectedColumn, setSelectedColumn] = useState("");
  const [descriptionOnHoverActive, setDescriptionOnHoverActive] =
    useState(false);
  const [columnsExpanded, setColumnsExpanded] = useState(false);
  const { getNodes } = useReactFlow();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === "MetaLeft") {
        setDescriptionOnHoverActive(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.code === "MetaLeft") {
        setDescriptionOnHoverActive(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown, false);
    document.addEventListener("keyup", handleKeyUp, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
      document.removeEventListener("keyup", handleKeyUp, false);
    };
  }, []);

  const handleNodeClick = (e) => {
    if (!e.target.closest(".expand-collapse-button")) {
      const nodes = getNodes();
    }
  };

  return (
    <>
      <div className="table w-44" onClick={handleNodeClick}>
        <div
          style={{ backgroundColor: data.schemaColor }}
          className="table__name"
          // onMouseEnter={() => {
          //   if (!showInfo) {
          //     setShowInfo(true);
          //   }
          // }}

          onClick={(e) => {
            e.stopPropagation();
            handleTableHeaderClick(e, data);
          }}
        >
          {data?.anomalyDetails ? (
            <Tooltip title={data?.anomalyDetails}>
              <span className="mr-2">
                <FontAwesomeIcon
                  className="text-red-500 animate-glow"
                  size="lg"
                  icon={faFlag}
                />
              </span>
            </Tooltip>
          ) : null}{" "}
          {data.schema ? `${data.schema}.${data.Name}` : data.Name}
        </div>

        <div className="table__columns">
          {data?.columns?.slice(0, 5).map((column, index) => (
            <div
              key={column.name}
              className={
                selectedColumn === column.name
                  ? "column-name column-name--selected"
                  : "column-name"
              }
              onMouseEnter={() => {
                if (descriptionOnHoverActive) {
                  setSelectedColumn(column.name);
                }
              }}
              onMouseLeave={() => setSelectedColumn("")}
            >
              {column.handleType?.map((handleType, handleIndex) => {
                return (
                  <Handle
                    key={handleIndex}
                    type={handleType}
                    position={Position.Right} // You can adjust this as needed
                    id={`${column.name}-right`}
                    className={
                      handleType === "source"
                        ? "right-handle source-handle"
                        : "right-handle target-handle"
                    }
                  />
                );
              })}
              {column.handleType?.map((handleType, handleIndex) => (
                <Handle
                  key={handleIndex}
                  type={handleType}
                  position={Position.Left} // You can adjust this as needed
                  id={`${column.name}-left`}
                  className={
                    handleType === "source"
                      ? "left-handle source-handle"
                      : "left-handle target-handle"
                  }
                />
              ))}

              <div className="column-name__inner">
                <div className="column-name__icon-name">
                  { column?.anomalyDetails ? 
                    <FontAwesomeIcon
                      color="red"
                      icon={faFlag}
                    /> : null}
                    {column.key && <KeyIcon />}
                  {column.name}
                </div>
                <div className="column-name__type">{column.nodeType}</div>
              </div>
            </div>
          ))}
          {data?.columns?.length > 5 && (
            <div
              className="expand-collapse-button"
              onClick={(e) => {
                e.stopPropagation();
                setColumnsExpanded(!columnsExpanded);
              }}
            >
              <div
                className="bg-grey-lighter p-2 flex items-center justify-between transition hover:bg-grey-light"
                onClick={(e) => {
                  e.stopPropagation();
                  setColumnsExpanded(!columnsExpanded);
                }}
              >
                {columnsExpanded ? (
                  <>
                    <FontAwesomeIcon icon={faAngleUp} color="#00309b" />{" "}
                    <div className="font-mono text-sm text-teal-500">
                      Show Less ...
                    </div>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faAngleDown} color="#00309b" />
                    <div className="font-mono text-sm text-teal-500">
                      Show More...
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <TableNodeModal
        setShowMoreClick={setColumnsExpanded}
        showMore={columnsExpanded}
        data={data}
      />
    </>
  );
};
