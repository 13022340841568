import React from "react";
import { useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";
import { themeColors } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "../../assets/styles/main.css";

const MenuButtonComponent = ({
  handleMenuClick,
  name,
  options,
  isIcon = false,
  placement
}) => {
  const themeState = useSelector((themeState) => themeState.themeReducer);
  const handleMenuClickUpdate = (e) => {
    handleMenuClick(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClickUpdate} >
      {options?.map((option) => (
        <Menu.Item key={option?.key} className="shadow-lg border border-gray-300 rounded-md p-2">
          <div className="spans-container font-newOne">
            <span>{option?.icon}</span>
            <span className="gap-between-spans">{option?.label}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  // Conditionally set className based on name
  let buttonClassName = "";
  if (!isIcon) {
    buttonClassName = `gradient__btn__small gradient__btn__small-${
      themeColors[themeState.sidenavColor]  
    }`;
    // Disable className if name is "create graph" or "manage/view"
    if (name === "Create Graph" || name === "Manage/View") {
      buttonClassName = "";
    }
  }

  return (
    <Dropdown overlay={menu} placement={placement} className={buttonClassName}>
      {isIcon ? (
        <FontAwesomeIcon className="fa" icon={faBars} size="2x" />
      ) : (
        (name === "Create Graph" || name === "Manage/View") ?
          <span
          className="shadow-lg border border-gray-300 rounded-md p-2 menu-button"
        >
          {name}
        </span>
        :
        <span
          style={{ cursor: "pointer", fontSize: "smaller", fontWeight: 600 }}
          className="shadow-lg border border-gray-300 rounded-md p-2"
        >
          {name}
        </span>
      )}
    </Dropdown>
  );
};

export default MenuButtonComponent;
