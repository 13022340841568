import React, { memo } from "react";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faServer,
  faScroll,
  faLayerGroup,
  faFolderOpen,
  faDisplay,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Handle, Position } from "reactflow";
import { systemIcons } from "../../utils/svgIcons/systemIcons";
import { ProcessOther } from "../../utils/svgIcons/process-other";
import { DBTable } from "../../utils/svgIcons/db-table-svgrepo";
import { Container } from "../../utils/svgIcons/container-registry-svgrepo";

import { AzureActiveDirectory } from "../../assets/Azure_Public_Service_Icons/Icons/identity/10221-icon-service-Azure-Active-Directory";
import { AzureNetworkInterfaces } from "../../assets/Azure_Public_Service_Icons/Icons/networking/10080-icon-service-Network-Interfaces";
import { AzurePrivateEndpoints } from "../../assets/Azure_Public_Service_Icons/Icons/other/02579-icon-service-Private-Endpoints";
import { AzureSqlServer } from "../../assets/Azure_Public_Service_Icons/Icons/databases/10132-icon-service-SQL-Server";
import { AzureSqlServerDB } from "../../assets/Azure_Public_Service_Icons/Icons/databases/10137-icon-service-Azure-SQL-Server-Stretch-Databases";
import { AzureAppServiceCertificates } from "../../assets/Azure_Public_Service_Icons/Icons/web/00049-icon-service-App-Service-Certificates";
import { AzureMLStudio } from "../../assets/Azure_Public_Service_Icons/Icons/iot/00030-icon-service-Machine-Learning-Studio-(Classic)-Web-Services";
import { AzureWebsiteStaging } from "../../assets/Azure_Public_Service_Icons/Icons/general/10848-icon-service-Website-Staging";
import { AzureServiceStorageAccounts } from "../../assets/Azure_Public_Service_Icons/Icons/storage/10086-icon-service-Storage-Accounts";
import { AzureServiceSSHKeys } from "../../assets/Azure_Public_Service_Icons/Icons/other/00412-icon-service-SSH-Keys";
import { AzureServiceComputerVision } from "../../assets/Azure_Public_Service_Icons/Icons/ai + machine learning/00792-icon-service-Computer-Vision";

import { AzureNetworkSecurityGroups } from "../../assets/Azure_Public_Service_Icons/Icons/networking/10067-icon-service-Network-Security-Groups";
import { AzurePublicIPAddresses } from "../../assets/Azure_Public_Service_Icons/Icons/networking/10068-icon-service-Public-IP-Addresses-(Classic)";
import { AzureVirtualNetworks } from "../../assets/Azure_Public_Service_Icons/Icons/networking/10061-icon-service-Virtual-Networks";
import { AzureServiceNetworkWatcher } from "../../assets/Azure_Public_Service_Icons/Icons/monitor/10066-icon-service-Network-Watcher";
import { AzureServiceGenomicsAccounts } from "../../assets/Azure_Public_Service_Icons/Icons/ai + machine learning/10164-icon-service-Genomics-Accounts";

function CustomNodeTypeOne({ color, data, editGraph, hoveredEdge }) {
  const nodeTypeMapper = (nodeType) => {
    if (nodeType.includes("AzureActiveDirectory/b2cDirectories")) {
      return AzureActiveDirectory;
    } else if (nodeType.includes("Network/networkInterfaces")) {
      return AzureNetworkInterfaces;
    } else if (nodeType.includes("Network/privateEndpoints")) {
      return AzurePrivateEndpoints;
    } else if (
      nodeType.includes("Sql/servers" || nodeType.includes("SQL Server"))
    ) {
      return AzureSqlServer;
    } else if (nodeType.includes("Sql/servers/databases")) {
      return AzureSqlServerDB;
    } else if (nodeType.includes("Web/certificates")) {
      return AzureAppServiceCertificates;
    } else if (nodeType.includes("Web/serverFarms")) {
      return AzureMLStudio;
    } else if (nodeType.includes("Web/sites")) {
      return AzureWebsiteStaging;
    } else if (nodeType.includes("Storage/storageAccounts")) {
      return AzureServiceStorageAccounts;
    } else if (nodeType.includes("Compute/sshPublicKeys")) {
      return AzureServiceSSHKeys;
    } else if (nodeType.includes("Compute/virtualMachineScaleSets")) {
      return AzureServiceComputerVision;
    } else if (nodeType.includes("Network/networkSecurityGroups")) {
      return AzureNetworkSecurityGroups;
    } else if (nodeType.includes("Network/publicIPAddresses")) {
      return AzurePublicIPAddresses;
    } else if (nodeType.includes("Network/virtualNetworks")) {
      return AzureVirtualNetworks;
    } else if (nodeType.includes("Network/networkWatchers")) {
      return AzureServiceNetworkWatcher;
    } else if (nodeType.includes("CognitiveServices/accounts")) {
      return AzureServiceGenomicsAccounts;
    } else if (nodeType.trim().toLowerCase() === "table") {
      return DBTable;
    } else if (
      nodeType.trim().toLowerCase() === "container" ||
      nodeType.trim().toLowerCase() === "context"
    ) {
      return Container;
    } else {
      return ProcessOther;
    }
  };

  const getEmojis = (val) => {
    if (/DB | Warehouse/.test(val)) {
      return faDatabase;
    } else if (/Batch/.test(val)) {
      return faScroll;
    } else if (/Screen/.test(val)) {
      return faDisplay;
    } else if (/File-Share/.test(val)) {
      return faFolderOpen;
    } else if (/Retail |Wealth/.test(val)) {
      return systemIcons.cloudServer;
    } else if (/FrontOffice | Operations/.test(val)) {
      return faUser;
    } else if (/Bloomberg/.test(val)) {
      return faServer;
    } else {
      return faServer;
    }
  };

  // const addChildNode = (evt) => {
  //   // prevent the expand/collapse behaviour when a new node is added while the
  //   // node is expanded
  //   setIsNodeModalOpen(true);
  //   if (data.expanded) {
  //     evt.preventDefault();
  //     evt.stopPropagation();
  //   }

  //   const newNodeId = `${id}__${new Date().getTime()}`;
  //   setNewNodeDetails({
  //     ...newNodeDetails,
  //     id: id,
  //     position: { x: xPos, y: yPos + 100 },
  //     newNodeId: newNodeId,
  //   });
  // };

  // function getLabel({ expanded, expandable }) {
  //   if (!expandable) {
  //     return "";
  //   }

  //   return expanded ? (
  //     <FontAwesomeIcon icon={faCaretRight} className="shadow-md" />
  //   ) : (
  //     <FontAwesomeIcon icon={faCaretLeft} className="shadow-md" />
  //   );
  // }

  function truncateWithEllipsis(str, maxLength) {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength - 3) + "...";
    }
    return str;
  } 

  const isConnectedToHoveredEdge = (nodeId) => {
    const hoveredSource = hoveredEdge?.source?.replace("System-", "");
    const hoveredTarget = hoveredEdge?.target?.replace("System-", "");
    const cleanedNodeId = nodeId
      ? nodeId.toString().replace("System-", "")
      : null;

    return hoveredSource === cleanedNodeId || hoveredTarget === cleanedNodeId;
  };

  const getNodeStyle = (nodeId) => {
    return isConnectedToHoveredEdge(nodeId)
      ? { borderColor: "#00ff00" } // Highlight color for connected nodes
      : {}; // Default style for other nodes
  };

  return (
    <>
      <div
        className=" font-newOne w-24 shadow-lg border-solid border-1 border-teal-400 rounded-lg py-1 px-1 bg-sky-50 dark:bg-gray-800"
        style={getNodeStyle(data?.innerGraphRequestBody?.Item?.nodeIdInfo?.idNum)}
      >
        <div
          className=" font-newOne text-xxs text-center font-extrabold font-helvetica mb-0.5"
          style={{ color: color }}
        >
          <Tooltip title={data?.Name} color={color}>
            {truncateWithEllipsis(data?.Name, 20, true)}
          </Tooltip>
        </div>
        <div className="flex items-center">
          <div className="mr-1">{nodeTypeMapper(data?.nodeType)}</div>
          <div className="max-w-xs text-xxs text-black font-helvetica overflow-hidden">
            <div className=" font-newOne line-clamp-3">
              <Tooltip title={data?.Description} color={color}>
                {truncateWithEllipsis(data?.Description, 70, false)}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-1">
          <div>
            <p className="flex items-center">
              <Tooltip title={data?.belongsToContext} color={color}>
                <h1 className="text-xxs text-gray-700 font-bold">
                  {truncateWithEllipsis(data?.belongsToContext, 10, false)}
                </h1>
              </Tooltip>
            </p>
          </div>
          <span className="text-xxxs py-0.5 font-bold px-1 text-indigo-700 rounded-full bg-indigo-100">
            # {data?.nodeType}
          </span>
        </div>
        <Handle position={Position.Left} type="target" />
        <Handle position={Position.Right} type="source" />
      </div>

      {editGraph ? <div className="add-node">+ add child node</div> : null}
      {/* <Row><FontAwesomeIcon className="infoIcon" icon={faCircleInfo} size="sm" /></Row> */}
    </>
  );
}

export default memo(CustomNodeTypeOne);
