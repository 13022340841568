import { UPDATE_THEME, RESET_THEME } from "../actionTypes"

const updateTheme = (data) => {
  return {
    type: UPDATE_THEME,
    payload: data
  };
};

const resetTheme = (data) => {
  return {
    type: RESET_THEME,
    payload: data
  };
};

export { updateTheme, resetTheme };