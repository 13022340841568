import React, { useState, useEffect } from "react";
import { Modal, Button, Select, Form } from "antd";
import { Grid, MenuItem, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFilter } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { svgIcons } from "../../utils/svgIcons";

const FilterModal = ({ open, onClose, graphData, setSelectedFilters }) => {
  const [selectedValues, setSelectedValues] = useState({
    ID: [],
    Name: [],
    nodeType: [],
    belongsToContext: [],
    owner: [],
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    ID: [],
    Name: [],
    nodeType: [],
    belongsToContext: [],
    owner: [],
  });

  useEffect(() => {
    const extractUniqueValues = (field) => {
      const values = (graphData?.nodes || []).map((node) => {
        if (field === "id") {
          return node[field];
        }

        return node.data?.[field];
      });

      const uniqueValues = [
        ...new Set(
          values.filter((value) => value !== null && value !== undefined)
        ),
      ];

      const options = uniqueValues;

      return options;
    };

    setDropdownOptions({
      ID: extractUniqueValues("id"),
      Name: extractUniqueValues("Name"),
      nodeType: extractUniqueValues("nodeType"),
      belongsToContext: extractUniqueValues("belongsToContext"),
      owner: extractUniqueValues("owner"),
    });
  }, [graphData, open]);

  const handleSelectChange = (fieldName, value) => {
    setSelectedValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  };

  const handleReset = () => {
    setSelectedValues({
      ID: [],
      Name: [],
      nodeType: [],
      belongsToContext: [],
      owner: [],
    });
    setSelectedFilters({});
    // onClose(false);
  };

  const handleFilter = () => {
    setSelectedFilters(selectedValues);
    onClose(false);
  };

  return (
    <Modal
      title={
        <div className="flex items-center bg-blue-100 py-4 px-6 rounded-t-lg">
          {svgIcons.filter}
          <span className="text-xl font-semibold ml-2">
            Choose your filter criterion
          </span>
        </div>
      }
      open={open}
      onCancel={() => onClose(false)}
      footer={null}
      width={500}
      className="rounded-lg  shadow-lg"
    >
      <div className="flex items-center justify-between">
        <FontAwesomeIcon
          className="absolute top-0 right-0 -mt-2 -mr-2 cursor-pointe"
          icon={faCircleXmark}
          size="2x"
          color="red"
          onClick={() => onClose(false)}
        />
      </div>
      <div className="p-4 font-newOne">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {/* labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} */}
            <Form.Item className="font-newOne font-semibold" label="ID" >
              <Select
                size="large"
                mode="multiple"
                value={selectedValues.ID}
                placeholder="Select ID"
                onChange={(value) => handleSelectChange("ID", value)}
                style={{
                  width: "100%",
                }}
                className="font-newOne"
                options={dropdownOptions.ID.map((element) => ({
                  value: element,
                  label: element,
                }))}
              />
            </Form.Item>
          </Grid>
          <Grid item xs={12}>
            <Form.Item className="font-newOne font-semibold" label="Name">
              <Select
                size="large"
                mode="multiple"
                value={selectedValues.Name}
                placeholder="Select Name"
                className="font-newOne"
                onChange={(value) => handleSelectChange("Name", value)}
                style={{
                  width: "100%",
                }}
                options={dropdownOptions.Name.map((element) => ({
                  value: element,
                  label: element,
                }))}
              />
            </Form.Item>
          </Grid>
          <Grid item xs={12}>
            <Form.Item className="font-newOne font-semibold" label="Owner">
              <Select
                size="large"
                mode="multiple"
                placeholder="Select Owner"
                value={selectedValues.owner}
                className="font-newOne"
                onChange={(value) => handleSelectChange("owner", value)}
                style={{
                  width: "100%",
                }}
                options={dropdownOptions.owner.map((element) => ({
                  value: element,
                  label: element,
                }))}
              />
            </Form.Item>
          </Grid>
          <Grid item xs={12}>
            <Form.Item className="font-newOne font-semibold" label="Node type">
              <Select
                size="large"
                mode="multiple"
                placeholder="Select Node Type"
                value={selectedValues.nodeType}
                className="font-newOne"
                onChange={(value) => handleSelectChange("nodeType", value)}
                style={{
                  width: "100%",
                }}
                options={dropdownOptions.nodeType.map((element) => ({
                  value: element,
                  label: element,
                }))}
              />
            </Form.Item>
          </Grid>
          <Grid item xs={12}>
            <Form.Item
              className="font-newOne font-semibold"
              label="Belongs to context"
            >
              <Select
                size="large"
                mode="multiple"
                placeholder="Select Belongs to context"
                value={selectedValues.belongsToContext}
                className="font-newOne"
                onChange={(value) =>
                  handleSelectChange("belongsToContext", value)
                }
                style={{
                  width: "100%",
                }}
                options={dropdownOptions.belongsToContext.map((element) => ({
                  value: element,
                  label: element,
                }))}
              />
            </Form.Item>
          </Grid>
          {/* <Grid item xs={4}>
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker label="Created date time from" />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker label="Created date time to" />
            </LocalizationProvider>
          </Grid> */}
        </Grid>
        <hr className="my-4 border-gray-300" />
        <div className="flex font-newOne justify-end mt-4">
          <Button onClick={handleReset} className="mr-2 font-newOne">
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Reset
          </Button>
          <Button type="primary" onClick={handleFilter} className="font-newOne">
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            Filter
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
