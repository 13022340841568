import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BarChartVisual } from "../../components/chart/BarChart";
import DataTypePieChart from "../../components/chart/PieChart";
import RelationshipStrengthHeatmap from "../../components/chart/HeatMap";
import SchemaHierarchyTreeMap from "../../components/chart/Treemap";
import SystemRelationshipChart from "../../components/chart/SankeyChart";
import NodeGrowthChart from "../../components/chart/AreaChart";
import SystemAttributesRadarChart from "../../components/chart/RadarChart";
import NodeOwnershipStackedBarChart from "../../components/chart/StackChart";
import NodeTypePieChart from "../../components/chart/NodeType";
import { Modal } from "antd";

function AnalyticsModal({ graphData, setShowAnalytics, showAnalytics }) {
  function findNodeWithMostConnections(graphData) {
    let maxConnections = 0;
    let nodeWithMostConnections = null;

    graphData.nodes.forEach((node) => {
      const connections = graphData.edges.filter(
        (edge) => edge.source === node.id || edge.target === node.id
      ).length;

      if (connections > maxConnections) {
        maxConnections = connections;
        nodeWithMostConnections = node;
      }
    });

    return nodeWithMostConnections;
  }

  const nodeWithMostConnections = findNodeWithMostConnections(graphData);

  console.log("Node with Most Connections:", nodeWithMostConnections);

  function findNodeWithFewestConnections(graphData) {
    let minConnections = Infinity;
    let nodeWithFewestConnections = null;

    graphData.nodes.forEach((node) => {
      const connections = graphData.edges.filter(
        (edge) => edge.source === node.id || edge.target === node.id
      ).length;

      if (connections < minConnections) {
        minConnections = connections;
        nodeWithFewestConnections = node;
      }
    });

    return nodeWithFewestConnections;
  }

  const nodeWithFewestConnections = findNodeWithFewestConnections(graphData);

  console.log("Node with Fewest Connections:", nodeWithFewestConnections);
  function findLargestConnectedComponent(graphData) {
    // Create a set to keep track of visited nodes
    const visited = new Set();

    // Initialize variables to track the largest component
    let largestComponent = [];
    let currentComponent = [];

    // Define a DFS function
    function dfs(nodeId) {
      visited.add(nodeId);
      currentComponent.push(nodeId);

      const neighbors = graphData.edges
        .filter((edge) => edge.source === nodeId || edge.target === nodeId)
        .map((edge) => (edge.source === nodeId ? edge.target : edge.source));

      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          dfs(neighbor);
        }
      }
    }

    // Iterate through nodes to find the largest connected component
    graphData.nodes.forEach((node) => {
      if (!visited.has(node.id)) {
        currentComponent = [];
        dfs(node.id);

        if (currentComponent.length > largestComponent.length) {
          largestComponent = [...currentComponent];
        }
      }
    });

    return largestComponent;
  }

  const largestConnectedComponent = findLargestConnectedComponent(graphData);

  console.log("Largest Connected Component:", largestConnectedComponent);

  return (
    <Modal title={null} open={showAnalytics} footer={null} width={1000}>
      <div className="relative py-4 px-5 md:px-4 bg-white shadow-lg rounded">
        <div className="flex justify-center space-x-4">
          <div className="flex-1 bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2">
              Node with Most Connections
            </h3>
            <p>
              {nodeWithMostConnections
                ? nodeWithMostConnections?.data?.Name
                : "N/A"}
            </p>
          </div>

          <div className="flex-1 bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2">
              Node with Fewest Connections
            </h3>
            <p>
              {nodeWithFewestConnections
                ? nodeWithFewestConnections?.data?.Name
                : "N/A"}
            </p>
          </div>

          {/* Largest Connected Component */}
          {/* <div className="flex-1 bg-white p-4 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold mb-2">
                  Largest Connected Component
                </h3>
                {largestConnectedComponent.length > 0 ? (
                  <p>
                    {largestConnectedComponent.map((nodeId, index) => (
                      <span key={nodeId}>
                        {
                          graphData.nodes.find((node) => node.id === nodeId)
                            ?.data.Name
                        }
                        {index < largestConnectedComponent.length - 1
                          ? ", "
                          : ""}
                      </span>
                    ))}
                  </p>
                ) : (
                  <p>N/A</p>
                )}
              </div> */}
        </div>

        <div className="flex p-2">
          <div className="w-1/2 border border-gray-300">
            <BarChartVisual graphData={graphData}    />
          </div>
          <div className="w-1/2 border border-gray-300">
            <NodeTypePieChart graphData={graphData} label={"edges"}   />
          </div>
        </div>
        <div className="flex  p-2">
          <div className="w-1/2 border border-gray-300">
            <NodeTypePieChart graphData={graphData} label={"nodes"} />
          </div>
          {/* <div className="w-1/2 border border-gray-300">
                <NodeOwnershipStackedBarChart />
              </div> */}
        </div>

        <button
          className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
          onClick={() => setShowAnalytics(false)}
          aria-label="close modal"
          role="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-x"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
      </div>
    </Modal>
  );
}

export default AnalyticsModal;
