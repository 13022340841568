export const themeColors = {
  "#024d84": "10",
  "#FAAF47": "2",
  "#52c41a": "11",
  "#d9363e": "4",
  "#111": "22",
  "#00309b": "23",
  "#bd9ac8": "7",
  "#6d87b5": "16",
};

export const wizardGradientColors = {
  "#00309b": "from-suketadarkblue",
  "#1890ff": "from-suketalightblue",
  "#52c41a": "from-suketagreen",
  "#d9363e": "from-suketared",
  "#FAAF47": "from-suketaorange",
  "#bd9ac8": "from-suketapink",
  "#6d87b5": "from-suketagreyblue",
};

export const modalWizardMenus = {
  1: {
    menu:1,
    title: "Extract metadata from a database using this Wizard.",
  },
  2: {
    menu:2,
    title: (
      <div>
        Prerequisites:
        <div>
        <a href="https://www.anansihub.com/blog/azure-resources-in-json" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
          Please follow this blog link for setting up the prerequisites in your
          Azure Portal
        </a>
        </div>
        
      </div>
    ),
  },
};
