//action type for theme configurator
export const UPDATE_THEME = "UPDATE_THEME";
export const RESET_THEME = "RESET_THEME";

export const ADD_CURRENT_FLOW = "ADD_CURRENT_FLOW";
export const RESET_CURRENT_FLOW = "CURRENT_FLOW";

export const GET_MASTER_LIST_SUCCESS = "GET_MASTER_LIST_SUCCESS";
export const GET_MASTER_LIST_FETCHING = "GET_MASTER_LIST_FETCHING";
export const GET_MASTER_LIST_FAILURE = "const GET_MASTER_LIST_FAILURE";

export const GET_JWT_TOKEN_FETCH = "GET_JWT_TOKEN_FETCH ";
export const GET_JWT_TOKEN_SUCCESS = "GET_JWT_TOKEN_SUCCESS";
export const GET_JWT_TOKEN_FAILURE = "GET_JWT_TOKEN_FAILURE";

export const GET_CATALOG_FETCH = "GET_CATALOG_FETCH";
export const GET_CATALOG_SUCCESS = "GET_CATALOG_SUCCESS";
export const GET_CATALOG_FAILURE = "GET_CATALOG_FAILURE";

export const GET_CATALOG_HEADERS = "GET_CATALOG_HEADERS";
export const GET_CATALOG_HEADERS_SUCCESS = "GET_CATALOG_HEADERS_SUCCESS";
export const GET_CATALOG_HEADERS_FAILURE = "GET_CATALOG_HEADERS_FAILURE";

export const UPDATE_CATALOG_DATA_FETCH = "UPDATE_CATALOG_DATA_FETCH";
export const UPDATE_CATALOG_DATA_SUCCESS = "UPDATE_CATALOG_DATA_SUCCESS";
export const UPDATE_CATALOG_FAILURE = "UPDATE_CATALOG_FAILURE";

export const GET_ENUM_VALUES_FETCH = "GET_ENUM_VALUES_FETCH";
export const GET_ENUM_VALUES_SUCCESS = "GET_ENUM_VALUES_SUCCESS";
export const GET_ENUM_VALUES_FAILURE = "GET_ENUM_VALUES_FAILURE";

export const GET_RELATIONSHIP_FETCH = "GET_RELATIONSHIP_FETCH";
export const GET_RELATIONSHIP_SUCCESS = "GET_RELATIONSHIP_SUCCESS";
export const GET_RELATIONSHIP_FAILURE = "GET_RELATIONSHIP_FAILURE";

export const CREATE_GRAPH_FETCH = "CREATE_GRAPH_FETCH";
export const CREATE_GRAPH_SUCCESS = "CREATE_GRAPH_SUCCESS";
export const CREATE_GRAPH_FAILURE = "CREATE_GRAPH_FAILURE";
export const UPDATE_GRAPH = "UPDATE_GRAPH";
export const RESET_GRAPH = "RESET_GRAPH"

export const GET_RELATIONSHIP_METAINFO_FETCH  = "GET_RELATIONSHIP_METAINFO_FETCH";
export const GET_RELATIONSHIP_METAINFO_SUCCESS  = "GET_RELATIONSHIP_METAINFO_SUCCESS";
export const GET_RELATIONSHIP_METAINFO_FAILURE  = "GET_RELATIONSHIP_METAINFO_FAILURE";

export const GET_MENU_CATALOG_FETCH = "GET_MENU_CATALOG_FETCH";
export const GET_MENU_CATALOG_SUCCESS = "GET_MENU_CATALOG_SUCCESS";
export const GET_MENU_CATALOG_FAILURE = "GET_MENU_CATALOG_FAILURE";




