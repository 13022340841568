import React, { memo } from "react";
import { getBezierPath, EdgeLabelRenderer, BaseEdge } from "reactflow";

function DefaultEdge({
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  label,
  hoveredEdge,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });


  const style = {
    strokeWidth: 1,
    stroke:
      hoveredEdge?.data.forwardEdge?.id === data?.forwardEdge?.id
        ? '#8df578'
        : "#479ef5",
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        {data?.forwardEdge?.data?.label ? (
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              backgroundColor: "#c8e0f6",
              padding: 2,
              borderRadius: 1,
              fontSize: 5,
              fontWeight: 700,
            }}
            className="nodrag nopan"
          >
            {data?.forwardEdge?.data?.label}
          </div>
        ) : null}
      </EdgeLabelRenderer>
    </>
  );
}

export default memo(DefaultEdge);
