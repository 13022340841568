import { createChatBotMessage } from 'react-chatbot-kit';

//const config = {
//  initialMessages: [createChatBotMessage(`How can I help you today?`)],
//};

const config = {
  initialMessages: [createChatBotMessage(`How can I help you today?`)],
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
      width: "400px"
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
};

export default config;