import React from "react";
import { Modal, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faFlag, faColumns, faUser, faInfoCircle, faUserCircle, faCircle, faMapMarker, faExclamationTriangle, faListAlt , faUserTie, faNetworkWired,
 } from "@fortawesome/free-solid-svg-icons";
import { KeyIcon } from "../customNodes/KeyIcon";

export const TableNodeModal = ({ showMore, setShowMoreClick, data , infoBox}) => {
  const uniqueDataTypes = [
    ...new Set(data.columns.map((column) => column.type)),
  ];

//   const positionStyle = {
//     position: "absolute",
//     top: top !== undefined ? top : "auto",
//     left: left !== undefined ? left : "auto",
//     right: right !== undefined ? right : "auto",
//     bottom: bottom !== undefined ? bottom : "auto",
//     zIndex: 1000,
//   };
  return (
    <Modal
      title={null}
      open={showMore}
      onCancel={(e) => {
        e.stopPropagation();
        setShowMoreClick(false);
      }}
      footer={null}
      width={400}
      className="rounded-lg shadow-lg"
      bodyStyle={{ maxHeight: "70vh", overflow: "auto" }}
    >
      <div className="flex items-center justify-between">
        <FontAwesomeIcon
          className="absolute top-0 right-0 -mt-2 -mr-2 cursor-pointer"
          icon={faCircleXmark}
          size="2x"
          color="red"
          onClick={(e) => {
            e.stopPropagation();
            setShowMoreClick(false);
          }}
          style={{ zIndex: 1000 }}
        />
      </div>
      <div className="font-newOne" onClick={(e) => {  e.stopPropagation() }}>
        <div className="flex">
          {/* First section: Table */}
          <div
            className="table-section w-full  overflow-auto"
            style={{ maxHeight: "60vh" }}
          >
            <div className="table w-full">
              <div
                style={{ backgroundColor: data.schemaColor }}
                className="table__name"
              >
                {data?.anomalyDetails ? (
                  <Tooltip title={data?.anomalyDetails}>
                    <span className="mr-2">
                      <FontAwesomeIcon
                        className="text-red-500 animate-glow"
                        size="lg"
                        icon={faFlag}
                      />
                    </span>
                  </Tooltip>
                ) : null}{" "}
                {data.schema ? `${data.schema}.${data.Name}` : data.Name}
              </div>

              <div className="table__columns">
                {data?.columns?.map((column, index) => (
                  <div key={column.name} className="column-name">
                    <div className="column-name__inner">
                      <div className="column-name__icon-name">
                        {column.key && <KeyIcon />}
                        {column.name}
                      </div>
                      <div className="column-name__type">{column.nodeType}</div>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>

         
        </div>
      </div>
    </Modal>
  );
};

         