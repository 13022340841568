import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMasterList } from "./container/actions/catalogActions";
import { fetchJWTToken } from "./container/actions/catalogActions";
import { resetGraph } from "./container/actions/graphActions";
import "./assets/styles/style";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/main.scss";
// MSAL imports
import { useIsAuthenticated, useMsal, MsalProvider } from "@azure/msal-react";
import {
  NavigationClient,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import Unauthorised from "./components/layout/Unauthorized";
import { MASTER_TABLE_MAX_ROWS } from "./data/constants";

function App({ pca }) {
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <Pages />
      </MsalProvider>
    </ClientSideNavigation>
  );
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new NavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

function Pages() {
  const dispatch = useDispatch();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);
  const masterListState = useSelector(
    (catalogState) => catalogState.masterListReducer
    );
  const navigate = useNavigate();

  const handleSignIn = () => {
    instance.loginRedirect({
      ...loginRequest,
      redirectUri: "/",
    }).catch((error) => console.log(error));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          setIsLoading(true); // Set loading to true when data fetching starts
          const response = await fetchJWTToken(instance);
          setToken(response);
          dispatch(fetchMasterList(MASTER_TABLE_MAX_ROWS, 0, response));
          dispatch(resetGraph());
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      } finally {
        setIsLoading(false); // Set loading to false after data fetching is completed
      }
    };

    fetchData();
  }, [instance, isAuthenticated, dispatch]);

  useEffect(() => {
    const navigationClient = new NavigationClient(navigate);
    instance.setNavigationClient(navigationClient);
  }, [navigate, instance]);


  if (masterListState?.loading || inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
    return (
      <div class="flex items-center justify-center min-h-screen p-5 bg-gray-100 min-w-screen">
      <div class="flex space-x-2 animate-pulse">
          <div class="w-3 h-3 bg-gray-500 rounded-full"></div>
          <div class="w-3 h-3 bg-gray-500 rounded-full"></div>
          <div class="w-3 h-3 bg-gray-500 rounded-full"></div>
      </div>
  
  </div>
    );
  }

  if (isAuthenticated) {
    if (masterListState.error !== null) {
      return (
        <div className="App">
          <Unauthorised />
        </div>
      );
    } else if (masterListState.data !== null) {
      return (
        <div className="App">
          <Dashboard token={token} masterList={masterListState.data} />
        </div>
      );
    }
  } else if (
    inProgress !== InteractionStatus.Startup &&
    inProgress !== InteractionStatus.HandleRedirect
  ) {
    return (
      <div className="App">
        <SignIn handleSignIn={handleSignIn} />
      </div>
    );
  } else if(masterListState.error !== null){
    return (
      <div className="App">
         <Unauthorised />
      </div>
    );
  }
}

export default App;
