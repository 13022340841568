import React from "react";
import { Treemap, Tooltip, ResponsiveContainer } from "recharts";

const SchemaHierarchyTreeMap = ({ graphData }) => {
  // Calculate the total count of columns for each table
  const tableColumnCounts = {};
  graphData.nodes.forEach((node) => {
    const columnCount = node.columns.length;
    tableColumnCounts[node.id] = columnCount;
  });

  // Create the hierarchical structure for the TreeMap
  const treeMapData = {
    name: "Schema Hierarchy",
    children: graphData.nodes.map((node) => ({
      name: node.name,
      value: tableColumnCounts[node.id],
      children: node.columns.map((column) => ({
        name: column.name,
        value: 1, // You can adjust this value if needed
      })),
    })),
  };

  console.log(treeMapData);
  return (
    <ResponsiveContainer width="100%" height={400}>
      <Treemap
        data={treeMapData}
        dataKey="value"
        ratio={4 / 3}
        stroke="#fff"
        fill="#8884d8" // Set the fill color for the nodes
        content={
          <CustomizedContent
            colors={["#8884d8", "#83a6ed", "#8dd1e1", "#82ca9d", "#a4de6c"]}
          />
        } // Custom content for each node
      >
        <Tooltip />
      </Treemap>
    </ResponsiveContainer>
  );
};

const CustomizedContent = ({
  root,
  depth,
  x,
  y,
  width,
  height,
  index,
  payload,
  colors,
}) => {
    console.log(payload)
  if (!payload) {

    return null;
  }

  const { name, value, children } = payload;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: colors[index % colors.length], // Assign colors dynamically
          fillOpacity: 0.8,
          stroke: "#fff",
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {depth === 1 ? (
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
        >
          {name}
        </text>
      ) : null}
      {children &&
        children.map((child, idx) => (
          <CustomizedContent
            key={idx}
            root={root}
            depth={depth + 1}
            x={x + (idx % 2) * (width / 2)}
            y={y + (idx > 1 ? height / 2 : 0)}
            width={width / 2}
            height={height / 2}
            index={idx}
            payload={child}
            colors={colors}
          />
        ))}
    </g>
  );
};

export default SchemaHierarchyTreeMap;
