import {
  GET_JWT_TOKEN_FAILURE,
  GET_JWT_TOKEN_FETCH,
  GET_JWT_TOKEN_SUCCESS,
  GET_MASTER_LIST_FAILURE,
  GET_MASTER_LIST_SUCCESS,
  GET_MASTER_LIST_FETCHING,
  GET_CATALOG_FAILURE,
  GET_CATALOG_FETCH,
  GET_CATALOG_SUCCESS,
  UPDATE_CATALOG_DATA_FETCH,
  UPDATE_CATALOG_DATA_SUCCESS,
  UPDATE_CATALOG_FAILURE,
  GET_CATALOG_HEADERS,
  GET_CATALOG_HEADERS_FAILURE,
  GET_CATALOG_HEADERS_SUCCESS,
  GET_RELATIONSHIP_FETCH,
  GET_RELATIONSHIP_SUCCESS,
  GET_RELATIONSHIP_FAILURE,
  GET_RELATIONSHIP_METAINFO_FETCH,
  GET_RELATIONSHIP_METAINFO_SUCCESS,
  GET_RELATIONSHIP_METAINFO_FAILURE,

} from "../actionTypes";

const initialJWTState = {
  token: null,
  loading: false,
  error: null,
};

const initialMasterListState = {
  data: null,
  loading: false,
  error: null,
};

const initialCatalogState = {};

const initialCataLogHeaderState = {};

const initialRelationshipState = {
  data: null,
  loading: false,
  error: null,
};

const initialRelationshipMetaState = {
  data: null,
  loading: false,
  error: null,
};

export const jwtReducer = (state = initialJWTState, action) => {
  switch (action.type) {
    case GET_JWT_TOKEN_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_JWT_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
      };
    case GET_JWT_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const masterListReducer = (state = initialMasterListState, action) => {
  switch (action.type) {
    case GET_MASTER_LIST_FETCHING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MASTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload],
      };
    case GET_MASTER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const cataLogDataReducer = (state = initialCatalogState, action) => {
  switch (action.type) {
    case GET_CATALOG_FETCH:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: true,
          error: null,
        },
      };
    case GET_CATALOG_SUCCESS:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_CATALOG_DATA_FETCH:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: true,
          error: null,
        },
      };
    case UPDATE_CATALOG_DATA_SUCCESS:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_CATALOG_FAILURE:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: false,
          error: action.payload,
        },
      };
    case GET_CATALOG_FAILURE:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export const cataLogHeaderReducer = (
  state = initialCataLogHeaderState,
  action
) => {
  switch (action.type) {
    case GET_CATALOG_HEADERS:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: true,
          error: null,
        },
      };
    case GET_CATALOG_HEADERS_SUCCESS:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: false,
          data: action.payload,
        },
      };
    case GET_CATALOG_HEADERS_FAILURE:
      return {
        ...state,
        [action.submenuName]: {
          ...state[action.submenuName],
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export const fetchRelationshipReducer = (
  state = initialRelationshipState,
  action
) => {
  switch (action.type) {
    case GET_RELATIONSHIP_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_RELATIONSHIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const fetchRelationshipMetaInfoReducer = (
  state = initialRelationshipMetaState,
  action
) => {
  switch (action.type) {
    case GET_RELATIONSHIP_METAINFO_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_RELATIONSHIP_METAINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_RELATIONSHIP_METAINFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

