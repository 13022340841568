// requestConstructor.js
export const getAPICallObject = (method, body = null, headers = {}) => {

  const callObject = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  if (body) {
    callObject.body = JSON.stringify(body);
  }

  return callObject;
};

export const request = async (url, callObject) => {
  try {
    const response = await fetch(url, callObject);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Request error:', error);
    throw error;
  }
};

export const requestDB = async (url, callObject) => {
  try {
    const response = await fetch(url, callObject);
    if(response.status === 200){
    const data = await response.json();
    return {status: response.status,message: response.statusText, data: data};
    }
    else{
      const errorResult = await response.json();
      return {status: response.status, message: response.statusText, data: errorResult}
    }
  } catch (error) {
    console.error('Request error:', error);
    throw error;
  }
};


