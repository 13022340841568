import React from "react";
import { PieChart, Pie, Cell, Legend , ResponsiveContainer,} from "recharts";

const DataTypePieChart = ({ graphData }) => {
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884D8",
    "#FF0000",
  ];
  const dataTypeCount = {};
  graphData.nodes.forEach((node) => {
    node.columns.forEach((column) => {
      if (column.type in dataTypeCount) {
        dataTypeCount[column.type]++;
      } else {
        dataTypeCount[column.type] = 1;
      }
    });
  });

  // Calculate total number of columns
  const totalColumns = graphData.nodes.reduce(
    (total, node) => total + node.columns.length,
    0
  );

  // Calculate percentages for each data type
  const dataTypePercentages = Object.keys(dataTypeCount).map((dataType) => ({
    name: dataType,
    percentage: (dataTypeCount[dataType] / totalColumns) * 100,
  }));

  return (
    <ResponsiveContainer >
    <PieChart width={400} height={300}>
      <Pie
        data={dataTypePercentages}
        dataKey="percentage"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={80}
        fill="#8884d8"
        label={({ name, percentage }) => `${name} (${percentage.toFixed(2)}%)`}
      >
        {dataTypePercentages.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend verticalAlign="bottom" height={36} />
    </PieChart>
    </ResponsiveContainer>
  );
};

export default DataTypePieChart;
