export function KeyIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="800"
        height="800"
        fill="rgb(250, 175, 71)"
        version="1.1"
        viewBox="0 0 485.017 485.017"
        xmlSpace="preserve"
        className="key-icon"
      >
        <path d="M361.205 68.899c-14.663 0-28.447 5.71-38.816 16.078-21.402 21.403-21.402 56.228 0 77.631 10.368 10.368 24.153 16.078 38.815 16.078s28.447-5.71 38.816-16.078c21.402-21.403 21.402-56.228 0-77.631-10.368-10.368-24.153-16.078-38.815-16.078zm17.602 72.495c-4.702 4.702-10.953 7.292-17.603 7.292s-12.901-2.59-17.603-7.291c-9.706-9.706-9.706-25.499 0-35.205 4.702-4.702 10.953-7.291 17.603-7.291s12.9 2.589 17.603 7.291c9.706 9.706 9.706 25.498 0 35.204z"></path>
        <path d="M441.961 43.036C414.21 15.284 377.311 0 338.064 0c-39.248 0-76.146 15.284-103.897 43.036-42.226 42.226-54.491 105.179-32.065 159.698L.254 404.584l-.165 80.268 144.562.165v-55.722h55.705V373.59h55.705v-64.492l26.212-26.212c17.615 7.203 36.698 10.976 55.799 10.976 39.244 0 76.14-15.282 103.889-43.032 57.289-57.289 57.289-150.505 0-207.794zm-21.213 186.581c-22.083 22.083-51.445 34.245-82.676 34.245-18.133 0-36.237-4.265-52.353-12.333l-9.672-4.842-49.986 49.985v46.918h-55.705v55.705h-55.705v55.688l-84.5-.096.078-37.85L238.311 208.95l-4.842-9.672c-22.572-45.087-13.767-99.351 21.911-135.029C277.466 42.163 306.83 30 338.064 30c31.234 0 60.598 12.163 82.684 34.249 45.592 45.592 45.592 119.776 0 165.368z"></path>
      </svg>
    );
  }