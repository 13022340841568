import { edgeClassName } from "./edgeClassName";
import { MarkerType } from "reactflow";
import { calculateSourcePosition } from "./calculateSourcePosition";
import { calculateTargetPosition } from "./calculateTargetPosition";

export const calculateEdges = ({ metaNodes, metaGraphData, color }) => {
  const initialEdges = [];
  const mergedEdgeMap = {}; // Map to store merged edges

  metaGraphData.graphData.edges.forEach((edgeConfig) => {
    const sourceNode = metaNodes.find((node) => node.id === edgeConfig.source);
    const targetNode = metaNodes.find((node) => node.id === edgeConfig.target);

    if (sourceNode && targetNode) {
      const sourcePosition =
        edgeConfig.sourcePosition ||
        calculateSourcePosition(
          sourceNode.width,
          sourceNode.position.x,
          targetNode.width,
          targetNode.position.x
        );
      const targetPosition =
        edgeConfig.targetPosition ||
        calculateTargetPosition(
          sourceNode.width,
          sourceNode.position.x,
          targetNode.width,
          targetNode.position.x
        );

      const sourceHandle = `${edgeConfig.sourceKey}-${sourcePosition}`;
      const targetHandle = `${edgeConfig.targetKey}-${targetPosition}`;

      const edgeId = `${edgeConfig.source}-${edgeConfig.target}`;
      const [source, target] = [edgeConfig.source, edgeConfig.target].sort(); // Sort nodes for consistent key generation
      const key = `${source}-${target}`;

      // If the edge already exists in the map, mark it as bidirectional
      if (mergedEdgeMap[key]) {
        mergedEdgeMap[key].type = "bidirectional";
      } else {
        mergedEdgeMap[key] = {
          id: edgeId,
          source: edgeConfig.source,
          target: edgeConfig.target,
          sourceHandle,
          targetHandle,
          markerEnd: { type: MarkerType.ArrowClosed, color },
          className: edgeClassName(edgeConfig, targetPosition),
          data: { forwardEdge: null, backwardEdge: null }, // Initialize data object
        };
      }

      // Assign forward or backward edge based on source and target
      mergedEdgeMap[key].data[source === edgeConfig.source ? "forwardEdge" : "backwardEdge"] = {
        id: edgeId,
        source: edgeConfig.source,
        target: edgeConfig.target,
        sourceHandle,
        targetHandle,
        markerEnd: { type: MarkerType.ArrowClosed, color },
        data: edgeConfig.data,
        className: edgeClassName(edgeConfig, targetPosition),
      };
    }
  });

  // Push the values from the mergedEdgeMap into initialEdges
  for (const key in mergedEdgeMap) {
    initialEdges.push(mergedEdgeMap[key]);
  }

  return initialEdges;
};

