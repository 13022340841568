import React from "react";
import Group from "../../assets/images/Group.png";

const Unauthorised = () => {
  return (
    <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
      <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div className="relative">
          <div className="absolute">
            <div className="">
              <h1 className="my-2 text-gray-800 font-bold text-2xl">
                Looks like you've found the doorway to the great nothing
              </h1>
              <p className="my-2 text-gray-800">Sorry about that! Please <a href="mailto:admin@suketa.in?subject=Unable to Login To Anansi">contact our tech team at: admin@suketa.in</a> with the error that you are seeing.
                We will try and get back to you as soon as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={Group} alt="Disconnected..." />
      </div>
    </div>
  );
};

export default Unauthorised;
