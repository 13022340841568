import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faTableColumns
} from "@fortawesome/free-solid-svg-icons";

export default function InfoBox({
  id,
  top,
  left,
  right,
  bottom,
  nodeDetails,
  setShowInfo,
  ...props
}) {
  const positionStyle = {
    position: "absolute",
    top: top !== undefined ? top : "auto",
    left: left !== undefined ? left : "auto",
    right: right !== undefined ? right : "auto",
    bottom: bottom !== undefined ? bottom : "auto",
    zIndex: 1000,
  };

  console.log(nodeDetails, "pari");

  function truncateWithEllipsis(str, maxLength) {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength - 3) + "...";
    }
    return str;
  }

  return (
    <div
      class="relative flex flex-col mt-6 text-gray-700 bg-slate-100 shadow-md bg-clip-border rounded-xl w-72"
      style={positionStyle}
    >
      <div class="p-4">
        <button
          onClick={() => setShowInfo(null)}
          className="absolute top-1 right-2 text-blue-800 dark:text-blue-400"
        >
          <FontAwesomeIcon icon={faCircleXmark} color="red" />
        </button>
        <h5 class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
          {truncateWithEllipsis(nodeDetails?.Name, 20)}
        </h5>
        <p class="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
          {nodeDetails.Description}
        </p>
      </div>

      <div class="p-4 pt-0 flex justify-between items-center">
  <div>
    {nodeDetails?.nodeType ? (
      <div
        class="inline-flex mr-2 items-center align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-3 rounded-full bg-blue-400 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
        type="button"
      >
        {truncateWithEllipsis(nodeDetails?.nodeType, 10)}
      </div>
    ) : null}

    <div
      class="inline-flex items-center align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-3 rounded-full bg-blue-400 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
      type="button"
    >
      {truncateWithEllipsis(nodeDetails?.nodeLabel, 10)}
    </div>
  </div>

  <div class="flex items-center">
    <FontAwesomeIcon icon={faTableColumns} color="green" />
    <span class="ml-2 text-black">{nodeDetails?.columns.length} {nodeDetails?.columns.length > 1 ? 'Columns': "Column"}</span>
  </div>
</div>

    </div>
  );
}
