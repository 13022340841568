// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.has-many-edge {
  /* https://reactflow.dev/docs/guides/theming/#react-flow-class-names */
}
.has-many-edge.selected path.react-flow__edge-path {
  marker-end: url(#hasManySelected);
  stroke-width: 1.5;
}
.has-many-edge--highlighted path.react-flow__edge-path,
.has-many-edge--highlighted path.react-flow__edge-interaction,
.has-many-edge--highlighted path.react-flow__connection-path {
  stroke: #00A300 !important;
  stroke-width: 1.5px;
}

.has-many-edge-reversed.selected path.react-flow__edge-path {
  marker-end: url(#hasManyReversedSelected);
  stroke-width: 1.5;
}
.has-many-edge-reversed--highlighted path.react-flow__edge-path,
.has-many-edge-reversed--highlighted path.react-flow__edge-interaction,
.has-many-edge-reversed--highlighted path.react-flow__connection-path {
  stroke: #00A300 !important;
  stroke-width: 1.5px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/has-many-edge.scss"],"names":[],"mappings":"AAAA;EACI,sEAAA;AACJ;AAAM;EACE,iCAAA;EACA,iBAAA;AAER;AAEQ;;;EAGE,0BAAA;EACA,mBAAA;AAAV;;AAMM;EACE,yCAAA;EACA,iBAAA;AAHR;AAOQ;;;EAGE,0BAAA;EACA,mBAAA;AALV","sourcesContent":[".has-many-edge {\n    /* https://reactflow.dev/docs/guides/theming/#react-flow-class-names */\n      &.selected path.react-flow__edge-path {\n        marker-end: url(#hasManySelected);\n        stroke-width: 1.5;\n      }\n    \n      &--highlighted {\n        path.react-flow__edge-path,\n        path.react-flow__edge-interaction,\n        path.react-flow__connection-path {\n          stroke: #00A300 !important;\n          stroke-width: 1.5px;\n        }\n      }\n    }\n    \n    .has-many-edge-reversed {\n      &.selected path.react-flow__edge-path {\n        marker-end: url(#hasManyReversedSelected);\n        stroke-width: 1.5;\n      }\n    \n      &--highlighted {\n        path.react-flow__edge-path,\n        path.react-flow__edge-interaction,\n        path.react-flow__connection-path {\n          stroke: #00A300 !important;\n          stroke-width: 1.5px;\n        }\n      }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
