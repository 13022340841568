import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    node: 'Node A',
    ownedByUserA: 25,
    ownedByUserB: 30,
    ownedByUserC: 45,
  },
  {
    node: 'Node B',
    ownedByUserA: 40,
    ownedByUserB: 20,
    ownedByUserC: 35,
  },
  {
    node: 'Node C',
    ownedByUserA: 15,
    ownedByUserB: 40,
    ownedByUserC: 45,
  },
];

const NodeOwnershipStackedBarChart = () => {
  return (
    <>
    <h2 className="text-m  text-center font-semibold m-1">
      {'Node Ownership by User Group'}
      </h2>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey="node" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ownedByUserA" stackId="a" fill="#8884d8" />
        <Bar dataKey="ownedByUserB" stackId="a" fill="#82ca9d" />
        <Bar dataKey="ownedByUserC" stackId="a" fill="#ffc658" />
      </BarChart>
    </ResponsiveContainer>
    </>
  );
};

export default NodeOwnershipStackedBarChart;
