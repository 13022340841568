import React from 'react';
import { Sankey, Tooltip, ResponsiveContainer } from 'recharts';

const SystemRelationshipChart = ({ graphData }) => {
    const createSankeyData = (jsonData) => {
        const nodes = {};
        const links = [];
      
        jsonData?.nodes?.forEach((node) => {
          nodes[node.id] = node.data.Name; 
        });
      
        jsonData?.edges?.forEach((edge) => {
          links.push({
            source: nodes[edge.source],
            target: nodes[edge.target],
            value: 1, // You can adjust this value based on your data
          });
        });
      
        // Create a list of unique nodes
        const uniqueNodes = Array.from(new Set(Object.values(nodes)));
      
        return {
          nodes: uniqueNodes.map((nodeName) => ({ name: nodeName })),
          links,
        };
      };
      
      const sankeyData = {
        nodes: [
          { name: "Bloomberg" },
          { name: "Summit" },
          { name: "Trades" },
        ],
        links: [
          { source: "Trades", target: "Summit", value: 1 },
          { source: "Bloomberg", target: "Summit", value: 1 },
        ],
      };
      
  return (
    <ResponsiveContainer width="100%" height={400}>
    {
        sankeyData ?
     
      <Sankey
      width={800}
      height={400}
      data={sankeyData}
      link={{ source: "source", target: "target", value: "value" }}
    >
      <Tooltip />
    </Sankey> : null}
    </ResponsiveContainer>
  );
};

export default SystemRelationshipChart;
