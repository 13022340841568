import { getEndpoints } from "./endpoints";
import { getAPICallObject, request, requestDB, requestDBMetadata } from "./requestConstructor";
import { DATA_FLOW_TABLE, MASTER_TABLE } from "../data/constants";

const {
  API_ENDPOINT,
  API_ENDPOINT_GRAPH,
  BASE_ENDPOINT,
  DATABASE_ENDPOINT,
  AZURE_RESOURCE_ENDPOINT,
  REACT_APP_AZURE_DATABASE_ENDPOINT
} = getEndpoints();

export const getMasterList = async (limit, skip, token) => {
  try {
    const url = `${API_ENDPOINT}/${MASTER_TABLE}?limit=${limit}&skip=${skip}&isActive=true`;
    const headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*'
    };

    const response = await request(url, getAPICallObject("GET", null, headers));
    return response;
  } catch (error) {
    throw new Error("Request failed: " + error.message);
  }
};

export const getCatalogData = async (
  limit,
  skip,
  token,
  submenuName,
  catalogName,
  filters,
  lastUpdateDateTime
) => {
  var url = `${API_ENDPOINT}/${submenuName}?limit=${limit}&skip=${skip}`;
  if (filters?.length > 0){
    url = url + "&" + filters;
  }
  if (lastUpdateDateTime) {
    url = url + `&orderInfo.orderProp=${lastUpdateDateTime}&orderInfo.orderType=DESC`;
  }
  const headers = {
    Authorization: `Bearer ${token}`,
    catalogName,
  };

  try {
    const response = await request(url, getAPICallObject("GET", null, headers));
    if (!Array.isArray(response)) {
      throw new Error("API request failed");
    }

    return response;
  } catch (error) {
    // Handle any request-related errors or exceptions
    throw new Error("Request failed: " + error.message);
  }
};

export const updateTableDetails = async (
  tableName,
  data,
  token,
  method,
  catalogName
) => {
  let url = `${API_ENDPOINT}/${tableName}`;
  if (["DELETE", "PATCH"].includes(method)) {
    url = `${API_ENDPOINT}/${tableName}/1`;
  }
  const headers = {
    Authorization: `Bearer ${token}`,
    catalogName,
  };
  const requestBody = data;
  try {
    const response = await request(
      url,
      getAPICallObject(method, requestBody, headers)
    );
    return response;
  } catch (error) {
    console.error("update error:", error);
    throw error;
  }
};

export const deleteDataFlow = async (id, token, catalogName) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    catalogName,
  };


  try {
    const getRelationshipsUrl = `${API_ENDPOINT}/Relationships?limit=10000&skip=0&RelationName=HAS_TAG&destClause.destUniqueColName=name&destClause.destId="${id}"`;
   const relationshipsResponse =  await   request(
    getRelationshipsUrl,
      getAPICallObject("GET", null, headers)
    );

    // Extract the relationships data from the response
    const relationshipsData = relationshipsResponse;

    // Construct the URLs for the DELETE requests
    const deleteRelationshipsUrl = `${API_ENDPOINT}/Relationships/1`;
    const deleteTagUrl = `${API_ENDPOINT}/Tag:DataFlow/1`;

    // Make DELETE requests using Promise.all to execute them concurrently
    await Promise.all([
      await request(
        deleteRelationshipsUrl,
        getAPICallObject("DELETE", relationshipsData, headers)
      ),
      await request(
        deleteTagUrl,
        getAPICallObject("DELETE", [{ Id: id }], headers)
      ),
    ]);

    return true

  } catch (error) {
    // Handle errors
    console.error('Error deleting data flow and relationships:', error);
    throw error; // Propagate the error to the caller if needed
  }
};


export const getTableHeaders = async (tableName, token, catalogName) => {
  const url = `${API_ENDPOINT}/PropertyInfo?limit=50&skip=0&nodeLabel='${tableName}'`;
  const headers = {
    Authorization: `Bearer ${token}`,
    catalogName,
  };
  try {
    const response = await request(url, getAPICallObject("GET", null, headers));
    if (!Array.isArray(response)) {
      throw new Error("API request failed");
    }
    return response;
  } catch (error) {
    throw new Error("Request for table details failed: " + error.message);
  }
};

export const getEnumValues = async (catalogType, token, catalogName) => {
  const url = `${API_ENDPOINT}/EnumValues?limit=50&skip=0&enumName='${catalogType}'&property=enumValue`;
  const headers = {
    Authorization: `Bearer ${token}`,
    catalogName,
  };
  try {
    const response = await request(url, getAPICallObject("GET", null, headers));
    if (!Array.isArray(response)) {
      throw new Error("API request failed");
    }
    return response;
  } catch (error) {
    throw new Error("Request for table details failed: " + error.message);
  }
};

export const createGraph = async (token, data, catalogName) => {
  const url = `${API_ENDPOINT_GRAPH}/${catalogName}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    catalogName,
  };
  const requestBody = data;
  const response = await request(
    url,
    getAPICallObject("POST", requestBody, headers)
  );
  return response;
};

export const createDataFlow = async (token, data, name, catalogName) => {
  const url = `${API_ENDPOINT}/${name}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    catalogName,
  };
  const requestBody = data;
  const response = await request(
    url,
    getAPICallObject("POST", requestBody, headers)
  );
  return response;
};

export const saveGraphDetails = async (
  jwtToken,
  dataFlowPayload,
  relationshipPayload,
  catalogName
) => {
  try {
    const promises = [
      createDataFlow(jwtToken, dataFlowPayload, DATA_FLOW_TABLE, catalogName),
      createDataFlow(
        jwtToken,
        relationshipPayload,
        "Relationships",
        catalogName
      ),
    ];

    const results = await Promise.allSettled(promises);
    const failedResults = results.filter(
      (result) => result.status === "rejected"
    );
    if (failedResults.length > 0) {
      throw new Error("One or more API calls failed:", failedResults);
    } else {
      // All three API calls succeeded, handle success here
      console.log("All API calls succeeded:", results);
    }
    return results;
  } catch (error) {
    console.error("Error occurred:", error);
    throw error;
  }
};

export const manageNodeDetails = async (url, token, method, details) => {
  const curUrl = new URL(url, BASE_ENDPOINT);
  const requestUrl = curUrl.toString();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await request(
      requestUrl,
      getAPICallObject(method, details, headers)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const makeDatabaseConnection = async (catalogName, requestBody) => {
  const url = `${DATABASE_ENDPOINT}/${catalogName}`;
  try {
    const response = await request(
      url,
      getAPICallObject("POST", JSON.stringify(requestBody))
    );
    return response;
  } catch (error) {
    throw new Error("Database Connection Failed: " + error.message);
  }
};

export const getRelationshipMetaInfo = async (limit, skip, token) => {
  const url = `${API_ENDPOINT}/RelationshipMetaInfo?limit=${limit}&skip=${skip}`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await request(url, getAPICallObject("GET", null, headers));
    return response;
  } catch (error) {
    throw new Error(
      "cannot fetch relationship metadata info: " + error.message
    );
  }
};
// http://localhost:80/api/catalog/System?limit=100&skip=0&name=~
export const searchSystemNames = async ({
  limit,
  skip,
  token,
  submenuName,
  name,
}) => {
  const url = `${API_ENDPOINT}/${submenuName}?limit=${limit}&skip=${skip}&name=~'(?i).*${name}.*'`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await request(url, getAPICallObject("GET", null, headers));
    return response;
  } catch (error) {
    throw new Error("cannot fetch names metadata info: " + error.message);
  }
};

export const getAzureToken = async (token, requestBody) => {
  const url = `${BASE_ENDPOINT}api/azureToken`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await request(
      url,
      getAPICallObject("POST", requestBody, headers)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAzureResources = async (token, subscriptionId) => {
  const url = `${AZURE_RESOURCE_ENDPOINT}/${subscriptionId}/resources?api-version=2022-09-01`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await request(url, getAPICallObject("GET", null, headers));
    return response;
  } catch (error) {
    throw error;
  }
};

export const checkDatabaseConnection = async (dbname,requestBody) => {
  const url = `${REACT_APP_AZURE_DATABASE_ENDPOINT}/${dbname}/check-connection`;
  try {
    const response = await requestDB(url, getAPICallObject("POST", requestBody));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getMetadata = async (token,dbname,requestBody) => {
  const url = `${REACT_APP_AZURE_DATABASE_ENDPOINT}/${dbname}/metadata`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await requestDB(url, getAPICallObject("POST", requestBody, headers));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSelectedMenuDetails = async (
  token,
  limit,
  skip,
  destinationNode,
  upstream,
  relationshipName,
  sourceNode,
  nodeUniqueColName,
  currId
) => {
  const baseurl = `${API_ENDPOINT}/${destinationNode}?limit=${limit}&skip=${skip}` +
    `&relationInfo.name=${relationshipName}` +
    `&relationInfo.nodeName=${sourceNode}` +
    `&relationInfo.uniqueColName=${nodeUniqueColName}&relationInfo.id=${currId}`;

  const url = upstream
    ? `${baseurl}&relationInfo.direction=Upstream`
    : baseurl;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await request(url, getAPICallObject("GET", null, headers));
    return response;
  } catch (error) {
    throw error;
  }
};

