import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { wizardGradientColors } from "../../utils/constants";
import { Select } from "antd";
import crypto from "crypto-js";
import { updateCatalogData } from "../../container/actions/catalogActions";
import { toast } from "react-toastify";
import { modalWizardMenus } from "../../utils/constants";
import {
  getAzureToken,
  getAzureResources,
  checkDatabaseConnection,
  getMetadata,
} from "../../config/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import PageLoader from "../customLoaders/Loader";
import { svgIcons } from "../../utils/svgIcons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const ImportWizard = ({ selectedMenu, setIsModalOpen, token, isModalOpen }) => {
  const dispatch = useDispatch();
  const [currentSteps, setCurrentStep] = useState({ 1: 1, 2: 1 });
  const [formData, setFormData] = useState({});
  const [azureResources, setAzureResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const themeState = useSelector((themeState) => themeState.themeReducer);
  const masterListState = useSelector(
    (catalogState) => catalogState.masterListReducer?.data
  );

  const cataLogsState = useSelector((catalogState) => catalogState);
  const setCurrentStepForMenu = (menu, step) => {
    setCurrentStep((prevSteps) => ({
      ...prevSteps,
      [menu]: step,
    }));
  };

  const catalogNames = masterListState?.map((item) => item["Catalog Name"]);
  const uniqueCatalogNames = Array.from(
    new Set(
      catalogNames?.filter(
        (name) => name !== "Admin Catalog" && name !== "General Catalog"
      )
    )
  );

  const requiredFields = {
    1: {
      1: ["db_name", "conn_string", "schemaName"],
      2: ["conn_string", "schemaName", "dbName", "catalogName"],
    },
    2: {
      1: ["subscriptionId", "tenantId", "clientId", "clientSecret", "resource"],
      2: ["catalogName", "tableName"],
    },
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const onChange = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const menuSteps = {
    1: [
      {
        title: "Step 1",
        content: (
          <>
            <div className="mb-2">
              <label htmlFor="dbname" className="mr-2">
                DB Type:
              </label>
              <Select
                id="db_name"
                name="db_name"
                onChange={(value) => handleSelectChange(value, "db_name")}
                className="w-full"
              >
                <Option value="sql-server">SQL Server</Option>
                <Option value="postgre-server">PostGreSQL</Option>
                <Option value="oracle-server">Oracle</Option>
              </Select>
            </div>

            <div className="mb-2">
              <label htmlFor="conn_string" >
                Connection String:
              </label>{" "}
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="infoIcon icon-style ml-2"
                size="sm"
                title="Example: Driver={ODBC Driver 18 for SQL Server};Server=xxx.com,1433;Database=xxxxxxxx;Uid=xxxxxxxxx;Pwd=xxxxxxxxxxx;TrustServerCertificate=no;Connection Timeout=60;"
              />
              <input
                type="text"
                id="conn_string"
                name="conn_string"
                placeholder="Example: Driver={ODBC Driver 18 for SQL Server};Server=xxx.com,1433;Database=xxxxxxxx;Uid=xxxxxxxxx;Pwd=xxxxxxxxxxx;TrustServerCertificate=no;Connection Timeout=60;"
                className="w-full text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                onChange={handleInput}
              />
            </div>

            <div>
              <label htmlFor="schemaName" className="mr-2">
                Schema Name:
              </label>
              <input
                type="text"
                id="schemaName"
                name="schemaName"
                className="w-full mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                onChange={handleInput} // Pass 'schemaName' as fieldName
              />
            </div>
          </>
        ),
      },
      {
        title: "Step 2",
        content: (
          <>
            <div className="w-full flex items-center justify-start text-gray-600 mt-3 mb-3">
              <h1 className=" text-gray-800 font-lg font-bold tracking-normal leading-tight">
                Connection Details:
              </h1>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
              <div>
                <label
                  htmlFor="hostname"
                  className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  Db Name:
                  <FontAwesomeIcon
                    className="infoIcon icon-style"
                    icon={faCircleInfo}
                    size="sm"
                    title="Example:dbo"
                  />
                </label>
                <input
                  type="text"
                  id="dbName"
                  name="dbName"
                  className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  value={formData.dbName}
                  onChange={handleInput}
                />
              </div>
              <div>
                <label
                  htmlFor="port"
                  className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                >
                  Catalog Name:
                </label>
                <Select
                  type="text"
                  id="catalogName"
                  name="catalogName"
                  className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  onChange={(value) => handleSelectChange(value, "catalogName")}
                >
                  {uniqueCatalogNames.map((catalogName, index) => (
                    <Option key={index} value={catalogName}>
                      {catalogName}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <p class="font-newOne text-xs text-red-500 text-right my-3">
              Required fields are marked with an asterisk{" "}
              <abbr title="Required field">*</abbr>
            </p>
          </>
        ),
      },
    ],
    2: [
      {
        title: "Step 1",
        content: (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
              <div>
                <label htmlFor="clientId">Subscription Id:</label>
                <input
                  type="text"
                  id="subscriptionId"
                  name="subscriptionId"
                  value={formData["subscriptionId"]}
                  className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  onChange={handleInput}
                />
              </div>
              <div>
                <label htmlFor="clientId">Client Id:</label>
                <input
                  type="text"
                  id="clientId"
                  name="clientId"
                  value={formData["clientId"]}
                  className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  onChange={handleInput}
                />
              </div>
              <div>
                <label htmlFor="clientSecret">Client Secret:</label>
                <input
                  type="text"
                  id="clientSecret"
                  name="clientSecret"
                  value={formData["clientSecret"]}
                  className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  onChange={handleInput}
                />
              </div>
              <div>
                <label htmlFor="tenantId">Tenant Id:</label>
                <input
                  type="text"
                  id="tenantId"
                  name="tenantId"
                  value={formData["tenantId"]}
                  className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  onChange={handleInput}
                />
              </div>
              <div>
                <label htmlFor="resource">Resource:</label>
                <input
                  type="text"
                  id="resource"
                  name="resource"
                  value={formData["resource"]}
                  className="mb-2 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  onChange={handleInput}
                />
              </div>
            </div>
            <p class="font-newOne text-xs text-red-500 text-right my-3">
              Required fields are marked with an asterisk{" "}
              <abbr title="Required field">*</abbr>
            </p>
          </>
        ),
      },
      {
        title: "Step 2",
        content: (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
              <div>
                <label htmlFor="catalogName">Catalog Name:</label>
                <Select
                  showSearch
                  placeholder="Select Catalog"
                  optionFilterProp="children"
                  value={formData["catalogName"]}
                  onChange={(value) => onChange(value, "catalogName")}
                  style={{ width: "80%", margin: "0.5rem 1rem 0rem 0rem" }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={[
                    ...new Set(
                      masterListState
                        .filter((item) => {
                          const catalogName = item["Catalog Name"];
                          return (
                            catalogName !== "Admin Catalog" &&
                            catalogName !== "General Catalog"
                          );
                        })
                        .map((item) => item["Catalog Name"])
                    ),
                  ].map((name) => ({ value: name, label: name }))}
                />
              </div>
              <div>
                <label htmlFor="tableName">Table Name:</label>
                <Select
                  showSearch
                  placeholder="Select Table"
                  optionFilterProp="children"
                  value={formData["tableName"]}
                  onChange={(value) => onChange(value, "tableName")}
                  style={{ width: "80%", margin: "0.5rem 1rem 0rem 0rem" }}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={[
                    ...new Set(
                      masterListState
                        ?.filter((item) => {
                          const catalogName = item["Catalog Name"];

                          return catalogName === formData["catalogName"];
                        })
                        .map((item) => item["Node Name"])
                    ),
                  ]?.map((name) => ({ value: name, label: name }))}
                />
              </div>
            </div>
            <p class="font-newOne text-xs text-red-500 text-right my-3">
              Required fields are marked with an asterisk{" "}
              <abbr title="Required field">*</abbr>
            </p>
          </>
        ),
      },
    ],
  };

  const handleCancelButton = () => {
    setIsModalOpen(false);
  };

  const handleNext = async () => {
    const currentStep = currentSteps[selectedMenu];
    const totalSteps = menuSteps[selectedMenu].length;
    if (currentStep < totalSteps) {
      const menuRequiredFields =
        requiredFields[selectedMenu][currentSteps[selectedMenu]];

      if (menuRequiredFields) {
        const missingFields = menuRequiredFields?.filter(
          (fieldName) => !(fieldName in formData)
        );
        if (missingFields.length > 0) {
          toast.error(
            `Please fill in all required fields: ${missingFields.join(", ")}`
          );
          return;
        }

        if (selectedMenu === "2" && currentStep === 1) {
          setLoading(true);
          const azureToken = await getAzureToken(token, formData);
          if (azureToken.accessToken) {
            const azureResource = await getAzureResources(
              azureToken.accessToken,
              formData["subscriptionId"]
            );
            setAzureResources(azureResource?.value);
            setCurrentStepForMenu(selectedMenu, currentStep + 1);
            setLoading(false);
          }
        }

        if (selectedMenu === "1" && currentStep === 1) {
          setLoading(true);
          const dbName = formData["db_name"]
            .replace(/\\"/g, '"')
            .replace(/\\+/g, "");
          const requestBody = {
            conn_string: formData["conn_string"]
              .replace(/\\"/g, '"')
              .replace(/\\+/g, ""),
            schemaName: formData["schemaName"]
              .replace(/\\"/g, '"')
              .replace(/\\+/g, ""),
          };
          const databaseResponse = await checkDatabaseConnection(
            dbName,
            requestBody
          );
          if (databaseResponse.status === 200) {
            setCurrentStepForMenu(selectedMenu, currentStep + 1);
          } else {
            toast.error(
              `Error: ${
                (databaseResponse.message,
                JSON.stringify(databaseResponse?.data))
              }`
            );
          }
          setLoading(false);
        }
      }
    }
  };

  // Define a function to handle "Previous" button click
  const handlePrevious = () => {
    const currentStep = currentSteps[selectedMenu];
    if (currentStep > 1) {
      setCurrentStepForMenu(selectedMenu, currentStep - 1);
    }
  };

  const createConvertedArray = (azureResources) => {
    return azureResources?.map((item) => ({
      "Azure Id": `AZ${crypto.MD5(item.name).toString()}`,
      Name: item.name,
      Description: "",
      Type: item.type,
      Location: item.location,
    }));
  };

  const convertedArray = createConvertedArray(azureResources);
  const concurrencyLimit = 3; // Set the desired concurrency limit

  const asyncWithConcurrency = async (array, limit, asyncFn) => {
    const results = [];
    const executing = [];

    const execute = async (item, index) => {
      try {
        const result = await asyncFn(item, index);
        results.push(result);
      } catch (error) {
        console.error(`Error processing item at index ${index}:`, error);
      } finally {
        const i = executing.indexOf(item);
        executing.splice(i, 1);
      }
    };

    for (const item of array) {
      if (executing.length < limit) {
        executing.push(execute(item, array.indexOf(item)));
      } else {
        await Promise.all(executing);
        executing.length = 0; // Clear the array after all promises are resolved
        executing.push(execute(item, array.indexOf(item)));
      }
    }

    await Promise.all(executing);

    return results;
  };

  const processBatch = async (batch, index) => {
    await dispatch(
      updateCatalogData(
        [batch],
        formData["tableName"],
        token,
        [batch],
        "POST",
        formData["catalogName"],
        true
      )
    );
    console.log(`Batch ${index + 1} inserted successfully.`);
  };

  const handleSubmit = async () => {
    const menuRequiredFields =
      requiredFields[selectedMenu][currentSteps[selectedMenu]];

    if (menuRequiredFields) {
      const missingFields = menuRequiredFields?.filter(
        (fieldName) => !(fieldName in formData)
      );
      if (missingFields.length > 0) {
        toast.error(
          `Please fill in all required fields: ${missingFields.join(", ")}`
        );
        return;
      }
    }
    if (selectedMenu === "2") {
      try {
        setLoading(true);
        await asyncWithConcurrency(
          convertedArray,
          concurrencyLimit,
          processBatch
        );
        setLoading(false);
        toast.success("Data imported successfully");
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error updating data:", error);
        // toast.error(`Error updating data: ${error.message}`);
      }
    }

    if (selectedMenu === "1") {
      try {
        setLoading(true);
        const dbName = formData["dbName"];
        const requestBodyMetadata = {
          conn_string: formData["conn_string"]
            .replace(/\\"/g, '"')
            .replace(/\\+/g, ""),
          schemaName: formData["schemaName"]
            .replace(/\\"/g, '"')
            .replace(/\\+/g, ""),
          dbName: formData["dbName"],
          catalogName: formData["catalogName"],
        };

        const databaseResponse = await getMetadata(
          token,
          dbName,
          requestBodyMetadata
        );

        if (databaseResponse.status === 200) {
          setLoading(false);
          toast.success("Metadata fetched successfully");
          setIsModalOpen(false);
        } else {
          toast.error(
            `Error: ${(databaseResponse.message, databaseResponse.error)}`
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Error updating data:", error);
        // toast.error(`Error updating data: ${error.message}`);
      }
    }
    setIsModalOpen(false);
  };

  return (
    <Modal title={null} open={isModalOpen} footer={null} width={650}>
      <div
        className="font-newOne relative  px-1 bg-white shadow-md rounded border overflow-y-auto"
        style={{ height: "auto" }}
      >
        <div className=" flex items-center justify-start text-gray-600 mb-3">
          <h1
            className={`ml-2 text-gray-800 font-lg font-bold tracking-normal leading-tight bg-gradient-to-r ${
              wizardGradientColors[themeState.sidenavColor]
            } to-white`}
            style={{
              marginLeft: "-0.6rem",
              padding: "0.4rem 0.8rem",
              color: "white",
              width: "85%",
            }}
          >
            {` ${selectedMenu === 1 ? "Data" : "Azure Data"}  Import Wizard`}
          </h1>
        </div>
        <div className="font-bold m-2 flex items-center">
          {selectedMenu === 1 ? svgIcons.database : svgIcons.cloud}
          {modalWizardMenus[selectedMenu]?.title}
        </div>
        {loading ? (
          <PageLoader />
        ) : (
          <>
            {" "}
            <form>
              {" "}
              {menuSteps[selectedMenu][currentSteps[selectedMenu] - 1].content}
            </form>
            <div
              className="flex items-center justify-center absolute bottom-0 p-1"
              style={{
                position: "relative",
                margin: "10px",
              }}
            >
              <div>
                {currentSteps[selectedMenu] !== 1 && (
                  <button
                    className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
                    onClick={handlePrevious}
                  >
                    Previous
                  </button>
                )}
              </div>
              <div className="items-center">
                {currentSteps[selectedMenu] <
                  menuSteps[selectedMenu].length && (
                  <button
                    className="focus:outline-none focus:ring-2 ml-3 focus:ring-offset-2 focus:ring-blue-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 rounded text-white px-8 py-2 text-sm"
                    onClick={handleNext}
                    type="button"
                  >
                    Next
                  </button>
                )}
              </div>

              {currentSteps[selectedMenu] ===
                menuSteps[selectedMenu].length && (
                <div className="items-center">
                  <button
                    className="focus:outline-none focus:ring-2 ml-3 focus:ring-offset-2 focus:ring-blue-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 rounded text-white px-8 py-2 text-sm"
                    onClick={handleSubmit}
                    type="button"
                  >
                    Submit
                  </button>
                </div>
              )}

              <button
                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 ml-3 bg-red-600 transition duration-150 text-white ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                onClick={handleCancelButton}
              >
                Cancel
              </button>
            </div>
          </>
        )}

        <button
          className="cursor-pointer absolute top-0 right-0 mt-1 mr-5 text-gray-400 hover-text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
          onClick={() => setIsModalOpen(false)}
          aria-label="close modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-x"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            fill=" none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
      </div>
    </Modal>
  );
};

export default ImportWizard;
