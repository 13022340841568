import { fullTableName } from "./fullTableName";
import schemaColors from "../../data/schemaColors.json";

export const loadDatabaseConfig = (graphData) => {
  graphData.edges.forEach((edgeConfig) => {
    const sourceTableName = fullTableName(edgeConfig.source);
    const targetTableName = fullTableName(edgeConfig.target);

    edgeConfig.source = sourceTableName;
    edgeConfig.target = targetTableName;
  });

  graphData.nodes.forEach((table) => {
    table.schemaColor = schemaColors[table.schema || "DEFAULT"];
    table.position = { x: 0, y: 0 };
  });

  return {
    graphData,
  };
};
