import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PieChart, Pie, Cell } from "recharts";
import { updateTheme } from "../../container/actions/themeActions";
import { Layout, Drawer, Affix, Row, Col, Typography } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faUserCircle } from "@fortawesome/free-solid-svg-icons";

const { Header: AntHeader, Content, Sider } = Layout;
const { Title } = Typography;

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

function Main({ children, title, description, icons }) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const themeState = useSelector((themeState) => themeState.themeReducer);
  const cataLogsState = useSelector(
    (catalogState) => catalogState.masterListReducer
  );

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  return (
    <>
      <Layout
        className={`layout-dashboard ${
          pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
      >
        <Drawer
          title={false}
          placement={placement === "right" ? "left" : "right"}
          closable={false}
          onClose={() => setVisible(false)}
          open={visible}
          key={placement === "right" ? "left" : "right"}
          width={250}
          className={`drawer-sidebar ${
            pathname === "rtl" ? "drawer-sidebar-rtl" : ""
          } `}
        >
          <Layout
            className={`layout-dashboard ${
              pathname === "rtl" ? "layout-dashboard-rtl" : ""
            }`}
          >
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${
                themeState.sidenavType === "#fff" ? "active-route" : ""
              }`}
              style={{ background: themeState.sidenavType }}
            >
              <Sidenav color={themeState.sidenavColor} />
            </Sider>
          </Layout>
        </Drawer>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          width="auto"
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${
            themeState.sidenavType === "#fff" ? "active-route" : ""
          }`}
          style={{ background: themeState.sidenavType }}
        >
          <Sidenav
            color={themeState.sidenavColor}
            cataLogsState={cataLogsState}
          />
        </Sider>
        <Layout>
          {/* {fixed ? (
            <Affix>
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header />
              </AntHeader>
            </Affix>
          ) : (
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header />
            </AntHeader>
          )} */}
          <Content className="content-ant">{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </>
  );
}

export default Main;
