export const ProcessOther = [
  <svg
    height="16px"
    width="16px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        style={{fill:"#78B9EB"}}
        d="M192.184,351.996l18.136-18.138l-32.178-32.178l-18.136,18.136 c-7.244-4.28-15.121-7.603-23.473-9.752v-25.621H91.024l0.001,25.621c-8.353,2.152-16.229,5.473-23.473,9.753l-18.138-18.138 L17.236,333.86l18.139,18.136c-4.28,7.244-7.603,15.121-9.751,23.474H0.001v45.507l25.624-0.001 c2.149,8.353,5.47,16.228,9.75,23.473l-18.138,18.138l32.178,32.178l18.139-18.138c7.243,4.28,15.12,7.602,23.47,9.75L91.025,512 h45.506v-25.623c8.353-2.149,16.229-5.472,23.473-9.751l18.139,18.138l32.178-32.178l-18.138-18.136 c4.28-7.243,7.603-15.121,9.751-23.473l25.623-0.002V375.47h-25.622C199.787,367.117,196.465,359.24,192.184,351.996z M113.78,436.148c-20.947,0-37.927-16.98-37.927-37.927c0-20.947,16.98-37.927,37.927-37.927c20.946,0,37.926,16.98,37.926,37.927 C151.706,419.168,134.726,436.148,113.78,436.148z"
      />{" "}
      <path
        style={{fill:"#3C5D76"}}
        d="M227.557,420.975V375.47h-25.622c-2.149-8.353-5.472-16.229-9.751-23.474l18.136-18.138 l-32.178-32.178l-18.136,18.136c-7.244-4.28-15.121-7.603-23.473-9.752v-25.621h-22.756v75.85c0.001,0,0.001,0,0.002,0 c20.946,0,37.926,16.98,37.926,37.927c0,20.947-16.98,37.927-37.926,37.927c-0.001,0-0.001,0-0.002,0V512h22.754v-25.623 c8.353-2.149,16.229-5.472,23.473-9.751l18.139,18.138l32.178-32.178l-18.138-18.136c4.28-7.243,7.603-15.121,9.751-23.473 L227.557,420.975z"
      />{" "}
      <path
        style={{fill:"#C9E3F7"}}
        d="M511.998,204.797l0.001-68.266l-38.43-0.001c-3.225-12.526-8.189-24.351-14.609-35.216l27.189-27.189 l-48.27-48.272l-27.19,27.189c-10.865-6.421-22.691-11.385-35.218-14.609V0h-68.267v38.431c-12.527,3.224-24.352,8.19-35.216,14.61 l-27.193-27.191l-48.273,48.272l27.191,27.19c-6.421,10.865-11.386,22.69-14.61,35.217h-38.431v68.267h38.43 c3.223,12.527,8.189,24.353,14.609,35.218l-27.19,27.189l48.273,48.273l27.189-27.189c10.865,6.421,22.69,11.386,35.217,14.61 v38.429h68.267v-38.428c12.528-3.223,24.354-8.19,35.219-14.61l27.189,27.189l48.273-48.272l-27.189-27.189 c6.421-10.865,11.386-22.691,14.61-35.22L511.998,204.797L511.998,204.797z M341.336,227.555c-31.419,0-56.89-25.471-56.89-56.89 s25.471-56.89,56.89-56.89s56.89,25.471,56.89,56.89S372.755,227.555,341.336,227.555z"
      />{" "}
      <path
        style={{fill:"#78B9EB"}}
        d="M511.998,204.797l0.001-68.266l-38.43-0.001c-3.225-12.526-8.189-24.351-14.609-35.216l27.189-27.189 l-48.27-48.272l-27.19,27.189c-10.865-6.421-22.691-11.385-35.218-14.609V0h-34.134v113.776c31.419,0,56.89,25.471,56.89,56.89 s-25.471,56.89-56.89,56.89v113.772h34.131v-38.428c12.528-3.223,24.354-8.19,35.219-14.61l27.189,27.189l48.273-48.272 l-27.189-27.189c6.421-10.865,11.386-22.691,14.61-35.22L511.998,204.797L511.998,204.797z"
      />{" "}
    </g>
  </svg>,
];
