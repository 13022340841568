// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient {
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/landing.scss"],"names":[],"mappings":"AAAA;EACI,4DAAA;AACJ","sourcesContent":[".gradient {\n    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
