import React from "react";
const CustomToast = ({ closeToast, message }) => {
  return (
    <div>
      <div>{message}</div>
      <button
        className="text-white bg-blue-400 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-xs px-5 py-2.5 text-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={closeToast}
      >
        OK
      </button>
    </div>
  );
};

export default CustomToast;
