import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";

import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { ReactFlowProvider } from "reactflow";
import { fetchGraphData } from "../container/actions/graphActions";
import { Typography, Breadcrumb } from "antd";
import { toast } from "react-toastify";
import styled from "styled-components";
import withRouter from "../utils/WithRouter";
import CreateGraph from "../components/graphGenerator";
import { saveGraphDetails, updateTableDetails } from "../config/Api";
import GlobalForm from "../components/modalForm/GlobalForm";
import AnalyticsModal from "../components/modalForm/AnalyticsModal";
import { TAG_TABLE, DATA_FLOW_TABLE } from "../data/constants";
import { themeColors } from "../utils/constants";
import MenuButtonComponent from "../components/button/DropDownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { actionIcons } from "../utils/svgIcons/actionIcons";
import FilterModal from "../components/modalForm/FilterModal";
import NavBar from "../components/layout/NavBar";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { updateGraph } from "../container/actions/graphActions";

const { Title } = Typography;

const StyledDiv = styled.div`
  h3.ant-typography {
    ${({ color }) =>
      color &&
      `
    margin: 0px;
    color: ${color};
    font-weight: 700;
    text-align: center
  `}
  }
`;

const Graph = ({ token }) => {
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  pathname = pathname.replace("/graph/", "");
  const graphData = useSelector(
    (graphState) => graphState?.systemGraphReducer?.graphData
  );
  const currentFlowData = useSelector(
    (currentFlowState) => currentFlowState?.currentFlowReducer?.currentFlow
  );

  const themeState = useSelector((themeState) => themeState.themeReducer);
  const [editGraph, setEditGraph] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [selectedGraphData, setSelectedGraphData] = useState([]);
  const [formData, setFormData] = useState({
    Title: "",
    Description: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const selectedgraphData = graphData.find((graph) => graph.Id === pathname);
    if (selectedgraphData) {
      console.log(selectedgraphData);
      setFormData({
        Title: selectedgraphData?.graphDetails?.title,
        Description: selectedgraphData?.graphDetails?.description,
      });
    }
    setSelectedGraphData(selectedgraphData);
  }, [graphData, pathname]);

  const handleCancel = () => {
    setEditGraph(false);
    window.location.reload();
  };

  const handleEdit = () => {
    if (editGraph) {
      setEditGraph(false);
      // handleCancel()
    } else {
      setEditGraph(true);
    }
  };

  const handleSave = () => {
    setShowModal(true);
  };

  const createRelationshipPayload = (inputData, hashId) => {
    const outputArray = [];
    inputData?.nodes?.forEach((node) => {
      const outputObject = {
        Source: node.data.innerGraphRequestBody.Item.nodeName,
        "Source Id":
          node.data.innerGraphRequestBody.Item.nodeIdInfo.hasOwnProperty(
            "idNum"
          )
            ? node.data.innerGraphRequestBody.Item.nodeIdInfo.idNum.toString()
            : node.data.innerGraphRequestBody.Item.nodeIdInfo.idStr,
        Type: "HAS_TAG",
        Destination: TAG_TABLE,
        "Destination Id": hashId,
        Description: "",
        Label: "",
      };
      outputArray.push(outputObject);
    });

    return outputArray;
  };

  const handleCreateDataFlow = async (formdata) => {
    setLoading(true)
    const catalogName = currentFlowData["Catalog Name"];
    if (uuidValidate(selectedGraphData?.Id)) {
      const data = {
        ...formdata,
        Id: selectedGraphData.Id,
      };
      const result = await updateTableDetails(
        DATA_FLOW_TABLE,
        data,
        token,
        "PATCH",
        catalogName
      );
      if (result) {
        toast.success("Graph Saved successfully");
      } else {
        toast.error("Error saving data:");
      }
    } else {
      const id = uuidv4();
      const dataFlowPayload = [
        {
          ...formdata,
          Id: id,
          "Catalog Name": catalogName,
          "Tag Type": "DataFlow",
        },
      ];

      const relationshipPayload = createRelationshipPayload(
        selectedGraphData.graphDetails,
        id
      );
      const response = await saveGraphDetails(
        token,
        dataFlowPayload,
        relationshipPayload,
        catalogName
      );
      const errorObj = response.reduce((accumulator, res) => {
        if ([500, 401, 400].includes(res.value.code)) {
          accumulator.push(res);
        }
        return accumulator;
      }, []);
      if (errorObj.length > 0) {
        toast.error("Error saving data:", errorObj);
      } else {
        toast.success("Graph Saved successfully");
      }
    }
    setLoading(false)
    setShowModal(false);
  };

  const handleAnalytics = () => {
    setShowAnalytics(true);
  };

  const handleGraphView = () => {
    const getGraphPayload = {
      Case:
        selectedGraphData?.graphDetails?.graphType === "SystemLineage"
          ? "DatabaseSchemaRequest"
          : "SystemGraphRequest",
      Item: selectedGraphData?.body?.Item,
    };
    dispatch(
      fetchGraphData(
        token,
        getGraphPayload,
        currentFlowData["Catalog Name"]
      )
    );
  };

  const downloadJSON = (data, filename) => {
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "data.json";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleJsonDowload = () => {
    downloadJSON(selectedGraphData?.graphDetails, "graphData.json");
  };

  const handleFilter = () => {
    setShowFilter(true);
  };

  function toTitleCase(str) {
    return str?.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const graphActions = [
    {
      name: formData?.Title ?  "Edit" : "Save",
      key: "1",
      onClick: () => handleSave(),
      icon: formData?.Title ?  actionIcons.editIcon : actionIcons.saveIcon,
    },
    // {
    //   name: editGraph ? "Cancel" : "Add Systems",
    //   key: "2",
    //   onClick: () => handleEdit(),
    //   icon: editGraph ? actionIcons.cancelIcon : actionIcons.addSystems,
    // },
    
    {
      name: "View Analytics",
      key: "3",
      onClick: () => handleAnalytics(),
      icon: actionIcons.analytics,
    },
    {
      name:
        selectedGraphData?.graphDetails?.graphType === "SystemLineage"
          ? "Meta Data View"
          : "System View",
      key: "4",
      onClick: () => handleGraphView(),
      icon:
        selectedGraphData?.graphDetails?.graphType === "SystemLineage"
          ? actionIcons.metaViewIcon
          : actionIcons.systemViewIcon,
    },
    {
      name: "Download as Json",
      key: "5",
      onClick: () => handleJsonDowload(),
      icon: actionIcons.jsonIcon,
    },
    {
      name: "Filter",
      key: "6",
      onClick: () => handleFilter(),
      icon: actionIcons.filterIcon,
    },
  ];

  const handleMenuClick = (key) => {
    const keyFunctionMap = {
      1: handleEdit,
      2: handleSave,
      3: handleAnalytics,
      4: handleGraphView,
      5: handleJsonDowload,
      6: handleFilter,
    };

    keyFunctionMap[key]();
  };

  const handleBreadcrumbClick = (index) => {
    const updateData = graphData.slice(0, index + 1);
    dispatch(updateGraph(updateData));
  };

  const renderBreadcrumbItems = () => {
    return graphData.map((path, index) => (
      <Breadcrumb.Item
        key={index}
        className="font-newOne font-bold"
        style={{ textTransform: "capitalize" }}
        onClick={() => handleBreadcrumbClick(index)}
      >
        <NavLink to={`/graph/${path.Id}`}>{`Level ${index + 1}`}</NavLink>
      </Breadcrumb.Item>
    ));
  };

  return (
    <ReactFlowProvider>
      <div className="mb-1">
        <NavBar
          title={toTitleCase(formData?.Title)}
          description={toTitleCase(formData?.Description)}
          icons={graphActions}
          token={token}
        />
      </div>
      <div className="m-1">
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink className="font-newOne font-bold" to="/">
              {" "}
              <FontAwesomeIcon
                className="fa"
                icon={faHome}
                color="black"
                size="lg"
              />
            </NavLink>
          </Breadcrumb.Item>
          {renderBreadcrumbItems()}
        </Breadcrumb>
      </div>
      <div>
        {/* <StyledDiv
          color={themeState.sidenavColor}
          className="p-2 bg-white mb-2"
        >
          <div className="flex relative justify-between items-center">
            <div className="font-newOne flex-1 text-center">
              <div className="flex items-center justify-center">
                <div className="ml-2">
                  <Title level={3} className="font-newTwo">
                    {toTitleCase(formData?.Title)}
                    {selectedGraphData?.graphDetails?.title ? (
                      <FontAwesomeIcon
                        className="fa ml-2"
                        size="2xs"
                        onClick={handleSave}
                        icon={faEdit}
                        color="#0E98BA"
                      />
                    ) : null}
                  </Title>
                  <p>{toTitleCase(formData?.Description)}</p>
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <MenuButtonComponent
                name="Tool Kit"
                className="font-newOne"
                handleMenuClick={handleMenuClick}
                options={graphActions}
                isIcon={true}
                placement="bottom"
              />
            </div>
          </div>
        </StyledDiv> */}
        {showAnalytics ? (
          <AnalyticsModal
            graphData={selectedGraphData?.graphDetails}
            setShowAnalytics={setShowAnalytics}
            showAnalytics={showAnalytics}
          />
        ) : null}
        <CreateGraph
          color={themeState.sidenavColor}
          editGraph={editGraph}
          graphData={graphData}
          token={token}
          catalogName={currentFlowData["Catalog Name"]}
          selectedFilters={selectedFilters}
        />

        {showModal ? (
          <GlobalForm
            handleInfoSubmit={handleCreateDataFlow}
            data={selectedGraphData?.graphDetails}
            setShowModal={setShowModal}
            formData={formData}
            setFormData={setFormData}
            loading={loading}
          ></GlobalForm>
        ) : null}

        <FilterModal
          open={showFilter}
          onClose={setShowFilter}
          graphData={selectedGraphData?.graphDetails}
          setSelectedFilters={setSelectedFilters}
        />
      </div>
    </ReactFlowProvider>
  );
};

export default withRouter(Graph);
