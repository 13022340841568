import React from "react";
import { Select } from "antd";

const CustomPageSize = ({pageSize,handlePageSizeChange}) => {
  return (
    <div className="pagesize-dropdown font-newOne">
      <span > Rows per Page: </span>
      <Select
        value={pageSize}
        onChange={handlePageSizeChange}
        style={{ width: 80, marginRight: 10 }}
      >
        <Select.Option value={20}>20</Select.Option>
        <Select.Option value={50}>50</Select.Option>
        <Select.Option value={100}>100</Select.Option>
        <Select.Option value={200}>200</Select.Option>
      </Select>
    </div>
  );
};

export default CustomPageSize;
