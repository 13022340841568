import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const BarChartVisual = ({ graphData }) => {
  function processDataForBarChart(graphData) {
    // Calculate the total number of nodes
    const totalNodes = graphData?.nodes?.length || 0;

    // Calculate the total number of edges
    const totalEdges = graphData?.edges?.length || 0;

    // Calculate the total number of anomalies
    const totalAnomalies =
      graphData?.nodes?.filter((node) => node.data.anomalyDetails)?.length || 0;

    // Create an array with a single object containing the totals
    const processedData = [
      {
        name: "Total",
        nodes: totalNodes,
        edges: totalEdges,
        anomalies: totalAnomalies,
      },
    ];

    return processedData;
  }

  // function processDataForBarChart(graphData) {
  //   console.log(graphData,'checking')
  //   // Initialize an empty array to store the processed data
  //   const processedData = [];

  //   // Create a map to keep track of source and target counts for each system
  //   const nodeCountsMap = {};

  //   // Iterate through the edges to count sources and targets
  //   graphData?.edges?.forEach((edge) => {
  //     if (!nodeCountsMap[edge.source]) {
  //       nodeCountsMap[edge.source] = { sourceCount: 0, targetCount: 0 };
  //     }
  //     if (!nodeCountsMap[edge.target]) {
  //       nodeCountsMap[edge.target] = { sourceCount: 0, targetCount: 0 };
  //     }

  //     // Increment the appropriate count based on the edge direction
  //     nodeCountsMap[edge.source].sourceCount += 1;
  //     nodeCountsMap[edge.target].targetCount += 1;
  //   });

  //   // Iterate through the systems (nodes) to create the processed data
  //   graphData?.nodes?.forEach((node) => {
  //     const { Name } = node.data;
  //     const { sourceCount, targetCount } = nodeCountsMap[node.id];
  //     processedData.push({ name: Name, sourceCount, targetCount });
  //   });

  //   return processedData;
  // }

  const processedData = processDataForBarChart(graphData);

  return (
    // <ResponsiveContainer>
    //   <BarChart width={600} height={300} data={processedData}>
    //   <CartesianGrid strokeDasharray="3 3" />
    //   <XAxis dataKey="name" />
    //   <YAxis />
    //   <Tooltip />
    //   <Legend />
    //   <Bar dataKey="sourceCount" name="Source Nodes" fill="#8884d8" />
    //   <Bar dataKey="targetCount" name="Target Nodes" fill="#82ca9d" />
    // </BarChart>
    // </ResponsiveContainer>
    <>
      <h2 className="text-m  text-center font-semibold m-1">
        {"Graph Composition"}
      </h2>

        <BarChart width={400} height={300} data={processedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="edges" name="Edges" fill="#8884d8" />
          <Bar dataKey="nodes" name="Nodes" fill="#82ca9d" />
          <Bar dataKey="anomalies" name="Anomalies" fill="#ffc658" />
        </BarChart>

    </>
  );
};
