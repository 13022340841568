import {
  ADD_CURRENT_FLOW,
  RESET_CURRENT_FLOW,
  RESET_GRAPH,
  CREATE_GRAPH_FAILURE,
  CREATE_GRAPH_FETCH,
  CREATE_GRAPH_SUCCESS,
  UPDATE_GRAPH
} from "../actionTypes";
import { createGraph } from "../../config/Api";

export const resetCurrentFLow = () => {
  return {
    type: RESET_CURRENT_FLOW,
  };
};

export const updateCurrentFlow = (data) => {
  return {
    type: ADD_CURRENT_FLOW,
    payload: data,
  };
};

export const fetchGraphRequest = () => ({
  type: CREATE_GRAPH_FETCH,
});

export const fetchGraphSuccess = (data) => ({
  type: CREATE_GRAPH_SUCCESS,
  payload: data,
});

export const fetchGraphFailure = (error) => ({
  type: CREATE_GRAPH_FAILURE,
  payload: error,
});

export const resetGraph = () => ({
  type: RESET_GRAPH,
});

export const updateGraph = (data) => ({
  type: UPDATE_GRAPH,
  payload: data,
});

export const fetchGraphData = (token, data, catalogName) => {
  return async (dispatch) => {
    dispatch(fetchGraphRequest());
    try {
      const response = await createGraph(token, data, catalogName);
      if (data.Item.Case === "TagNames") {
        dispatch(
          fetchGraphSuccess({
            id: data.Item?.tags[0].toString(),
            body: data,
            graphDetails: response,
          })
        );
      } else if (data.Item.Case === "NodeGraphRequest") {
        let id;
        if (data.Item.nodeIdInfo.hasOwnProperty('idNum')) { id= data.Item.nodeIdInfo.idNum; }
        else if (data.Item.nodeIdInfo.hasOwnProperty('idNumList')) { id= data.Item.nodeIdInfo.idNumList[0]; }
        else if (data.Item.nodeIdInfo.hasOwnProperty('idStr')) { id = data.Item.nodeIdInfo.idStr; }
        else { id = data.Item.nodeIdInfo.idStrList[0];}
        dispatch(
          fetchGraphSuccess({
            id: id.toString(),
            body: data,
            graphDetails: response,
          })
        );
      }else if(data.Item.Case === "Lineage"){
        dispatch(
          fetchGraphSuccess({
            id: (data.Item?.nodeId?.idNum)?(data.Item?.nodeId.idNum.toString()):(data.Item?.nodeId.idStr),
            body: data,
            graphDetails: response,
          })
        );
      } else {
        throw "Unknown Graph Request."
      }
    } catch (error) {
      dispatch(fetchGraphFailure(error.message));
    }
  };
};
