import { combineReducers } from 'redux'
import themeReducer from './themeReducer'
import  { currentFlowReducer, systemGraphReducer } from './graphReducer'
import { jwtReducer, masterListReducer, cataLogDataReducer, cataLogHeaderReducer,fetchRelationshipReducer,fetchRelationshipMetaInfoReducer } from './catalogReducer'

export default combineReducers({
    themeReducer,
    currentFlowReducer,
    jwtReducer,
    masterListReducer,
    cataLogDataReducer,
    cataLogHeaderReducer,
    fetchRelationshipReducer,
    systemGraphReducer,
    fetchRelationshipMetaInfoReducer
  })