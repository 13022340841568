import { Table, Space, Button, Select, Modal } from "antd";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import {
  updateCurrentFlow,
  resetCurrentFLow,
  resetGraph,
} from "../../container/actions/graphActions";
import { fetchCataLog } from "../../container/actions/catalogActions";
import { fetchGraphData } from "../../container/actions/graphActions";
import HourGlass from "../customLoaders/HourGlass";
import { DATA_FLOW_TABLE } from "../../data/constants";
import { deleteDataFlow } from "../../config/Api";
import recentFlowSvg from "../../assets/images/recent-flow.svg";
import {DEFAULT_PAGE_SIZE} from "../../globalUtils";

const StyledTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    ${({ color }) =>
      color &&
      `
    background-color: rgb(234 234 234) !important;
    color: ${color} !important;
    font-weight:bold;
    font-size:14px;
  `}
  }
`;

const RecentFlow = ({ color, graphdata, token }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cataLogsState = useSelector(
    (catalogState) => catalogState.cataLogDataReducer
  );
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const fetchData = (pageNumber) => {
    const calculatedOffset = (pageNumber - 1) * pageSize;
    if (token) {
      dispatch(
        fetchCataLog(
          pageSize, // Fetch based on pageSize
          calculatedOffset,
          token,
          DATA_FLOW_TABLE,
          "all",
          [],
          "lastUpdatedDateTime"
        )
      );
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    dispatch(resetGraph());
    dispatch(resetCurrentFLow());
  }, [token, refresh, currentPage, pageSize]); // Update dependencies

  useEffect(() => {
    const newData = cataLogsState[DATA_FLOW_TABLE]?.data || [];
    if (cataLogsState[DATA_FLOW_TABLE]?.data?.length) {
      setHasNextPage(cataLogsState[DATA_FLOW_TABLE]?.data?.length === pageSize);
      setHasPrevPage(currentPage > 1);
    } else {
      setHasNextPage(false);
      setHasPrevPage(currentPage > 1);
    }
    setData(newData);
  }, [cataLogsState, refresh]);

  const handleDelete = async (record) => {
    const result = await deleteDataFlow(
      record.Id,
      token,
      record["Catalog Name"]
    );
    setConfirmLoading(false);
    setOpen(false);
    if (result) {
      toast.success("Deleted Successfully");
      setTimeout(() => {
        setSelectedRecord(null);
        setRefresh(!refresh);
      }, 3000);
    }
  };

  useEffect(() => {
    fetchData(1); // Fetch initial data when component mounts
    dispatch(resetGraph());
    dispatch(resetCurrentFLow());
  }, [token, refresh]);

  useEffect(() => {
    const newData = cataLogsState[DATA_FLOW_TABLE]?.data || [];
    setData(newData);
  }, [cataLogsState, refresh]);

  const handleSelectedGraph = async (currentGraphData) => {
    const getGraphPayload = {
      Case: "DatabaseSchemaRequest",
      Item: {
        Case: "TagNames",
        tags: [currentGraphData.Id],
      },
    };
    dispatch(updateCurrentFlow(currentGraphData));
    dispatch(
      fetchGraphData(
        token,
        getGraphPayload,
        currentGraphData["Catalog Name"]
      )
    );
    navigate(`/graph/${currentGraphData.Id}`);
  };

  const columns = [
    {
      title: "View",
      key: "view",
      width: 70,
      fixed: "left",
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <FontAwesomeIcon
            icon={faEye}
            color="green"
            onClick={() => handleSelectedGraph(record)}
          />
        </div>
      ),
    },

    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      filters: data?.map((rowData) => {
        return {
          text: rowData["Title"],
          value: rowData["Title"],
        };
      }),
      onFilter: (value, record) => record?.Title.includes(value),
      ellipsis: true,
      render: (_, record) => <>{record.Title}</>,
      width: 210,
      fixed: "left",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      filters: data?.map((rowData) => {
        return {
          text: rowData["Description"],
          value: rowData["Description"],
        };
      }),
      onFilter: (value, record) => record?.Description.includes(value),
      ellipsis: true,
      width: 250,
    },
    {
      title: "Created By",
      dataIndex: "Created By",
      key: "Created By",
      filters: data?.map((rowData) => {
        return {
          text: rowData["Created By"],
          value: rowData["Created By"],
        };
      }),
      onFilter: (value, record) => record["Created By"].includes(value),
      ellipsis: true,
      width: 150,
    },
    {
      title: "Created Date",
      dataIndex: "Created DateTime",
      key: "Created DateTime",
      sorter: (a, b) => {
        const dateA = new Date(a["Created DateTime"]);
        const dateB = new Date(b["Created DateTime"]);
        return dateA - dateB;
      },
      ellipsis: true,
      width: 150,
    },
    {
      title: "Last Updated By",
      dataIndex: "Last Updated By",
      key: "Last Updated By",
      filters: data?.map((rowData) => {
        return {
          text: rowData["Last Updated By"],
          value: rowData["Last Updated By"],
        };
      }),
      onFilter: (value, record) => record["Last Updated By"].includes(value),
      ellipsis: true,
      width: 150,
    },
    {
      title: "Last Updated",
      dataIndex: "Last Updated DateTime",
      key: "Last Updated DateTime",
      sorter: (a, b) => {
        const dateA = new Date(a["Last Updated DateTime"]);
        const dateB = new Date(b["Last Updated DateTime"]);
        return dateA - dateB;
      },
      ellipsis: true,
      width: 150,
    },
    {
      title: "Delete",
      key: "delete",
      width: 80,
      fixed: "right",
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <FontAwesomeIcon
            icon={faTrashCan}
            color="red"
            onClick={() => showModal(record)}
          />
        </div>
      ),
    },
  ];

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to the first page when changing page size
  };

  const showModal = (record) => {
    setSelectedRecord(record);
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    handleDelete(selectedRecord);
  };

  const handleCancel = () => {
    setSelectedRecord(null);
    setOpen(false);
  };

  return (
    <>
      {cataLogsState[DATA_FLOW_TABLE]?.loading ? (
        <HourGlass />
      ) : cataLogsState[DATA_FLOW_TABLE]?.error && data?.length === 0 ? (
        <div className="parent-container">
          <div className="centered-div">
            <h3>No Records Found</h3>
          </div>
        </div>
      ) : (
        data?.length > 0 && (
          <>
            <StyledTable
              columns={columns}
              dataSource={data}
              color={color}
              pagination={false}
              scroll={{ y: 1500 }}
            />
            <div className="flex justify-center items-center mt-2">
              <div className="flex items-center">
                <Space wrap>
                  <Button onClick={goToPrevPage} disabled={!hasPrevPage}>
                    Previous
                  </Button>
                  <Button>{currentPage}</Button>
                  <Button onClick={goToNextPage} disabled={!hasNextPage}>
                    Next
                  </Button>
                </Space>
                {currentPage === 1 && (
                  <div className="ml-4">
                    <span>Rows per Page:</span>
                    <Select
                      value={pageSize}
                      onChange={handlePageSizeChange}
                      style={{ width: 80, marginLeft: 10 }}
                    >
                      <Select.Option value={20}>20</Select.Option>
                      <Select.Option value={50}>50</Select.Option>
                      <Select.Option value={100}>100</Select.Option>
                      <Select.Option value={200}>200</Select.Option>
                    </Select>
                  </div>
                )}
              </div>
            </div>
          </>
        )
      )}

      <Modal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        width={500}
      >
        <div className="flex items-center justify-center">
          <p className="text-center m-2 text-lg">
            Are you sure you want to delete?
          </p>

          <img src={recentFlowSvg} alt="recent-flow" />
        </div>
      </Modal>
    </>
  );
};
export default RecentFlow;
