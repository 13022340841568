import { getNonGraphicalRelationshipTypes, getGraphicalRelationshipTypes } from "../../globalUtils";
import MenuButtonComponent from "./DropDownButton";

function getGraphicalTypes(relationMetaInfo, catalogName, submenuName) {

    var graphicalTypes = [];
    const allGraphicalInfo = getGraphicalRelationshipTypes(relationMetaInfo, catalogName, submenuName);

    //Check Downstream - Source Node
    if (allGraphicalInfo?.find((item) => { return item["Source Node"] === submenuName; })) {
      graphicalTypes.push({ key: "Downstream", label: "Downstream Relationships" });
    }

    //Check Upstream - Destination Node
    if (allGraphicalInfo?.find((item) => { return item["Destination Node"] === submenuName; })) {
      graphicalTypes.push({ key: "Upstream", label: "Upstream Relationships" });
    }

    return graphicalTypes;
  }

  function getNonGraphicalTypes(relationMetaInfo, catalogName, submenuName) {
    return getNonGraphicalRelationshipTypes(
      relationMetaInfo,
      catalogName,
      submenuName
    )?.map((item) => {
      return item["Relationship Type"];
    });
  }

  function checkNonGraphicalDestinationNodes (relationMetaInfo, submenuName) {
    return relationMetaInfo?.find((value) => {
      if (
        submenuName === value["Destination Node"] &&
        value["Relationship Type"] !== "Graphical"
      ) {
        return true;
      } else return false;
    });
  }

  function getNonGraphicalTypesOptions(relationMetaInfo, catalogName, submenuName) {
    return getNonGraphicalTypes(relationMetaInfo, catalogName, submenuName)?.map((type) => { return { label: type, key: type }; });
  }

  export function ManageViewButton({ relationMetaInfo, handle, catalogName, submenuName }) {
    if (relationMetaInfo?.length > 0) {
      const nonGraphicalTypes = getNonGraphicalTypes(relationMetaInfo, catalogName, submenuName);
      const graphicalTypes = getGraphicalTypes(relationMetaInfo, catalogName, submenuName);

      let dropdownOptions = [];

      if (nonGraphicalTypes?.length > 0) {
        const nonGraphicalTypesOptions = getNonGraphicalTypesOptions(relationMetaInfo, catalogName, submenuName);
        dropdownOptions = dropdownOptions.concat(nonGraphicalTypesOptions);
      }

      if (graphicalTypes?.length > 0) {
        dropdownOptions = dropdownOptions.concat(graphicalTypes)
      }
      
      if (checkNonGraphicalDestinationNodes(relationMetaInfo,submenuName)) {
        dropdownOptions = dropdownOptions.concat([{ label: "Entities", key: "Entities" }]);
      }

      if (dropdownOptions.length > 0) {
        return (
          <MenuButtonComponent
            handleMenuClick={handle}
            name="Manage/View"
            placement="left"
            options={dropdownOptions} />
        );
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }