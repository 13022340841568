// in ActionProvider.jsx
import React, { useState, useEffect } from "react";
import { RemoteRunnable } from "@langchain/core/runnables/remote";
import {AIMessage} from "@langchain/core/messages";

// class StreamHandler {
//   constructor(container, status, initialText = "") {
//     this.status = status;
//     this.container = container;
//     this.text = initialText;
//   }

//   newToken(token) {
//     this.text += token;
//     this.container.innerHTML = this.text;
//   }

//   newStatus(statusUpdate) {
//     this.status.update("Generating answer🤖", "running", true);
//     this.status.textContent = statusUpdate;
//   }
// }

async function invokeRemoteChain(input, chat_history, setResult) {
  let chatbotUrl = process.env.REACT_APP_CHATBOT_URL;

  const remoteChain = new RemoteRunnable({
    url: chatbotUrl,
  });

  try {

    /////Works with invoke too!!!
    //console.log(chat_history);
    // const result = await remoteChain.invoke({
    //   input: input,
    //   chat_history: chat_history,
    // });


    // console.log(result);
    //setResult(result.output);

    const stream = await remoteChain.stream({
     input: input,
     chat_history: chat_history,
    });

    let state = "";

    // Each chunk has the same interface as a chat message
    for await (const chunk of stream) {
      state = state + chunk?.messages?.reduce((acc,message)=>{
        if (message instanceof AIMessage) {
          return acc+message.content;
        } else {
          return acc;
        }},"")
      //console.log(JSON.stringify(chunk));
    }

    //https://js.langchain.com/v0.1/docs/expression_language/interface/#stream-log
    // for await (const logPatch of logStream) {
    //   if (logPatch?.ops?.length > 0) {
    //     state = state + logPatch.ops.reduce((acc,element) => {
    //       if (element.hasOwnProperty('value') && element.value instanceof AIMessageChunk) {
    //         if (element.value.content !== undefined && element.value.content !== null && element.value.content !== ""){
    //             return acc = acc + element.value.content;
    //         }
    //       } else if (element.hasOwnProperty('value') && 
    //       element.value !== "" 
    //       && !(element.value instanceof FunctionMessage)
    //       && !(element.value instanceof AIMessage)) {
    //         console.log(element.value);
    //         return acc;
    //       }

    //       return acc;
          
    //     },"");
    //   }
      
    // }
    
    setResult(state);
  } catch (error) {
    console.log(error || 'An unexpected error occurred');
    setResult('An unexpected error occurred');
  }
}

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [input, setInput] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [result, setResult] = useState(null);

  function printBotMessage(message) {
    const botMessage = createChatBotMessage(message);

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  useEffect(() => {
    if (input != null) {
      if (chatHistory.length <= 3) {
        invokeRemoteChain(input, chatHistory,setResult);
      } else {
        invokeRemoteChain(input, chatHistory.slice(-3),setResult);
      } 
    }
  }, [input]);

  useEffect(() => {
    if (result != null) {
      chatHistory.push([input,result]);
      setChatHistory(chatHistory);
      printBotMessage(result);
      setResult(null);
    }
  }, [result]);

  const handleHello = () => {
    printBotMessage("Hey! How are you doing?");
  };

  const handleMessages = (message) => {
    setInput(message);
    printBotMessage("Generating answer🤖");
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleMessages,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
