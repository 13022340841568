import React from 'react';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    attribute: 'Performance',
    score: 90,
  },
  {
    attribute: 'Reliability',
    score: 85,
  },
  {
    attribute: 'Scalability',
    score: 70,
  },
  {
    attribute: 'Security',
    score: 95,
  },
  {
    attribute: 'Usability',
    score: 80,
  },
];

const SystemAttributesRadarChart = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="attribute" />
        <PolarRadiusAxis angle={30} domain={[0, 100]} />
        <Radar name="System Attributes" dataKey="score" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default SystemAttributesRadarChart;
