import { useSelector } from "react-redux";
import { Card, Typography } from "antd";
import RecentFlow from "../components/layout/RecentFlows";
import withRouter from "../utils/WithRouter";
import graphdata from "../data/graphdata.json";
import NavBar from "../components/layout/NavBar";

function Home({ token }) {
  const { Title } = Typography;
  const themeState = useSelector((themeState) => themeState.themeReducer);

  return (
    <>
      <div className="mb-2">
        <NavBar title={""} description={""} icons={[]} token={token} />
      </div>
      <div className="layout-content">
        <Card bordered={false} className="criclebox cardbody h-full">
          <div className="project-ant">
            <div>
              <Title level={5} className="font-newOne">
                Data Flows
              </Title>
            </div>
          </div>
          <div className="recent-flow-table font-newOne">
            <RecentFlow
              color={themeState.sidenavColor}
              token={token}
              graphdata={graphdata}
            />
          </div>
        </Card>
      </div>
    </>
  );
}

export default withRouter(Home);
