import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import { Row, Col, Breadcrumb } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faCloud, faHome } from "@fortawesome/free-solid-svg-icons";
import MenuButtonComponent from "../button/DropDownButton";
import ImportWizard from "../modalForm/ImportWizardModal";
import withRouter from "../../utils/WithRouter";
import { updateGraph } from "../../container/actions/graphActions";
import { themeColors } from "../../utils/constants";
import "../../assets/styles/main.css";

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");

  const themeState = useSelector((state) => state.themeReducer);
  const jwtTokenState = useSelector((jwtState) => jwtState.jwtReducer);

  const graphData = useSelector(
    (state) => state?.systemGraphReducer?.graphData
  );

  const [selectedMenu, setSelectedMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBreadcrumbClick = (index) => {
    const updateData = graphData.slice(0, index + 1);
    dispatch(updateGraph(updateData));
  };

  const renderBreadcrumbItems = () => {
    return graphData.map((path, index) => (
      <Breadcrumb.Item
        key={index}
        className="font-newOne font-bold"
        style={{ textTransform: "capitalize" }}
        onClick={() => handleBreadcrumbClick(index)}
      >
        <NavLink to={`/graph/${path.Id}`}>{`Level ${index + 1}`}</NavLink>
      </Breadcrumb.Item>
    ));
  };

  const handleMenuClick = (key) => {
    setSelectedMenu(key);
    setIsModalOpen(true);
  };

  const options = [
    {
      label: "Import DB",
      key: "1",
      icon: <FontAwesomeIcon className="fa" icon={faDatabase} />,
    },
    {
      label: "Azure Resources",
      key: "2",
      icon: <FontAwesomeIcon className="fa" icon={faCloud} />,
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]} style={{ position: "relative" }}>
        <Col
          span={24}
          style={{
            margin: "0px",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            maxWidth: "100%",
          }}
        >
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink className="font-newOne font-bold" to="/">
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faHome}
                  color="black"
                  size="lg"
                />
              </NavLink>
            </Breadcrumb.Item>
            {renderBreadcrumbItems()}
          </Breadcrumb>
        </Col>
        {["dashboard", "datacatalog"].includes(pathname) && (
          <Col span={24} className="header-control">
            <MenuButtonComponent
              name={"Import"}
              handleMenuClick={handleMenuClick}
              options={options}
              placement="bottom"
            />
          </Col>
        )}
      </Row>
      {isModalOpen && (
        <ImportWizard
          selectedMenu={selectedMenu}
          setIsModalOpen={setIsModalOpen}
          token={jwtTokenState.token}
          isModalOpen={isModalOpen}
        />
      )}
    </>
  );
}

export default withRouter(Header);
