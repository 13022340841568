import "./HourGlass.css";

const HourGlass = () => {
  return (
    <div className="main">
      <div className="hourglass"></div>
    </div>
  );
};

export default HourGlass;
