// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-handle {
    width: 2px;
    height: 2px;
    min-width: 2px;
    left: 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .right-handle {
    width: 2px;
    height: 2px;
    min-width: 2px;
    right: 0;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/handle.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,cAAc;IACd,OAAO;IACP,QAAQ;IACR,4CAA4C;EAC9C;;EAEA;IACE,UAAU;IACV,WAAW;IACX,cAAc;IACd,QAAQ;IACR,QAAQ;IACR,2CAA2C;EAC7C","sourcesContent":[".left-handle {\n    width: 2px;\n    height: 2px;\n    min-width: 2px;\n    left: 0;\n    top: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  }\n  \n  .right-handle {\n    width: 2px;\n    height: 2px;\n    min-width: 2px;\n    right: 0;\n    top: 50%;\n    transform: translateX(50%) translateY(-50%);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
