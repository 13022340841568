import dagre from "dagre";

export const calculateDagreLayout = (nodes, edges, graphType, containerWidth=1000, containerHeight=600) => {
  const updatedNodes = JSON.parse(JSON.stringify(nodes));
  const g = new dagre.graphlib.Graph();
  g.setGraph({
    rankdir: "LR",
    ranksep: graphType === 'columnlineage' ? 200 : 30,
    nodesep: 50, // Adjust this value to control vertical spacing
  });
  g.setDefaultEdgeLabel(() => ({}));

  let maxX = 0;
  let maxY = 0;

  g.nodes().forEach((nodeId) => {
    const pos = g.node(nodeId);
    maxX = Math.max(maxX, pos.x);
    maxY = Math.max(maxY, pos.y);
  });

  // Calculate scale factors based on container size
  const scaleX = containerWidth / maxX;
  const scaleY = containerHeight / maxY;

  updatedNodes?.forEach((node) => {
    // Calculate width and height based on content or other factors
    const width = 200; // You can set a default width here
    const height = graphType === 'columnlineage' ? 230 : 40;

    // Scale down node position and dimensions
    node.position.x *= scaleX;
    node.position.y *= scaleY;
    node.data = {
      ...node.data,
      width,
      height,
    };
    g.setNode(node.id, { width, height });
  });

  edges?.forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  dagre.layout(g);

  const calculatedNodes = updatedNodes?.map((node) => {
    const pos = g.node(node.id);

    return {
      ...node,
      position: { x: pos.x, y: pos.y },
    };
  });

  return { calculatedNodes, edges };
};

const topologicalSort = (nodes, edges) => {
  const sortedNodes = [];
  const incomingEdges = {};
  const outgoingEdges = {};

  // Initialize incoming and outgoing edge maps
  nodes.forEach((node) => {
    incomingEdges[node.id] = 0;
    outgoingEdges[node.id] = [];
  });

  // Populate incoming and outgoing edge maps
  edges.forEach((edge) => {
    incomingEdges[edge.target]++;
    outgoingEdges[edge.source].push(edge.target);
  });

  // Perform topological sorting using Kahn's algorithm
  const queue = nodes.filter((node) => incomingEdges[node.id] === 0);
  while (queue.length > 0) {
    const node = queue.shift();
    sortedNodes.push(node);
    outgoingEdges[node.id].forEach((neighbor) => {
      incomingEdges[neighbor]--;
      if (incomingEdges[neighbor] === 0) {
        queue.push(nodes.find((n) => n.id === neighbor));
      }
    });
  }

  // Check for cycles
  if (sortedNodes.length !== nodes.length) {
    throw new Error('Graph contains a cycle');
  }

  return sortedNodes;
};


// Step 2: Layer Assignment
const assignLayers = (nodes, edges) => {
  const layers = {};
  const sortedNodes = topologicalSort(nodes, edges);
  console.log(sortedNodes)

  sortedNodes.forEach((node, index) => {
    layers[node.id] = index;
  });
  return layers;
};

const orderNodesWithinLayers = (layers, edges) => {
  const nodeOrder = {};
console.log(layers)
  // Initialize a map to store the order of nodes within each layer
  Object.values(layers).forEach((layerIndex) => {
    nodeOrder[layerIndex] = [];
  });

  // Create a map to store the number of predecessors for each node
  const predecessors = {};
  Object.keys(layers).forEach((nodeId) => {
    predecessors[nodeId] = 0;
  });

  // Calculate the number of predecessors for each node
  edges.forEach((edge) => {
    predecessors[edge.target]++;
  });

  // Initialize a queue with nodes that have no predecessors
  const queue = Object.keys(predecessors).filter((nodeId) => predecessors[nodeId] === 0);

  // Perform topological sorting to order nodes within layers
  Object.keys(layers).forEach((nodeId) => {
    const layerIndex = layers[nodeId];
    while (queue.length > 0) {
      const node = queue.shift();
      if (layers[node] === layerIndex) {
        nodeOrder[layerIndex].push(node);
      }

      // Decrease the number of predecessors for adjacent nodes
      edges.forEach((edge) => {
        if (edge.source === node) {
          predecessors[edge.target]--;
          if (predecessors[edge.target] === 0) {
            queue.push(edge.target);
          }
        }
      });
    }
  });

  return nodeOrder;
};


const routeEdges = (nodes, edges, layers) => {
  const routedEdges = [];

  // Loop through each edge and calculate its route
  edges.forEach((edge) => {
    const { source, target } = edge;

    // Find the index of source and target nodes within their layers
    const sourceIndex = layers[source];
    const targetIndex = layers[target];

    // Get the source and target node objects
    const sourceNode = nodes.find(node => node.id === source);
    const targetNode = nodes.find(node => node.id === target);

    // Check if the source and target nodes exist
    if (sourceNode && targetNode) {
      // Calculate the x-coordinate for the source node (center of the node)
      const sourceX = sourceNode.position.x + sourceNode.data.width / 2;

      // Calculate the y-coordinate for the source node (top or bottom of the node)
      const sourceY = sourceIndex % 2 === 0 ? sourceNode.position.y : sourceNode.position.y + sourceNode.data.height;

      // Calculate the x-coordinate for the target node (center of the node)
      const targetX = targetNode.position.x + targetNode.data.width / 2;

      // Calculate the y-coordinate for the target node (top or bottom of the node)
      const targetY = targetIndex % 2 === 0 ? targetNode.position.y : targetNode.position.y + targetNode.data.height;

      // Add the route for the edge
      const route = [
        { x: sourceX, y: sourceY },
        { x: sourceX, y: (sourceY + targetY) / 2 },
        { x: targetX, y: (sourceY + targetY) / 2 },
        { x: targetX, y: targetY }
      ];

      routedEdges.push({ ...edge, route });
    } else {
      console.error(`Source or target node not found for edge: ${edge.id}`);
    }
  });

  return routedEdges;
};




const positionNodes = (nodes, layers, containerWidth, containerHeight) => {
  const nodePositions = {};

  // Calculate the vertical spacing between layers
  const layerSpacing = containerHeight / (layers.length + 1);
console.log(layers)
  // Iterate through each layer
  layers.forEach((layer, layerIndex) => {
    // Calculate the horizontal spacing between nodes within the layer
    const nodeSpacing = containerWidth / (layer.length + 1);

    // Iterate through each node in the layer
    layer.forEach((nodeId, nodeIndex) => {
      // Calculate the x-coordinate for the node
      const x = (nodeIndex + 1) * nodeSpacing;

      // Calculate the y-coordinate for the node
      const y = (layerIndex + 1) * layerSpacing;

      // Store the position of the node
      nodePositions[nodeId] = { x, y };
    });
  });

  // Update the positions of nodes in the nodes array
  nodes.forEach((node) => {
    const nodeId = node.id;
    node.position = nodePositions[nodeId];
  });

  return nodes;
};


// Sugiyama layout function
export const calculateSugiyamaLayout = (nodes, edges,  graphType, containerWidth=1000, containerHeight=600) => {
  console.log(nodes,edges)
  const layers = assignLayers(nodes, edges);
  console.log(layers,'layers')
  const orderedNodes = orderNodesWithinLayers(layers, edges);
  const routedEdges = routeEdges(nodes, edges, layers);
  console.log(routedEdges)
  const positionedNodes = positionNodes(nodes, layers, containerWidth, containerHeight);

  return { calculatedNodes: positionedNodes, edges: routedEdges };
};
